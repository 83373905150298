
const url={
    'homePage':'/',
    'searchTour':'/tour-list',
    'viewTourDetails':'/tour-details',
    'searchFlight':'/search-flight',
    'flightbooking':'/flightbooking',
    'login':'/login',
    'signup':'/signup',
    'package':'/bookedpackage',
    'airticketbookings':'/air-ticket-bookings',
    'userProfile':'/userprofile',
    'rzpykey':'rzp_live_oCbAvb54nsgAmz',
    'contactUs':'/contact-us',
    'account':'/account',
    'paymentFailed':'/payment-failed',
    'wallet':'/wallet',
    'packagebookings':'/packagebookings',
}
export default url;