import React, { useEffect, useState } from "react";
import { PiHandbagFill } from "react-icons/pi";
import { MdLuggage } from "react-icons/md";
import Common from "../../../Common/Common";
const TicketDetails=({dataList})=>{
    const[ticketDetails,setTicketDetails]=useState(dataList);
    console.log('dataList',dataList)

    useEffect(()=>{
        setTicketDetails(dataList);
    },[dataList])
     return(
        <>
            <div className="tour_details_leftside_wrapper">
                <div className="tour_details_boxed review-flight-container">
                    <div className="row rf-flight-details">
                        <h3 className="heading_theme sec-timing">
                        <span className="me-1 sec">{ticketDetails.departureSectorName}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                            </svg>
                            <span className="sec ms-1"> {ticketDetails.arrivalSectorName} </span>
                        </h3>
                        <div className="tour_details_boxed_inner" >
                            <span className="flight-t me-1">{ticketDetails.formattedDepartureDate} •</span>
                            <span>{ticketDetails.flightStoppage} • {ticketDetails.timeDifference}</span>
                        </div>
                    </div>
                    <div className="row review-flight-details">
                        <div className="flight_search_item_wrappper">
                            <div className="flight_search_items rf-flight-d">
                                <div className="multi_city_flight_lists">
                                    <div className="flight_multis_area_wrapper">
                                        <div className="flight_policy_refund rf-conatiner border-none">
                                            {ticketDetails.isConnectedFlight === 1 ?
                                                <>
                                                    {ticketDetails.connectionList && ticketDetails.connectionList.length > 0 && ticketDetails.connectionList.map((conn,ind,allConn)=>{
                                                        return(
                                                                <>
                                                                    {ind === 0 ?
                                                                        <div className="flight_show_down_wrapper border-none" key={ind+1}>
                                                                            <div className="flight-shoe_dow_item">
                                                                                <div className="airline-details">
                                                                                    <div className="img"><img src={ticketDetails.airlineLogo} alt="img" /></div>
                                                                                    <span className="airlineName fw-500">{ticketDetails.airline_name}</span>
                                                                                    <span className="flightNumber"> {ticketDetails.airline_no}</span>
                                                                                </div>
                                                                                <div className="flight_inner_show_component">
                                                                                    <div className="flight_det_wrapper flight-dt">
                                                                                        <div className="flight_det">
                                                                                            <div className="code_time">
                                                                                                <span className="code"> {ticketDetails.formattedDepartureDate}</span><br />
                                                                                                <span className="time font-1rem"> {ticketDetails.departureTime}</span> <br />
                                                                                                <span className="time font-sec"> {ticketDetails.departureSectorCode} - {ticketDetails.departureSectorName}</span>
                                                                                            </div>
                                                                                            <p className="airport mb-1">
                                                                                                {ticketDetails.departureSectorLocation}
                                                                                                <br />
                                                                                                <span className="font-sec">{ticketDetails.departureTerminal} </span>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="flight_duration">
                                                                                        <div className="arrow_right"></div>
                                                                                        <span> {Common.getTimeDifference(`${ticketDetails.departureDate.split('T')[0]}T${ticketDetails.departureTime}`,`${conn.arrivalDate.split('T')[0]}T${conn.arrivalTime}`)}</span>

                                                                                    </div>
                                                                                    <div className="flight_det_wrapper rf-arrival-details">
                                                                                        <div className="flight_det">
                                                                                            <div className="code_time">
                                                                                                <span className="code"> {conn.formattedArrivalDate}</span><br />
                                                                                                <span className="time font-1rem"> {conn.arrivalTime}</span> <br />
                                                                                                <span className="time font-sec"> {conn.arrivalSectorCode} - {conn.arrivalSectorName}</span>
                                                                                            </div>
                                                                                            <p className="airport mb-1">
                                                                                                {conn.arrivalSectorLocation}
                                                                                                <br />
                                                                                                <span className="font-sec">{conn.arrivalTerminal} </span>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="flight_refund_policy baggage-container-main">
                                                                                {/* <div className="TabPanelInner flex_widht_less">
                                                                                    <h4>Refund Policy</h4>
                                                                                    <p className="fz12">1. Refund and Date Change are done as per the
                                                                                        following policies.</p>
                                                                                    <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                                                        policy + DocoTrip Convenience Fee). </p>
                                                                                    <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                                                        Airline Policy + DocoTrip Convenience Fee).</p>
                                                                                </div> */}
                                                                                <div className="TabPanelInner baggage-container">
                                                                                    <h4 className="baggage-title">Baggage</h4>
                                                                                    <div className="flight_info_taable mt-1">
                                                                                        {/* <p><span>{(conn.handbaggageWeight+conn.checkInBaggageWeight)}KG /</span> person</p> */}
                                                                                        <div>
                                                                                            <PiHandbagFill className="cabin-bag-icon"/>
                                                                                            <span className="baggage-type">Cabin :</span>
                                                                                            <span className="baggage-quantity">{ticketDetails.handBaggageWeight} Kg</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <MdLuggage className="cabin-bag-icon"/>
                                                                                            <span className="baggage-type">Check-in :</span>
                                                                                            <span className="baggage-quantity">{ticketDetails.checkInBaggageWeight} Kg</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    : 
                                                                        <div className="flight_show_down_wrapper border-none" key={ind+2}>
                                                                            <div className="flight-shoe_dow_item">
                                                                                <div className="airline-details">
                                                                                    <div className="img"><img src={ticketDetails.airlineLogo} alt="img" /></div>
                                                                                    <span className="airlineName fw-500">{ticketDetails.airline_name}</span>
                                                                                    <span className="flightNumber"> {ticketDetails.airline_no}</span>
                                                                                </div>
                                                                                <div className="flight_inner_show_component">
                                                                                    <div className="flight_det_wrapper">
                                                                                        <div className="flight_det">
                                                                                            <div className="code_time">
                                                                                                <span className="code"> {allConn[(ind-1)].formattedArrivalDate}</span><br />
                                                                                                <span className="time font-1rem"> {allConn[(ind-1)].arrivalTime}</span> <br />
                                                                                                <span className="time font-sec"> {allConn[(ind-1)].arrivalSectorCode} - {allConn[(ind-1)].arrivalSectorName}</span>
                                                                                            </div>
                                                                                            <p className="airport mb-1">
                                                                                                {allConn[(ind-1)].arrivalSectorLocation}
                                                                                                <br />
                                                                                                <span className="font-sec">{allConn[(ind-1)].arrivalTerminal} </span>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="flight_duration">
                                                                                        <div className="arrow_right"></div>
                                                                                        <span> {allConn[(ind-1)].timeDifference}</span>
                                                                                    </div>
                                                                                    <div className="flight_det_wrapper rf-arrival-details">
                                                                                        <div className="flight_det">
                                                                                            <div className="code_time">
                                                                                                <span className="code"> {conn.formattedArrivalDate}</span><br />
                                                                                                <span className="time font-1rem"> {conn.arrivalTime}</span> <br />
                                                                                                <span className="time font-sec"> {conn.arrivalSectorCode} - {conn.arrivalSectorName}</span>
                                                                                            </div>
                                                                                            <p className="airport mb-1">
                                                                                                {ticketDetails.arrivalSectorLocation}
                                                                                                <br />
                                                                                                <span className="font-sec">{conn.arrivalTerminal} </span>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="flight_refund_policy">
                                                                                <div className="TabPanelInner baggage-container">
                                                                                    <h4 className="baggage-title">Baggage</h4>
                                                                                    <div className="flight_info_taable mt-1">
                                                                                        {/* <p><span>{(conn.handbaggageWeight+conn.checkInBaggageWeight)}KG /</span> person</p> */}
                                                                                        <div>
                                                                                            <PiHandbagFill className="cabin-bag-icon"/>
                                                                                            <span className="baggage-type">Cabin :</span>
                                                                                            <span className="baggage-quantity">{conn.handBaggageWeight} Kg</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <MdLuggage className="cabin-bag-icon"/>
                                                                                            <span className="baggage-type">Check-in :</span>
                                                                                            <span className="baggage-quantity">{conn.checkInBaggageWeight} Kg</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>              
                                                                    }
                                                                    {ind === (ticketDetails.connectionList.length-1) ?
                                                                        <div className="flight_show_down_wrapper border-none" key={ind+3}>
                                                                            <div className="flight-shoe_dow_item">
                                                                                <div className="airline-details">
                                                                                    <div className="img"><img src={ticketDetails.airlineLogo} alt="img" /></div>
                                                                                    <span className="airlineName fw-500">{ticketDetails.airline_name}</span>
                                                                                    <span className="flightNumber"> {ticketDetails.airline_no}</span>
                                                                                </div>
                                                                                <div className="flight_inner_show_component">
                                                                                    <div className="flight_det_wrapper">
                                                                                        <div className="flight_det">
                                                                                            <div className="code_time">
                                                                                                <span className="code"> {conn.formattedArrivalDate}</span><br />
                                                                                                <span className="time font-1rem"> {conn.arrivalTime}</span> <br />
                                                                                                <span className="time font-sec"> {conn.arrivalSectorCode} - {conn.arrivalSectorName}</span>
                                                                                            </div>
                                                                                            <p className="airport mb-1">
                                                                                                {conn.arrivalSectorLocation}
                                                                                                <br />
                                                                                                <span className="font-sec">{conn.arrivalTerminal} </span>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="flight_duration">
                                                                                        <div className="arrow_right"></div>
                                                                                        <span> {Common.getTimeDifference(`${conn.arrivalDate.split('T')[0]}T${conn.arrivalTime}`,`${ticketDetails.arrivalDate.split('T')[0]}T${ticketDetails.arrivalTime}`)}</span>
                                                                                    </div>
                                                                                    <div className="flight_det_wrapper rf-arrival-details">
                                                                                        <div className="flight_det">
                                                                                            <div className="code_time">
                                                                                                <span className="code"> {ticketDetails.formattedArrivalDate}</span><br />
                                                                                                <span className="time font-1rem"> {ticketDetails.arrivalTime}</span> <br />
                                                                                                <span className="time font-sec"> {ticketDetails.arrivalSectorCode} - {ticketDetails.arrivalSectorName}</span>
                                                                                            </div>
                                                                                            <p className="airport mb-1">
                                                                                                {ticketDetails.arrivalSectorLocation}
                                                                                                <br />
                                                                                                <span className="font-sec">{ticketDetails.arrivalTerminal} </span>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="flight_refund_policy">
                                                                                <div className="TabPanelInner baggage-container">
                                                                                    <h4 className="baggage-title">Baggage</h4>
                                                                                    <div className="flight_info_taable mt-1">
                                                                                        <div>
                                                                                            <PiHandbagFill className="cabin-bag-icon"/>
                                                                                            <span className="baggage-type">Cabin :</span>
                                                                                            <span className="baggage-quantity">{conn.handbaggageWeight} Kg</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <MdLuggage className="cabin-bag-icon"/>
                                                                                            <span className="baggage-type">Check-in :</span>
                                                                                            <span className="baggage-quantity">{conn.checkInBaggageWeight} Kg</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> 
                                                                    :""}
                                                                </>
                                                        );
                                                    })}
                                                </>
                                            :
                                                    <div className="flight_show_down_wrapper border-none" key={1}>
                                                        <div className="flight-shoe_dow_item">
                                                            <div className="airline-details">
                                                                <div className="img"><img src={ticketDetails.airlineLogo} alt="img" /></div>
                                                                <span className="airlineName fw-500">{ticketDetails.airline_name}</span>
                                                                <span className="flightNumber"> {ticketDetails.airline_no}</span>
                                                            </div>
                                                            <div className="flight_inner_show_component">
                                                                <div className="flight_det_wrapper">
                                                                    <div className="flight_det">
                                                                        <div className="code_time">
                                                                            <span className="code"> {ticketDetails.departureSectorCode}</span>
                                                                            <span className="time"> {ticketDetails.departureTime}</span>
                                                                        </div>
                                                                        <p className="airport">{ticketDetails.departureTerminal} {ticketDetails.departureSectorLocation}</p>
                                                                        <p className="date"> {ticketDetails.formattedDepartureDate}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flight_duration">
                                                                    <div className="arrow_right"></div>
                                                                    <span> {ticketDetails.timeDifference}</span>
                                                                </div>
                                                                <div className="flight_det_wrapper rf-arrival-details">
                                                                    <div className="flight_det">
                                                                        <div className="code_time">
                                                                            <span className="code"> {ticketDetails.arrivalSectorCode}</span>
                                                                            <span className="time"> {ticketDetails.arrivalTime}</span>
                                                                        </div>
                                                                        <p className="airport">{ticketDetails.arrivalTerminal} {ticketDetails.arrivalSectorLocation}</p>
                                                                        <p className="date"> {ticketDetails.formattedArrivalDate}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flight_refund_policy">
                                                            <div className="TabPanelInner baggage-container">
                                                                <h4 className="baggage-title">Baggage</h4>
                                                                <div className="flight_info_taable mt-1">
                                                                    <div>
                                                                        <PiHandbagFill className="cabin-bag-icon"/>
                                                                        <span className="baggage-type">Cabin :</span>
                                                                        <span className="baggage-quantity">{ticketDetails.handBaggageWeight} Kg</span>
                                                                    </div>
                                                                    <div>
                                                                        <MdLuggage className="cabin-bag-icon"/>
                                                                        <span className="baggage-type">Check-in :</span>
                                                                        <span className="baggage-quantity">{ticketDetails.checkInBaggageWeight} Kg</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TicketDetails;