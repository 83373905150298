import React from 'react';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Home from '../components/Home';
import AboutUs from '../components/Pages/AboutUs/index';
import ContactUs from '../components/Pages/ContactUs/index';
import TourDetails from '../components/Pages/TourDetails';
import Tour from '../components/Pages/Tour';
import ExploreDestination from '../components/Pages/Package/index';
import ExploreTourByCategory from '../components/Pages/Package/ExploreTourByCategory';
import FlightTicket from '../components/Pages/FlightTicket';
import FlightBooking from '../components/Pages/FlightTicket/FlightBooking';
import Login from '../components/Login';
import SignUp from '../components/SignUp';
import InvoicePDF from '../components/Pages/Invoice';
import Success from '../components/Pages/Success';
import FailedPayment from '../components/Pages/Failed';
import PrivacyAndPolicy from '../components/Policies/PrivacyAndPolicy';
import RefundAndCancellation from '../components/Policies/RefundAndCancellation';
import TermAndConditions from '../components/Policies/TermAndConditions';
import Profile from '../components/Profile';
import CustomerService from '../components/Pages/CustomerService';
import BookedAirTicket from '../components/Pages/Booking/FlightTicket';
import ETicket from '../components/Pages/Booking/ETicket';
import Account from '../Module/Account/Pages';
import Calendar from '../components/Calendar';
import NotFound from '../components/Pages/NotFound/NotFound';
import AuthenticationWrapper from '../Common/Auth/AuthenticationWrapper';
import Wallet from '../Module/Account/Pages/Wallet';
import PackageBookings from '../Module/Account/Pages/PackageBookings';
const Routing=()=>{
    return(
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Home />}/>
                    <Route path='/login' element={<Login />} />
                    <Route path='/signup' element={<SignUp />} />
                    <Route path='/about-us' element={<AboutUs />} />
                    <Route path='/contact-us' element={<ContactUs />} />
                    <Route path='/tour-details' element={<TourDetails />} />
                    <Route path='/tour-list' element={<Tour />} />
                    <Route path='/explore-destination' element={<ExploreDestination />} />
                    <Route path='/best-deals' element={<ExploreTourByCategory />} />
                    <Route path='/search-flight' element={<FlightTicket />} />
                    <Route path='/payment-success' element={<Success />} />
                    <Route path='/payment-failed' element={<FailedPayment />} />
                    <Route path='/privacy-policy' element={<PrivacyAndPolicy />} />
                    <Route path='/refund-cancellation-policy' element={<RefundAndCancellation />} />
                    <Route path='/term-conditions-policy' element={<TermAndConditions />} />
                    <Route path='/customerservice' element={<CustomerService />} />
                    <Route path='/flightbooking' element={<AuthenticationWrapper component={<FlightBooking />} />}/>
                    <Route path='/invoice' element={<AuthenticationWrapper component={<InvoicePDF />} />}/>
                    <Route path='/userprofile' element={<AuthenticationWrapper component={<Profile />} />}/>
                    <Route path='/air-ticket-bookings' element={<AuthenticationWrapper component={<BookedAirTicket />} />} />
                    <Route path='/e-ticket' element={<AuthenticationWrapper component={<ETicket />} />} />
                    <Route path='/account' element={<AuthenticationWrapper component={<Account />} />} />
                    <Route path='/wallet' element={<AuthenticationWrapper component={<Wallet />} />} />
                    <Route path='/packagebookings' element={<PackageBookings />} />
                    <Route path='/cal' element={<Calendar />} />
                     {/* Catch-all route for 404 */}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
            {/* <RouterProvider router={routes}/> */}
        </>
    )

}
export default Routing;