import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import './PartnerSlider.css'; // Ensure to create this CSS file

const PartnerSlider = () => {
    const options = {
        loop: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        autoplayTimeout: 3000,  // Slide duration in milliseconds (3 seconds)
        smartSpeed: 1000,
        margin: 10,
        nav: false,
        responsive: {
            0: { items: 2 },
            768: { items: 4 },
            992: { items: 4 },
            1200: { items: 8 }
        }
    };

    return (
        <section id="our_partners" className="section_padding">
            <div className="container">
                {/* Section Heading */}
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="section_heading_center">
                            <h2>Our partners</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <OwlCarousel className="partner_slider_area owl-theme" {...options}>
                            <div className="partner_logo">
                                <a href="#!"><img src="assets/img/partner/1.png" alt="logo" /></a>
                            </div>
                            <div className="partner_logo">
                                <a href="#!"><img src="assets/img/partner/2.png" alt="logo" /></a>
                            </div>
                            <div className="partner_logo">
                                <a href="#!"><img src="assets/img/partner/3.png" alt="logo" /></a>
                            </div>
                            <div className="partner_logo">
                                <a href="#!"><img src="assets/img/partner/4.png" alt="logo" /></a>
                            </div>
                            <div className="partner_logo">
                                <a href="#!"><img src="assets/img/partner/5.png" alt="logo" /></a>
                            </div>
                            <div className="partner_logo">
                                <a href="#!"><img src="assets/img/partner/6.png" alt="logo" /></a>
                            </div>
                            <div className="partner_logo">
                                <a href="#!"><img src="assets/img/partner/7.png" alt="logo" /></a>
                            </div>
                            <div className="partner_logo">
                                <a href="#!"><img src="assets/img/partner/8.png" alt="logo" /></a>
                            </div>
                            <div className="partner_logo">
                                <a href="#!"><img src="assets/img/partner/5.png" alt="logo" /></a>
                            </div>
                            <div className="partner_logo">
                                <a href="#!"><img src="assets/img/partner/3.png" alt="logo" /></a>
                            </div>
                            <div className="partner_logo">
                                <a href="#!"><img src="assets/img/partner/2.png" alt="logo" /></a>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PartnerSlider;
