import React, { useState } from "react";
import ReviewRating from "./ReviewRating";
import StarRating from "./Rating";

const Review = ()=>{
    const [reviewList,setReviewList]=useState([
        {
            name:'Sam',
            rating:4,
            date:'01 Dec, 2024',
            review:'oved the overall tour for all 6 days covering Manali Dheradoon and jaisalmer.'
        },
        {
            name:' Sheikh',
            rating:3,
            date:'08 Dec, 2024',
            review:'oved the overall tour for all 6 days covering jaipur jodhpur and jaisalmer. worth ur money for sure. thanks. Driver was very good and polite and safe driving for all 6 days. on time pickup and drop overall. Thanks for it. '
        },
        {
            name:'Khan',
            rating:4,
            date:'08 Nov, 2023',
            review:'oved the overall tour for all 6 days covering jaipur jodhpur and jaisalmer. worth ur money for sure. thanks. Driver was very good and polite and safe driving for all 6 days. on time pickup and drop overall. Thanks for it. '
        },
       
    ]);
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        rating: 0,
        comments: ''
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
    
      const handleRatingChange = (rating) => {
        setFormData({
          ...formData,
          rating
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
        console.log('Form Data:', formData);
      };
    return(
       <section>
         <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="write_your_review_wrapper">
                        <h3 className="heading_theme">Write your review</h3>
                        <div className="write_review_inner_boxed">
                        <form  onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12 mt-1 mb-1">
                                    <div className="form-group">
                                        <input
                                        type="text"
                                        className="form-control bg_input"
                                        placeholder="Enter full name"
                                        name="fullName"
                                        value={formData.fullName}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 mt-1 mb-1">
                                    <div className="form-group">
                                        <input
                                        type="text"
                                        className="form-control bg_input"
                                        placeholder="Enter phone number*"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 mt-1 mb-1">
                                    <div className="form-group">
                                        <input
                                        type="email"
                                        className="form-control bg_input"
                                        placeholder="Enter email address"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                
                                <div className="col-lg-3 col-md-6 col-sm-12 mt-1 mb-1">
                                    <div className="form-group">
                                        {/* <ReviewRating
                                        rating={formData.rating}
                                        onRatingChange={handleRatingChange}
                                        /> */}
                                        <select className="form-control bg_input">
                                            <option value="0">Choose Rating</option>
                                            <option value="1 star">1 star</option>
                                            <option value="2 star">2 star</option>
                                            <option value="3 star">3 star</option>
                                            <option value="4 star">4 star</option>
                                            <option value="5 star">5 star</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-12 mt-2 ">
                                    <div className="form-group">
                                        <textarea
                                        rows="6"
                                        placeholder="Write your comments"
                                        className="form-control bg_input"
                                        name="comments"
                                        value={formData.comments}
                                        onChange={handleChange}
                                        ></textarea>
                                    </div>
                                    <div className="comment_form_submit mt-2">
                                        <button className="btn cus-btn-theme btn_md" type="submit" >
                                        Post comment
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-lg-12">
                    <div className="all_review_wrapper">
                        <h3 className="heading_theme">All review</h3>
                    </div>
                </div>
               {reviewList && reviewList.map((val,ind)=>{
                    return(
                        <>
                        <div className="col-lg-4 col-md-6" key={ind}>
                            <div className="all_review_box">
                                <div className="all_review_date_area">
                                    <div className="all_review_date">
                                        <h5>{val.date}</h5>
                                    </div>
                                    <div className="all_review_star">
                                        <h5>Rating</h5>
                                        <StarRating rating={val.rating} />
                                        
                                    </div>
                                </div>
                                <div className="all_review_text mt-0">
                                    <h4>{val.name}</h4>
                                    <p>{val.review}</p>
                                </div>
                            </div>
                        </div>
                        </>
                    )
               })}
            </div>
        </div>
       </section>
    )
}
export default Review;