import React, { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import hotel from '../assets/img/tr-image/icons/hotel.png'
import umrah from '../assets/img/tr-image/icons/umrah.png'
import honeymoon from '../assets/img/tr-image/icons/honeymoon.png'
import vacation from '../assets/img/tr-image/icons/vacation.png'
import event from '../assets/img/tr-image/icons/event.png'
import plane from '../assets/img/tr-image/icons/plane.png'
import handshake from '../assets/img/tr-image/icons/handshake.png'
// import './TourTypesSlider.css'; // Ensure to create this CSS file

const ServicesSlider = () => {
    const options = {
        loop: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        autoplayTimeout: 3000,  // Slide duration in milliseconds (3 seconds)
        smartSpeed: 1000,
        margin: 30,
        nav: true,
        navText: [
            "<i class='fas fa-arrow-left'></i>", 
            "<i class='fas fa-arrow-right'></i>"
        ],
        responsive: {
            0: { items: 1 },
            768: { items: 2 },
            992: { items: 3 },
            1200: { items: 5 }
        }
    };
    const [services,setServices]=useState([
        {'name':'Hotels','description':'Hotels','icon':hotel},
        {'name':'Umrah Packages','description':'Umrah Packages' ,'icon':umrah},
        {'name':'Honeymoon Packages','description':'Honeymoon Packages' ,'icon':honeymoon},
        {'name':'Holiday Packages','description':'Holiday Packages','icon':vacation},
        {'name':'Events','description':'Events','icon':event},
        {'name':'Flight Tickets','description':'Flight Tickets','icon':plane},
        {'name':'Travel Consultant','description':'Travel Consultant','icon':handshake}
    ])
    return (
        <section id="popular_tours_four" className="section_padding_top">
            <div className="container">
                {/* Section Heading */}
                <div className="row mb-3">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="section_heading_center">
                            <h2>Our Services</h2>
                        </div>
                    </div>
                </div>
                {/* Inner Content */}
                <div className="row">
                    <div className="col-lg-12">
                        <OwlCarousel className="tour_type_slider owl-theme" {...options}>
                            {services && services.map((service,index)=>{
                                return(
                                    <>
                                        <div className="tour_type_boxed">
                                            <div><img src={service.icon} alt='service-icon' style={{height:'50px'}}/></div>
                                            <h3 className='c-text-wrap'>{service.name}</h3>
                                        </div>
                                    </>
                                )
                            })}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesSlider;
