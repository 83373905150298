import React, { useEffect, useState } from "react";
import { FaIndianRupeeSign } from "react-icons/fa6";

const FareSummary=({dataList,isReviewFlight=false})=>{
    const[ticketDetails,setTicketDetails]=useState(dataList);
    useEffect(() => {
        setTicketDetails(dataList);
    }, [dataList]);
    return(<>
        <div className="tour_details_right_boxed">
            <div className="tour_package_details_bar_price fareSummaryTitle">
                    <h5>Fare Summary </h5>
                    <span>Fare Rule </span>
            </div>
            <div className="tour_package_details_bar_list">
                <form id="contact_form_content">
                    <div className="row">
                    <div className="col-6"><p className="faresummary-content">Traveller</p></div>
                    <div className="col-6 text-end"><p className="faresummary-content">1</p></div>
                    <div className="col-6"><p className="faresummary-content">Fare Type</p></div>
                    <div className="col-6 text-end"><p className="faresummary-content">{ticketDetails.ticketType}</p></div>
                    <div className="col-6"><p className="faresummary-content">Total Fare</p></div>
                    <div className="col-6 text-end">
                        <p className="faresummary-content">
                        <FaIndianRupeeSign className="marginBottom3"/>
                        {ticketDetails.normalTicketFare}
                        </p>
                    </div>
                    {isReviewFlight === false ?
                    <>
                        <div className="col-8 mt-4 paid-amount"><p className="faresummary-content fw-600">Amount To Be Paid</p></div>
                        <div className="col-4 mt-4 paid-amount text-end">
                            <p className="faresummary-content fw-600">
                                <FaIndianRupeeSign className="marginBottom3"/>
                                {ticketDetails.normalTicketFare}
                            </p>
                        </div>
                    </>
                    :''}
                    
                    
                    </div>
                </form>
            </div>
        </div>
    </>)
}
export default FareSummary;