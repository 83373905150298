import React, { useEffect, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import apiService from "../../../../Common/ApiFactory/apiService";
import Common from "../../../../Common/Common";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Header from "../../../../Module/Account/Component/Header";
import Loader from "../../../../Loader/Loader";
import { MdCurrencyRupee } from "react-icons/md";

const BookedAirTicket = () => {
    const navigate=useNavigate();
    const [showLoader,setShowLoader]=useState(0);
    const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const[bookedTicketList,  setBookedTicketList] = useState([]);
    const[loginUserDetails, setLoginUserDetails]=useState(Common.getUserLoginDetails());
    const[isCustomDateRange, setisCustomDateRange]=useState(0);
    const[bookingNo, setBookingNo]=useState('');
    const[fromDate, setFromDate]=useState('');
    const[toDate, setToDate]=useState('');
    const RetrieveFlightBooking=async()=>{
        if(isCustomDateRange === "1" && fromDate > toDate){
            setIsSnackbarVisible(true);
            setSnackbarMessage('From Date should be less than To Date');
            setTimeout(() => {
                setIsSnackbarVisible(false);
                setSnackbarMessage('');
            }, 1000);
            return
        }
        setShowLoader(1);
        const obj={
            userID:loginUserDetails.userID,
            session:loginUserDetails.sessionToken,
            fromDate:isCustomDateRange === "1" ? fromDate:'',
            toDate:isCustomDateRange === "1" ? toDate :''
        }
        try{
            const {status,message,responseValue} = await apiService.get(`User/RetrieveFlightBooking?userId=${obj.userID}&SessionToken=${obj.session}&FromDate=${obj.fromDate}&ToDate=${obj.toDate}`);
            console.log('ticket list : responseValue',responseValue);
            if(status === 1){
                setBookedTicketList(responseValue);
            }
            else{
                setBookedTicketList([]);
            }
            setShowLoader(0);
        }
        catch(error){
            setShowLoader(0);
            console.error('Error While fetching Booked Ticket:',error)
        }
    };
    const handleViewTicket =(item)=>{
        const ticketDetails=encodeURIComponent(JSON.stringify(item));
        const encodeData=Common.encodedData(ticketDetails);
        console.log('ticketDetails :',encodeData)
        sessionStorage.setItem('eTicketDetails',encodeData)
        navigate('/e-ticket');
    };
    useEffect(() => {
        RetrieveFlightBooking();
    },[]);
    return(
        <>
            <Header />
           <section className="cusSesctionPaddingTop">
           <div className="customTopHeader ">
                <div className="row">
                    <div className="col-lg-2 col-md-4 col-sm-12">
                        <div className="form-group">
                                <span>Date Range</span>
                                <select class="form-control filterItem" onChange={(e)=>{setisCustomDateRange(e.target.value)}} value={isCustomDateRange}>
                                    <option value="0">All</option>
                                    <option value="1">Custom</option>
                                </select>
                        </div>
                    </div>
                {isCustomDateRange === "1" ?
                    <>
                    <div className="col-lg-2 col-md-4 col-sm-12 ">
                        <span>From Date</span>
                        <input type="date" class="form-control filterItem" name="fromDate" value={fromDate} onChange={(e)=>{setFromDate(e.target.value)}} />
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 ">
                        <span>To Date</span>
                        <input type="date" class="form-control filterItem" name="toDate" value={toDate} onChange={(e)=>{setToDate(e.target.value)}} />
                    </div>
                    </>
                :''}
                
                <div className="col-lg-2 col-md-4 col-sm-12 ">
                <span>Search By Booking No</span>
                    <input type="text" class="form-control filterItem" name="bookingNo" value={bookingNo} placeholder="Booking No" onChange={(e)=>{setBookingNo(e.target.value)}} />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12 padingTop25Px">
                    <button type="button" className="btn customBtn me-1" onClick={RetrieveFlightBooking}>Search</button>
                    <button type="button" className="btn customBtn" onClick={RetrieveFlightBooking}>Clear</button>
                </div>
                </div>
            </div>

                <div className="container-fluid">
                    <div className="ticket-list">
                        <div className="row">
                            {bookedTicketList && bookedTicketList.map((item,index)=>{
                                return(
                                    <div className="col-lg-3 col-md-4 col-sm-12" key={index+1}>
                            <div class="card pd0">
                                <div class="card-header">Booking Date : <span className="ftWeight600">{item.bookingDate ?? ''}</span></div>
                                <div class="card-body">
                                    <div className="ticket-details">
                                        <div className="time-diff"><b>{item.airline_name ?? ''}</b> - {item.airline_no}</div>
                                        <div className="sector">{item.departureSectorCode ?? ''} <FaLongArrowAltRight /> {item.arrivalSectorCode ?? ''} <span className="stoppage">({item.flightStoppage ?? ''})</span></div>
                                        <div className="time-diff">{item.timeDifference ?? ''}</div>
                                        <div className="travel-date">Travel Date  : <span>{item.departureTime ?? ''} {item.departureDate}</span></div>
                                        
                                    </div>
                                    <div className="ticket-action">
                                        <div type="button" className="btn-price"><MdCurrencyRupee className="rupeeIcon"/> <span className="ticketPrice">{item.bookedFare}</span></div>
                                        <button type="button" className="btn customBtn" onClick={()=>{handleViewTicket(item)}}><FaEye /> View</button>
                                    </div>
                                </div>
                            </div>
                        </div>  
                                )
                            })}                        
                                                        
                        </div>
                    </div>
                </div>
           </section>
           <div id="snackbar" className={isSnackbarVisible ? "show" : ""}> {snackbarMessage} </div>
           {showLoader ? <Loader showLoader={showLoader} /> :''}
        </>
    )
}
export default BookedAirTicket