import React, { useState, useEffect } from "react";
import { addMonths, startOfMonth, endOfMonth, eachDayOfInterval, format, isBefore, isValid, getDay } from "date-fns";
import "./style.css";  // Link the new CSS file

const Calendar = () => {
  const [notes, setNotes] = useState({});
  const [monthOffset, setMonthOffset] = useState(0);

  useEffect(() => {
    // Fetch notes from API
    // fetch("https://api.example.com/notes")
    //   .then((response) => response.json())
    //   .then((data) => setNotes(data));
  }, []);

  const currentMonth = startOfMonth(addMonths(new Date(), monthOffset));
  const nextMonth = addMonths(currentMonth, 1);
  const today = new Date();

  // Ensure valid days are returned
  const getDaysInMonth = (month) => {
    const start = startOfMonth(month);
    const end = endOfMonth(month);
    const days = eachDayOfInterval({ start, end });

    return days.filter(day => isValid(day));
  };

  // Get the starting weekday of the month (0 for Sunday, 1 for Monday, etc.)
  const getStartDayOfMonth = (month) => {
    return getDay(startOfMonth(month)); // Sunday = 0, Monday = 1, etc.
  };

  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Helper to fill empty days for the beginning of the month
  const fillEmptyDays = (startDay) => {
    const emptyDays = [];
    for (let i = 0; i < startDay; i++) {
      emptyDays.push(null);
    }
    return emptyDays;
  };

  return (
    <div className="calendar-container">
      <div className="calendar-header">
        <button onClick={() => setMonthOffset(monthOffset - 1)} className="prev-button">Previous</button>
        <h2 className="month-title">{format(currentMonth, "MMMM yyyy")}</h2>
        <button onClick={() => setMonthOffset(monthOffset + 1)} className="next-button">Next</button>
      </div>
      <div className="calendar-grid">
        {[currentMonth, nextMonth].map((month, idx) => {
          const startDay = getStartDayOfMonth(month);
          const emptyDays = fillEmptyDays(startDay);
          const daysInMonth = getDaysInMonth(month);
          const allDays = [...emptyDays, ...daysInMonth]; // Combine empty days and actual days
          
          return (
            <div key={idx} className="month-card">
              <h2 className="month-header">{format(month, "MMMM yyyy")}</h2>
              <div className="week-days">
                {weekDays.map((day) => (
                  <div key={day} className="day-name">{day}</div>
                ))}
              </div>
              <div className="days-grid">
                {allDays.map((day, index) => (
                  <div
                    key={index}
                    className={`day-box ${day ? (isBefore(day, today) ? 'past-day' : '') : 'empty-day'}
                                ${day && format(day, 'yyyy-MM-dd') === format(today, 'yyyy-MM-dd') ? 'today' : ''}`}
                  >
                    {day ? (
                      <>
                        <span className="day-number">{format(day, "d")}</span>
                        <div className="note-box">
                          {notes[format(day, "yyyy-MM-dd")] || ""}
                        </div>
                      </>
                    ) : (
                      <div className="empty-box"></div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Calendar;
