import React, { useRef } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import './style.css';
const InvoicePDF = () => {
    const invoiceRef = useRef(null);
  
    const handleDownloadPDF = () => {
      const doc = new jsPDF("p", "pt", "a4");
      const invoice = invoiceRef.current;
  
      html2canvas(invoice, { scale: 2 })
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const imgProps = doc.getImageProperties(imgData);
          const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
          doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
          doc.save("invoice.pdf");
        })
        .catch((err) => {
          console.error("Error generating PDF: ", err);
        });
    };
   return(
    <>
    <div class="invoice-box" id="invoice"  ref={invoiceRef}>
    <header>
        <div class="company-details">
            <h1>Travel Right</h1>
            <p>
                1234 Street Rd.vbsvdsh
            </p>
        </div>
        <div class="invoice-info">
            <h2>INVOICE</h2>
            <p>Booking No : <span>INV-0001</span></p>
            <p>Date: <span>October 8, 2024</span></p>
            
        </div>
    </header>

    <section class="client-details">
        {/* <div class="from">
            <h3>From:</h3>
            <p>Your Company</p>
            <p>contact@yourcompany.com</p>
            <p>(123) 456-7890</p>
        </div> */}
        <div class="to">
            {/* <h3>To:</h3> */}
            <p>Client Name</p>
            <p>client.email@example.com</p>
            <p>(098) 765-4321</p>
        </div>
    </section>

    <section class="invoice-items">
        <table>
            <thead>
                <tr>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Service/Product 1</td>
                    <td>2</td>
                    <td>$150.00</td>
                    <td>$300.00</td>
                </tr>
                <tr>
                    <td>Service/Product 2</td>
                    <td>1</td>
                    <td>$450.00</td>
                    <td>$450.00</td>
                </tr>
                {/* <tr class="total-row">
                    <td colspan="3">Subtotal</td>
                    <td>$750.00</td>
                </tr> */}
                {/* <tr>
                    <td colspan="3">Tax (10%)</td>
                    <td>$75.00</td>
                </tr> */}
                <tr class="total-row">
                    <td colspan="3">Price</td>
                    <td>$825.00</td>
                </tr>
            </tbody>
        </table>
    </section>

    <section class="payment-info">
        <h3>Payment Status</h3>
        <p>Success</p>
        {/* <p>Account Number: 123456789</p>
        <p>Bank Name: Your Bank</p>
        <p>SWIFT Code: ABCD1234</p> */}
    </section>

    <div class="download-button">
        <button id="download-pdf" onClick={handleDownloadPDF}>Download PDF</button>
    </div>

    <footer>
        <p>Thank you for your business!</p>
        {/* <p>If you have any questions regarding this invoice, please contact us.</p> */}
    </footer>
    </div>

</>
   )
};
export default InvoicePDF;