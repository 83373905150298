import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import Loader from "../../../Loader/Loader";
import apiService from "../../../Common/ApiFactory/apiService";
import Common from "../../../Common/Common";

const Wallet=()=>{
    const [showLoader,setShowLoader]=useState(0);
    const [transactionList,setTransactionList]=useState([]);
    const [partnerCreditDetails, setPartnerCreditDetails] = useState({});
    const[isCustomDateRange, setisCustomDateRange]=useState(0);
    const[fromDate, setFromDate]=useState('');
    const[toDate, setToDate]=useState('');
    const getCreditHistory=async()=>{
        const userDetails=Common.getUserLoginDetails();
        setShowLoader(1);
            try{
                const {status,message,responseValue}= await apiService.get(`Credit/RetrieveAddedCreditList?userID=${userDetails.userID ?? 0}&sessionToken=${userDetails.sessionToken ?? ""}`);
                if(status === 1){
                    setShowLoader(0);
                    setTransactionList(responseValue)    
                }
                else{
                    setShowLoader(0);
                }
            }
            catch(error){
                console.error("Error While Getting Credit List Err:",error);
                setShowLoader(0);
                

            }
    };
    const getPartnerCredit=async()=>{
        const obj={
            userID:Common.getUserLoginDetails().userID,
            session:Common.getUserLoginDetails().sessionToken
        }
        try{
            const {status,responseValue} = await apiService.get(`User/RetrieveDashboardData?userId=${obj.userID}&SessionToken=${obj.session}`);
            if(status === 1){
                const data=responseValue[0];
                console.log('data',data);
                setPartnerCreditDetails(data);
    
            }
            else{
                setPartnerCreditDetails({});
            }
        }
        catch(error){
            console.error('Error While fetching Dashboard Data:',error)
        }
    };
    const handleFilteredCreditTransactionHistory=()=>{
        getCreditHistory();
        getPartnerCredit();
    };
    const handleClearFilter=()=>{

    };
    useEffect(()=>{
        getCreditHistory();
        getPartnerCredit();
    },[])
    return(
        <>
         <Header />
         <div className="padTop60">
         <div className="customTopHeader ">
                <div className="row">
                    <div className="col-lg-2 col-md-4 col-sm-12">
                        <div className="form-group">
                                <span>Date Range</span>
                                <select class="form-control filterItem" onChange={(e)=>{setisCustomDateRange(e.target.value)}} value={isCustomDateRange}>
                                    <option value="0">All</option>
                                    {/* <option value="1">Custom</option> */}
                                </select>
                        </div>
                    </div>
                    {isCustomDateRange === "1" ?
                    <>
                        <div className="col-lg-2 col-md-4 col-sm-12 ">
                            <span>From Date</span>
                            <input type="date" class="form-control filterItem" name="fromDate" value={fromDate} onChange={(e)=>{setFromDate(e.target.value)}} />
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-12 ">
                            <span>To Date</span>
                            <input type="date" class="form-control filterItem" name="toDate" value={toDate} onChange={(e)=>{setToDate(e.target.value)}} />
                        </div>
                    </>
                    :''}
                
                {/* <div className="col-lg-2 col-md-4 col-sm-12 ">
                <span>Search By Booking No</span>
                    <input type="text" class="form-control filterItem" name="bookingNo" value={"bookingNo"} placeholder="Booking No"  />
                </div> */}
                <div className="col-lg-2 col-md-4 col-sm-12 padingTop25Px">
                    <button type="button" className="btn customBtn me-1" onClick={handleFilteredCreditTransactionHistory}>Search</button>
                    <button type="button" className="btn customBtn" onClick={handleClearFilter}>Clear</button>
                </div>
                </div>
            </div>
            <div className="container ">
                <div className="">
                    <table class="table table-hover table-light" >
                        <thead>
                            <tr key={1}>
                                <th scope="col" className="text-nowrap text-center">Available Credit</th>
                                <th scope="col" className="text-nowrap text-center">Expiry Date</th>
                                <th scope="col" className="text-nowrap text-center">Status</th>
                            </tr>
                            <tr key={2}>
                                <td className="text-nowrap text-center"><span className={`mt-1 ${(partnerCreditDetails.creditStatus === 'Active' && !partnerCreditDetails.isCreditExpire ) ? 'badge bg-success':'badge bg-danger'}`}>Rs {partnerCreditDetails.availableCredit}</span></td>
                                <td className="text-nowrap text-center">{partnerCreditDetails.creditExpireDate}</td>
                                <td className="text-nowrap text-center"><span className={`text-nowrap text-center ${(partnerCreditDetails.creditStatus === 'Active' && !partnerCreditDetails.isCreditExpire ) ? 'badge bg-success text-light':'badge bg-danger text-light'}`}>{partnerCreditDetails.isCreditExpire ? 'Expire' : partnerCreditDetails.creditStatus === 'Active' ? 'Active' :partnerCreditDetails.creditStatus }</span></td>
                             
                            </tr>
                            <tr key={3}>
                                <th scope="col" className="text-nowrap">Amount</th>
                                <th scope="col" className="text-nowrap">Transaction Date</th>
                                <th scope="col" className="text-nowrap">Type</th>
                            </tr>
                        </thead>
                        <tbody>
                        {console.log('transactionList',transactionList)}
                            {transactionList.length > 0 && transactionList.map((item,index)=>{
                                return(
                                    <tr key={index+4}>
                                        <td>Rs {item.amount}</td>
                                        <td>{item.transactionDate}</td>
                                        <td className="text-success">Credit</td>
                                    </tr>
                                )
                            })}
                           
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        {showLoader ? <Loader showLoader={showLoader} /> :''}
        </>
    )
}

export default Wallet;