const StarRating = ({ rating }) => {
    const stars = [];
  
    for (let i = 0; i < rating; i++) {
      stars.push(<i key={i} className="fas fa-star"></i>);
    }
  
    for (let i = rating; i < 5; i++) {
      stars.push(<i key={i + 5} className="far fa-star"></i>);
    }
  
    return <div className="review_star_all">{stars}</div>;
  };

  export default StarRating;