import React, { useEffect, useRef, useState } from "react";
import Header from "../../../Header";
import apiService from "../../../../Common/ApiFactory/apiService";
import Loader from "../../../../Loader/Loader";
import { useNavigate } from "react-router-dom";
const ExploreTourByCategory = ()=>{
    const navigate = useNavigate();
    const [showLoader,setShowLoader]=useState(0);
    const [packageList,setPackageList]=useState([]);
    const [categoryName,setCategoryName]=useState('');
    const handleCheckRequestUrl=()=>{
        setShowLoader(1);
        const urlString = window.location;
        const url = new URL(urlString);
        const param= new URLSearchParams(url.search);
        console.log('urlString',urlString)
        if(param.size){
                const key = param.get("category");
                const key2 = param.get("name");
                setCategoryName(key2);
                console.log('key',key)
                if(key){
                    try{
                        const decodedString=atob(key);
                        console.log('decodedString',decodedString)
                        getPackageList(decodedString);
                    }
                    catch(error){
                        setShowLoader(0);
                        console.error('window.URL explore-category',error)
                    }
                }
                else{
                    setShowLoader(0);
                    console.error('Invalid Url Key',key)
                }
            
            
        }
        
    };
    const getPackageList=async(param=0)=>{
        try{
            const {responseValue,status}= await apiService.get(`User/GetProductByCategory?CategoryId=${param}`);
            console.log('res',responseValue);
            if(status===1)setPackageList(responseValue)
        }
        catch(error){
            console.error('Error fn getpackage from packages->',error);
        }
        setShowLoader(0);
    };
    const handleViewPackageDetails=(param)=>{
        const encodedString=btoa(param.product_id);
        const urlParam=`product-key=${encodedString}`;
        const searchUrl=`/tour-details?${urlParam}`;
        navigate(searchUrl);
    };
    const handleSearchTour=(e)=>{
        const searchKeyword = e.target.value.toLowerCase();
        const parent = document.getElementById("tourList");
        const childElements = parent.getElementsByTagName('h4');
        for (let i = 0; i < childElements.length; i++) {
            childElements[i].parentElement.parentElement.parentElement.style.display = 
                childElements[i].innerText.toLowerCase().includes(searchKeyword) ? "block" : "none";
        }
    }
    useEffect(()=>{
        handleCheckRequestUrl();
    },[])
return(
    <>
        <Header customizedHeader={1}/>
        <section id="explore_area" className="section_padding">
        <div className="container mt-4">
           
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="section_heading_center">
                        <h2>{categoryName ||''}</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3">
                    <div className="left_side_search_area">
                       
                        <div className="left_side_search_boxed">
                            <div className="left_side_search_heading">
                                <h5>Search by name</h5>
                            </div>
                            <div className="name_search_form">
                                <input type="text" className="form-control" name="search" placeholder="e.g Manali tour" onChange={handleSearchTour}/>
                                <i className="fas fa-search"></i>
                            </div>
                        </div>
                        <div className="left_side_search_boxed">
                            <div className="left_side_search_heading">
                                <h5>Sort by </h5>
                            </div>
                            <div className="filter-price">
                                <select className="form-control"> 
                                    <option>Price : High to Low</option>
                                    <option></option>
                                </select>
                            </div>
                            <button className="apply" type="button">Apply</button>
                        </div>
                        
                        
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="row" id="tourList">
                    {packageList && packageList.map((val,ind)=>{
                        return(
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12" key={ind+1}>
                                <div className="theme_common_box_two img_hover card c-pointer">
                                    <div className="theme_two_box_img card-img" >
                                        <img src={val.media_url} alt="img" />
                                            <p><i className="fas fa-map-marker-alt"></i>{val.location}</p>
                                            
                                        </div>
                                        <div className="theme_two_box_content card-content" >
                                            <h4>{val.product_name}</h4>
                                            <p><span className="review_rating">4.8/5 Excellent</span>  </p>
                                            <div className="price-section">
                                                <div className="discounted-price me-2">₹{val.discounted_price}</div>
                                                <div className="base-price">₹{val.base_price}</div>
                                                <span className="discount-percentage">{val.discount_percentage} off</span>
                                            </div>
                                        </div>
                                        <div className="cruise_content_bottom_right jutify-content-center c-card-button">
                                            <button className="btn btn_theme btn_md col-12" onClick={()=>{handleViewPackageDetails(val)}}>View</button>
                                        </div>
                                </div>
                            </div>
                        )
                    })}
                       
                       
                    </div>
                </div>
            </div>
        </div>
        {showLoader ? <Loader showLoader={showLoader} /> :''}
        </section>
    </>
)
}

export default ExploreTourByCategory;