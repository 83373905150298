import React, { useEffect, useRef, useState } from "react";
import '../CustomCSS/Finder.css';
import { useNavigate } from "react-router-dom";
import apiService from "../Common/ApiFactory/apiService";
import url from '../Common/url';
import Common from "../Common/Common";
const Finder = ({isFlightSearch=0})=>{
    const navigate = useNavigate();
    const locationDropdownRef = useRef(null);
    const categoryDropdownRef = useRef(null);
    const fromSectorDropdownRef = useRef(null);
    const toSectorDropdownRef = useRef(null);
    const[showLocationDrpdown,setShowLocationDrpdown]=useState(false);
    const[showFromSectorDrpdown,setShowFromSectorDrpdown]=useState(false);
    const[showToSectorDrpdown,setShowToSectorDrpdown]=useState(false);
    const[searchQuery,setSearchQuery]=useState('');
    const[locationList,setLocationList]=useState([]);
    const[categoryList,setCategoryList]=useState([]);
    const[fromSectorList,setFromSectorList]=useState([]);
    const[toSectorList,setToSectorList]=useState([]);
    const[selectedCategory,setSelectedCategory]=useState(0);
    const[selectedCategoryName,setSelectedCategoryName]=useState('');
    const[showCategoryDrpdown,setShowCategoryDrpdown]=useState(false);
    const[isApiCall,setIsApiCall]=useState(0);
    const[isFromSectorApiCall,setIsFromSectorApiCall]=useState(0);
    const[isToSectorApiCall,setIsToSectorApiCall]=useState(0);
    const[selectedFromSectorName,setSelectedFromSectorName]=useState("LKO - Lucknow");
    const[selectedFromSectorID,setSelectedFromSectorID]=useState(0);
    const[selectedToSectorName,setSelectedToSectorName]=useState("DXB - Dubai");
    const[selectedToSectoID,setSelectedToSectorID]=useState(0);
    const[searchKeyFromSector,setSearchKeyFromSector]=useState("");
    const[searchKeyToSector,setSearchKeyToSector]=useState("");
    const[fromDate,setFromDate]=useState("");
    const[toDate,setToDate]=useState("");
    const[currentDate,setCurrentDate]=useState("");
    const getLocationList=async()=>{
        try{
            const {status,responseValue}= await apiService.get(`SearchEngine/SearchLocation?KeyWord=${searchQuery}`);
            if (status === 1){
                setLocationList(responseValue);
                if(responseValue.length > 0){
                    setShowLocationDrpdown(true);
                }
                else{
                    setShowLocationDrpdown(false);
                }
                
            }
            else{
                setShowLocationDrpdown(false);
            }
        }
        catch(error){
            console.error('Error Get Location List->',error);
        } 
    };
    const getCategoryList = async () => {
        try{
            const {status,responseValue}= await apiService.get(`Admin/GetCategoryList`);
            if (status === 1)setCategoryList(responseValue);
        }
        catch(error){
            console.error('Error Get Category List->',error);
        } 
    };
    const handleSearch=(e)=>{
        const value = e.target.value;
        setSearchQuery(value);
    };
    const handleSelectLocation =(param)=>{
        document.getElementById('searchLocation').value=param;
        setShowLocationDrpdown(false);
    };
    const handleSearchTour =()=>{
        const searchQuery=document.getElementById('searchLocation').value;
        const encodedString=btoa(searchQuery);
        const urlParam=`search-tour=${encodedString}`;
        const urlParam2=`category=${selectedCategory}`;
        const urlParam3=`categoryName=${selectedCategoryName}`;
        const searchUrl=`${url.searchTour}?${urlParam}&${urlParam2}&${urlParam3}`;
        navigate(searchUrl);
    };
    const handleSelectCategory=(value)=>{
        setSelectedCategory(value.id);
        setSelectedCategoryName(value.category_name);
        setShowCategoryDrpdown(false);
    };
    const handleSearchSector=async(value,type)=>{
        if(type === 1){
            setSelectedFromSectorName(value);
            setSearchKeyFromSector(value);
        }
        else{
           
            setSelectedToSectorName(value);
            setSearchKeyToSector(value);
        }
    };
    const getSectorList=async(value,type)=>{
            const{status,responseValue}= await apiService.get(`SearchEngine/SearchSector?KeyWord=${value}`);
            if(status === 1){
                if(type === 1){
                    setFromSectorList(responseValue);
                    setShowFromSectorDrpdown(true);
                }
                else{
                    setToSectorList(responseValue);
                    setShowToSectorDrpdown(true);
                }
            }
            else{
                if(type === 1){
                    if(value.length === 0){
                        setShowFromSectorDrpdown(false);
                    }
                    setFromSectorList([]);
                }
                else{
                    if(value.length === 0){
                        setShowToSectorDrpdown(false);
                    }
                    setToSectorList([]);
                }
                
            }
    }
    const handleSelectSector=(type,data)=>{
        if(type === 1){
            setSelectedFromSectorName(`${data.sectorCode} - ${data.sectorName}`);
            setSelectedFromSectorID(data.id);
            setShowFromSectorDrpdown(false);
        }
        else if(type === 2){
            setSelectedToSectorName(`${data.sectorCode} - ${data.sectorName}`);
            setSelectedToSectorID(data.id);
            setShowToSectorDrpdown(false);
        }
    };
    const handleSearchFlight=(e)=>{
        e.preventDefault();
        const obj={
            fromSectorID:selectedFromSectorID,
            fromSectorName:selectedFromSectorName,
            toSectorID:selectedToSectoID,
            toSectorName:selectedToSectorName,
            fromDate:fromDate,
            toDate:toDate
        };
        const encodedString=btoa(JSON.stringify(obj));
        const urlParam=`data=${encodedString}`;
        const searchUrl=`${url.searchFlight}?${urlParam}`;
        navigate(searchUrl);
    };
    const handlSetDate=()=>{
        const currentDate = new Date();
        const yearCurrent = currentDate.getFullYear();
        const monthCurrent = String(currentDate.getMonth() + 1).padStart(2, '0');
        const dayCurrent = String(currentDate.getDate()).padStart(2, '0');
        const formattedCurrentDate = `${yearCurrent}-${monthCurrent}-${dayCurrent}`;
        // Get the future date (3 days later)
        const futureDate = new Date(currentDate);
        futureDate.setDate(futureDate.getDate() + 3); // Add 3 days
        const yearFuture = futureDate.getFullYear();
        const monthFuture = String(futureDate.getMonth() + 1).padStart(2, '0');
        const dayFuture = String(futureDate.getDate()).padStart(2, '0');
        const formattedFutureDate = `${yearFuture}-${monthFuture}-${dayFuture}`;
        setFromDate(formattedCurrentDate);
        setToDate(formattedFutureDate);
    };
    //Use Effect
    useEffect(()=>{
        getCategoryList();
        handlSetDate();
        setCurrentDate(Common.getCurrentDate())

    },[]);
    useEffect(()=>{
        if(isApiCall){
            let timeout= setTimeout(()=>{
                getLocationList();
            },500);
            return ()=>clearTimeout(timeout);
        }
        setIsApiCall(1);
        
    
        
    },[searchQuery]);
    useEffect(()=>{
        if(isFromSectorApiCall){
            let timeout= setTimeout(()=>{
                getSectorList(searchKeyFromSector,1);
            },500);
            return ()=>clearTimeout(timeout);
        }
        setIsFromSectorApiCall(1); 
    },[searchKeyFromSector]);
    useEffect(()=>{
        if(isToSectorApiCall){
            let timeout= setTimeout(()=>{
                getSectorList(searchKeyToSector,2);
            },500);
            return ()=>clearTimeout(timeout);
        }
        setIsToSectorApiCall(1); 
    },[searchKeyToSector]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (locationDropdownRef.current && !locationDropdownRef.current.contains(event.target)) {
                setShowLocationDrpdown(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [locationDropdownRef]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (categoryDropdownRef.current && !categoryDropdownRef.current.contains(event.target)) {
                setShowCategoryDrpdown(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [categoryDropdownRef]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (fromSectorDropdownRef.current && !fromSectorDropdownRef.current.contains(event.target)) {
                setShowFromSectorDrpdown(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [fromSectorDropdownRef]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (toSectorDropdownRef.current && !toSectorDropdownRef.current.contains(event.target)) {
                setShowToSectorDrpdown(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [toSectorDropdownRef]);
return(
    <>
        <section id="theme_search_form" className={`${isFlightSearch === 1 ? "flight-search":""}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area">
                            <div className="theme_search_form_tabbtn">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="flights-tab" data-bs-toggle="tab"
                                            data-bs-target="#flights" type="button" role="tab" aria-controls="flights"
                                            aria-selected="true"><i className="fas fa-plane-departure"></i>Flights</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="tours-tab" data-bs-toggle="tab" data-bs-target="#tours"
                                            type="button" role="tab" aria-controls="tours" aria-selected="false"><i
                                                className="fas fa-globe"></i>Tours</button>
                                    </li>
                                    {/* <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="Hotels-tab" data-bs-toggle="tab"
                                            data-bs-target="#Hotels" type="button" role="tab" aria-controls="Hotels"
                                            aria-selected="false"><i className="fas fa-hotel"></i> Hotels</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="visa-tab" data-bs-toggle="tab"
                                            data-bs-target="#visa-application" type="button" role="tab" aria-controls="visa"
                                            aria-selected="false"><i className="fas fa-passport"></i> Visa</button>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="flights" role="tabpanel"
                                    aria-labelledby="flights-tab">
                                    {/* <div className="row">
                                        <div className="col-lg-12">
                                            <div className="flight_categories_search">
                                                <ul className="nav nav-tabs" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="oneway-tab" data-bs-toggle="tab"
                                                            data-bs-target="#oneway_flight" type="button" role="tab"
                                                            aria-controls="oneway_flight" aria-selected="true">One
                                                            Way</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="roundtrip-tab" data-bs-toggle="tab"
                                                            data-bs-target="#roundtrip" type="button" role="tab"
                                                            aria-controls="roundtrip"
                                                            aria-selected="false">Roundtrip</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="multi_city-tab" data-bs-toggle="tab"
                                                            data-bs-target="#multi_city" type="button" role="tab"
                                                            aria-controls="multi_city" aria-selected="false">Multi
                                                            city</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="tab-content" id="myTabContent1">
                                        <div className="tab-pane fade show active" id="oneway_flight" role="tabpanel"
                                            aria-labelledby="oneway-tab">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="oneway_search_form">
                                                        <form action="#!">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                                                    <div className="flight_Search_boxed"  ref={fromSectorDropdownRef}>
                                                                        <p>From</p>
                                                                        <input type="text" value={selectedFromSectorName} onChange={(e)=>{handleSearchSector(e.target.value,1)}}/>
                                                                        {showFromSectorDrpdown ?
                                                                            <div class="dropdown">
                                                                                <div class="dropdown-contentNew">
                                                                                    {fromSectorList && fromSectorList.map((list)=>{
                                                                                        return(
                                                                                            <>
                                                                                             <div className="row" onClick={()=>{handleSelectSector(1,list)}}>
                                                                                                <div className="cus-w-20 sector-code-btn-p"><button type="button" className="btn sector-code-btn">{list.sectorCode}</button></div>
                                                                                                <div className="cus-w-80">
                                                                                                <span className="sector-Name">{list.sectorName}</span>
                                                                                                <span className="sector-code-c">{list.sectorLocation}</span>
                                                                                                </div>
                                                                                             </div>
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        :''}
                                                                        <div className="plan_icon_posation">
                                                                            <i className="fas fa-plane-departure"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                                                    <div className="flight_Search_boxed">
                                                                        <p>To</p>
                                                                        <input type="text" value={selectedToSectorName} onChange={(e)=>{handleSearchSector(e.target.value,2)}}/>
                                                                        {showToSectorDrpdown ?
                                                                            <div class="dropdown">
                                                                                <div class="dropdown-contentNew">
                                                                                {toSectorList && toSectorList.map((list)=>{
                                                                                        return(
                                                                                            <>
                                                                                             <div className="row" onClick={()=>{handleSelectSector(2,list)}}>
                                                                                                <div className="cus-w-20 sector-code-btn-p"><button type="button" className="btn sector-code-btn">{list.sectorCode}</button></div>
                                                                                                <div className="cus-w-80">
                                                                                                <span className="sector-Name">{list.sectorName}</span>
                                                                                                <span className="sector-code-c">{list.sectorLocation}</span>
                                                                                                </div>
                                                                                             </div>
                                                                                                {/* <div onClick={()=>{handleSelectLocation(list.location)}}>
                                                                                                   <div>
                                                                                                    
                                                                                                   
                                                                                                   </div>
                                                                                                    
                                                                                                </div> */}
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        :''}
                                                                        <div className="plan_icon_posation">
                                                                            <i className="fas fa-plane-arrival"></i>
                                                                        </div>
                                                                        <div className="range_plan">
                                                                            <i className="fas fa-exchange-alt"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                                                    <div className="form_search_date">
                                                                        <div className="flight_Search_boxed date_flex_area">
                                                                            <div className="Journey_date w-100">
                                                                                <p>Travel Date</p>
                                                                                <input type="date" value={fromDate} min={currentDate} onChange={(e)=>{setFromDate(e.target.value)}}/>
                                                                                
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                    <div className="form_search_date">
                                                                        <div className="flight_Search_boxed date_flex_area">
                                                                            
                                                                            <div className="Journey_date w-100">
                                                                                <p>To date</p>
                                                                                <input type="date" value={toDate} onChange={(e)=>{setToDate(e.target.value)}}/>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                {/* <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                                                    <div className="form_search_date">
                                                                        <div className="flight_Search_boxed date_flex_area">
                                                                            <div className="Journey_date w-100">
                                                                                <p>From date</p>
                                                                                <input type="date" value="2022-05-05" />
                                                                                
                                                                            </div>
                                                                            <div className="Journey_date w-100">
                                                                                <p>To date</p>
                                                                                <input type="date" value="2022-05-08" />
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                <div className="top_form_search_button">
                                                                    <button className="btn btn_theme btn_md cus-search-button" onClick={handleSearchFlight}>Search</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="tours" role="tabpanel" aria-labelledby="tours-tab">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="tour_search_form">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div className="flight_Search_boxed" ref={locationDropdownRef}>
                                                                <p>Destination</p>
                                                                <input type="text" id="searchLocation" placeholder="Where are you going?" onChange={handleSearch}/>
                                                                {/* <input type="text" className="form-control" id="searchLocation" placeholder="where are you going" onChange={handleSearch} /> */}
                                                                {showLocationDrpdown ?
                                                                    <div class="dropdown">
                                                                        <div class="dropdown-content">
                                                                            {locationList && locationList.map((list)=>{
                                                                                return(
                                                                                    <>
                                                                                    <span onClick={()=>{handleSelectLocation(list.location)}}>{list.location}</span>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                :''}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div className="form_search_date">
                                                                <div className="flight_Search_boxed date_flex_area finder-cate-dropdown">
                                                                    <div className="Journey_date c-width-100 c-mx-auto"  ref={categoryDropdownRef}>
                                                                       <button className="form-control t-left finder-cate-btn finder-cate-heading" onClick={(e)=>{e.preventDefault();setShowCategoryDrpdown(!showCategoryDrpdown)}}>{selectedCategoryName || 'Choose Category'}</button>
                                                                       {showCategoryDrpdown ?
                                                                        <div class="dropdown">
                                                                            <div class="dropdown-content">
                                                                                {categoryList && categoryList.map((list)=>{
                                                                                    return(
                                                                                        <>
                                                                                        <span onClick={()=>{handleSelectCategory(list)}}>{list.category_name}</span>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                        :''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-lg-2  col-md-6 col-sm-12 col-12">
                                                            <div className="flight_Search_boxed dropdown_passenger_area">
                                                                <p>Passenger</p>
                                                                <div className="dropdown">
                                                                    <button className="dropdown-toggle final-count"
                                                                        data-toggle="dropdown" type="button"
                                                                        id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                                        aria-expanded="false">
                                                                        0 Passenger
                                                                    </button>
                                                                    <div className="dropdown-menu dropdown_passenger_info"
                                                                        aria-labelledby="dropdownMenuButton1">
                                                                        <div className="traveller-calulate-persons">
                                                                            <div className="passengers">
                                                                                <h6>Passengers</h6>
                                                                                <div className="passengers-types">
                                                                                    <div className="passengers-type">
                                                                                        <div className="text"><span
                                                                                                className="count pcount">2</span>
                                                                                            <div className="type-label">
                                                                                                <p>Adult</p>
                                                                                                <span>12+
                                                                                                    yrs</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="button-set">
                                                                                            <button type="button"
                                                                                                className="btn-add">
                                                                                                <i className="fas fa-plus"></i>
                                                                                            </button>
                                                                                            <button type="button"
                                                                                                className="btn-subtract">
                                                                                                <i className="fas fa-minus"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </div>
                                                        </div> */}
                                                        <div className="top_form_search_button">
                                                            <button className="btn btn_theme btn_md cus-search-button" onClick={handleSearchTour}>Search</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
)
}
export default Finder;