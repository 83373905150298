import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import url from "../../../Common/url";
import { useNavigate } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import Common from "../../../Common/Common";
import ETicket from "../Booking/ETicket";

const Success=()=>{
    const navigate = useNavigate();   
    const[isDownload,setIsDownload]=useState(false);
    const invoiceRef = useRef(null);
    const[ticketDetails,setTicketDetails]=useState({});
    const[paymentDetails,setPaymentDetails]=useState({});
    const[count,setCount]=useState(4);
    const[isShowETicket,setisShowETicket]=useState(false);

    const handleCheckRequestUrl= async()=>{
        const urlString = window.location;
        const url = new URL(urlString);
        const param= new URLSearchParams(url.search);
        if(param.size){
            const key = param.get("data");
            const key2 = param.get("response");
            if(key || key2){
                try{
                    const decodedStringData=atob(key);
                    const decodedStringResponse=atob(key2);
                    try{
                        const ticketDetails=JSON.parse(decodedStringData);
                        const getPaymentDetails=JSON.parse(decodedStringResponse);
                        setTicketDetails(ticketDetails);
                        setPaymentDetails(getPaymentDetails[0]);
                        const encodedUriData=encodeURIComponent(JSON.stringify(getPaymentDetails[0]));
                        const encodeData=Common.encodedData(encodedUriData);
                        sessionStorage.setItem('eTicketDetails',encodeData)
                    }
                    catch(error){
                        console.error('Error While Parsing URL Req Err->',error);
                        
                    }                    
                }
                catch(error){
                    console.error('Error While Decoding Url Req Err->',error);
                    
                }
            }
            
        }
        
        
    };
    const handleBackBtn=()=>{
        setisShowETicket(false); 
        navigate(url.searchFlight)
    };
    useEffect(() => {
        if(isShowETicket) setisShowETicket(false); 
        if (count === 0) {  
            setisShowETicket(true); 
            return  
        };
    
        const interval = setInterval(() => {
          setCount(prevCount => prevCount - 1);
        }, 1000);
        return () => clearInterval(interval); // Cleanup function
      }, [count]);
    useEffect(()=>{
        handleCheckRequestUrl();
        document.title="Payment - Success";
    },[]);
    useEffect(()=>{
        document.title= isShowETicket ? 'E-Ticket' : 'Payment - Success';
    },[isShowETicket]);
    return(
        <>              


            {/*  */}
           <div className="container">               
                {isShowETicket === false ?               
                <>
                    <div className="d-flex flight-booking-header">
                        <div onClick={handleBackBtn}><IoIosArrowBack className="flight-booking-header-back-icon"/></div>
                        <div><span className="flight-booking-header-title">Payments</span></div>
                        <div></div>
                    </div>
                    <div className="payment-success-container">
                        <div className="success">
                            <div>
                            <i className="fa fa-check-circle mb-3 payment-check-icon" aria-hidden="true"></i>
                            <h3> Ticket booked! </h3>
                            <p className="mt-1">{paymentDetails.firstName} Thanks for choosing us!</p>    
                            </div>
                            <div className="ticket-booking-details">
                                <div className="sector">{ticketDetails.departureSectorCode} <FaLongArrowAltRight /> {ticketDetails.arrivalSectorCode}</div>
                                <div className="time-diff">{ticketDetails.timeDifference}</div>
                                <div className="travel-date">{ticketDetails.departureTime} {ticketDetails.formattedDepartureDate}</div>
                                
                            </div> 
                            <div className="redirectMessage">You will be redirected to the ticket page in {count} seconds...</div>     
                        </div> 
                        
                    </div> 
                </> 
                :<ETicket isCustom={true} />}               
           </div>
                    
        </>
    )
}
export default Success;