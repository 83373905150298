import React, { useEffect, useState } from 'react';
import { FaFacebook, FaInstagram, FaUser, FaMoneyCheck, FaWallet, FaFileInvoice} from 'react-icons/fa';
import { PiHeadsetFill } from "react-icons/pi";
import { TbLogout2 } from "react-icons/tb";
import { BiLogIn } from "react-icons/bi";
import { IoCreate, IoWalletOutline } from "react-icons/io5";
import { Navbar, Nav, NavDropdown, Modal, Button } from 'react-bootstrap';
import '../../../CustomCSS/Header.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Common from '../../../Common/Common';
import apiService from '../../../Common/ApiFactory/apiService';
import url from '../../../Common/url';
import { RiFlightTakeoffFill } from 'react-icons/ri';
import { GiMountains } from 'react-icons/gi';
import { MdDashboard } from 'react-icons/md';

const Header = () => {
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const [activeMenu, setActiveMenu] = useState('Home');
  const [isLogin, setIsLogin] = useState(false);
  const [isShowBtnLoader, setIsShowBtnLoader] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const location = useLocation();
  const [loginUserDetails, setLoginUserDetails] = useState({});
  const [isFluid, setIsFluid] = useState(false);
  const [partnerCredit, setPartnerCredit] = useState(0);
  const [partnerCreditDetails, setPartnerCreditDetails] = useState({});


  const [menuList,setMenuList] =useState([
    { menuName: 'Dashboard', path:'/account', isParent: 0 ,icon:(<MdDashboard style={{marginBottom:'2px'}} />)},
    { menuName: 'Wallet', path: url.wallet, isParent: 0 ,icon:(<IoWalletOutline style={{marginBottom:'2px'}} />)},
    { menuName: 'Flight Ticket', path: '', isParent: 1 ,icon:(<RiFlightTakeoffFill style={{marginBottom:'2px'}} />)
      ,childMenu: [
        { menuName: 'Search Flight', path: url.searchFlight },
        { menuName: 'My Bookings', path: url.airticketbookings }
      ]
    },
    { menuName: 'Packages', path: '', isParent: 1 ,icon:(<GiMountains style={{marginBottom:'2px'}} />)
      ,childMenu: [
        { menuName: 'Search Packages', path: url.searchTour },
        { menuName: 'My Bookings', path: url.packagebookings }
      ]
    },
    { menuName: 'Invoive', path: '/partnerinvoice', isParent: 0 ,icon:(<FaFileInvoice style={{marginBottom:'2px',fontSize:'14px'}} />)}   
    
  ]);

  const redirectToLogin = () => {
    navigate(url.login);
  };
  const redirectToSignUp = () => {
    navigate(url.signup);
  };
  const handleLogout = async() => {
    const obj ={
      "userID": Common.getUserLoginDetails().userID,
      "sessionToken": Common.getUserLoginDetails().sessionToken
    }
    setIsShowBtnLoader(true)
    try{
        await apiService.post('Authentication/UserLogout',obj);
    }
    catch(error){
      console.error('Error While Logout Err :',error)
    }
    localStorage.setItem('isLoggedIn',false);
    localStorage.removeItem('userLoginData');
    localStorage.removeItem('data');
    setIsShowBtnLoader(false);
    setShowLogoutModal(false);
    navigate(url.login);
    
  };
  const closeLogoutModal = () => {
    setShowLogoutModal(false);
  };



  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
      window.addEventListener('scroll', handleScroll);
      const activeItem = menuList.filter(item => item.path === location.pathname);
      const activeMenuName = activeItem.length > 0 ? activeItem[0].menuName : "";
      setActiveMenu(activeMenuName);
   
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location, menuList]);

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth <= 1399 && window.innerWidth > 991){
        setIsFluid(true);
      }
      else{
         setIsFluid(false);
      }
    };

    // Initial check
    handleResize();

    // Add resize listener
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.navbar-collapse') && !event.target.closest('.navbar-toggler')) {
        setIsToggled(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const getPartnerCredit=async()=>{
    const obj={
        userID:Common.getUserLoginDetails().userID,
        session:Common.getUserLoginDetails().sessionToken
    }
    try{
        const {status,responseValue} = await apiService.get(`User/RetrieveDashboardData?userId=${obj.userID}&SessionToken=${obj.session}`);
        if(status === 1){
            const data=responseValue[0];
            setPartnerCreditDetails(data);
            setPartnerCredit(data.availableCredit);

        }
        else{
            setPartnerCredit(0);
        }
    }
    catch(error){
        console.error('Error While fetching Dashboard Data:',error)
    }
  };
  useEffect(() => {
    setIsLogin(Common.isUserAuthenticated());
    setLoginUserDetails(Common.getUserLoginDetails());
    getPartnerCredit();
  }, []);
  return (
    <header className={`main_header_arae`}>
       <div className={`navbar-area cus-bg ${isSticky ? 'is-sticky' : ''}`}>
        <Navbar expand="lg" sticky="top" drop="end">
          <div className={isFluid ? 'container-fluid' : 'container'}>
            <Navbar.Brand >
              <Link to='/'><span className='cus-text-white'>{Common.brandName}</span></Link>
            </Navbar.Brand>
            <span className='creditTitleMediumDevice'>Cash Bal:  Rs  {partnerCredit}</span>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            
            <Navbar.Collapse id="basic-navbar-nav">
            <div className='collaps-brand-name-container'>
              <Link to='/' className='collaps-brand-name'>{Common.brandName}</Link>
            </div>
              <Nav className="me-auto nav-collapse-def-padding">
                {menuList && menuList.map((val) => {
                  return (
                    <>  
                      {val.isParent === 0 ?
                        <Link to={val.path} className={`cus-nav-link cus-menu-a accounts ${activeMenu === val.menuName ? 'cus-active' : ''}`}>{val.icon} {val.menuName}</Link>
                        :
                        <NavDropdown title={ <>{val.icon} <span>{val.menuName}</span></>} id="basic-nav-dropdown" className='parent-menu-title default-menu-font custom-dropdown-menu marginTop1Px marginLeft2Px'>
                          {val.childMenu && val.childMenu.map((child) => {
                            return (
                              <NavDropdown.Item as={Link} to={child.path}><span>{child.menuName}</span></NavDropdown.Item>
                            )
                          })}
                        </NavDropdown>
                      }
                    </>
                  )
                })}
              </Nav>
              <Nav className='nav-collapse-def-padding'>
                <span className='partner-credit creditTitle'>Cash Bal:  Rs  {partnerCredit}</span>
                 <Link to={'/customerservice'} className={`cus-nav-link cus-menu-a support`}><PiHeadsetFill /> <span>Customer Service</span></Link>
                <NavDropdown title={<span><FaUser style={{ marginRight: '8px' }} />{isLogin ? loginUserDetails.firstName ?? 'Log in/Sign up' : 'Log in/Sign up'}</span>} id="basic-nav-dropdown" className='parent-menu-title cm cusdd default-menu-font custom-dropdown-menu'>
                  {isLogin ?
                    <>
                      <NavDropdown.Item as={Link} to={url.userProfile}><FaUser /><span className='ms-3'>My Profile</span></NavDropdown.Item>
                      <NavDropdown.Item onClick={()=>{setShowLogoutModal(true)}}><TbLogout2 /><span className='ms-3'>Logout</span></NavDropdown.Item>
                    </>
                    :
                    <>
                      <NavDropdown.Item as={Link} to={url.package} ><FaMoneyCheck /><span className='ms-3'>Packages</span></NavDropdown.Item>
                      <NavDropdown.Item onClick={redirectToLogin}><BiLogIn /><span className='ms-3'>Log in</span></NavDropdown.Item>
                      <NavDropdown.Item onClick={redirectToSignUp}><IoCreate /><span className='ms-3'>Sign up</span></NavDropdown.Item>
                    </>
                  }
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
      {/* Logout Modal */}
      <Modal show={showLogoutModal} onHide={closeLogoutModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Logout Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to logout?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeLogoutModal}>
            Cancel
          </Button>
          {isShowBtnLoader ? <span class="spinner-border text-primary" role="status"></span>
          : <Button variant="primary" onClick={handleLogout}>Logout</Button>}
        </Modal.Footer>
      </Modal>
    </header>
  );
};

export default Header;
