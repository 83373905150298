
const Common ={
    brandName:"Travel Right",

    encodedData(data){
        const encodedString=btoa(data);
        return encodedString;
    },

    decodedData(data){
        const deCodedString=atob(data);
        return deCodedString;
    },

    validateForm(data){
        try{
            const validationRes={key:'',isValid:true};
            const keys = Object.keys(data);

            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = data[key];
    
                // General validation: check if the field is empty or undefined
                if (!value || (typeof value === 'string' && value.trim() === "")) {
                    validationRes.key = key;
                    validationRes.isValid = false;
                    break; 
                }
                //Used for custom validation
               switch (key) {
                
                case 'dob':
                    if (value && isNaN(Date.parse(value))) {
                        validationRes.key=key;
                        validationRes.isValid=false;
                    }
                    break; 
                case 'passportIssueDate':
                    if (value && isNaN(Date.parse(value))) {
                        validationRes.key=key;
                        validationRes.isValid=false;
                    }
                    break; 
                case 'passportExpiryDate':
                    if (value && isNaN(Date.parse(value))) {
                        validationRes.key=key;
                        validationRes.isValid=false;
                    }
                    break;
                case 'mobileNumber':
                    if (value && !/^\d+$/.test(value) && value.length !== 10) {
                        validationRes.key=key;
                        validationRes.isValid=false;
                    }
                    break;
                
                    
                case 'email':
                    if (value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                        validationRes.key=key;
                        validationRes.isValid=false;
                    }
                    break;
                
                default:
                    break;
               }

            };

            return validationRes
        }
        catch(error){
            console.error('invalid object passing err:',error)
        }

    },

    checkEmailOrMobile(input) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;  
        const mobileRegex = /^\d{10}$/; 
        if (emailRegex.test(input)) {
            return { type: "email",code:2,value: input };
        } else if (mobileRegex.test(input)) {
            return { type: "mobile", code:1,value: input };
        } else {
            return {type:null,code:2,error: "Please enter a valid email address or a 10-digit mobile number." };
        }
    },

    isUserAuthenticated(){
        try{
            const isLogin=localStorage.getItem("isLoggedIn") ?? 0;
            const userData=localStorage.getItem("data") ?? null;
            if(isLogin !== false && isLogin !== 0 && isLogin !== null  && userData !== null){
                return true;
            }
            else{
                return false;
            }
        }
        catch(error){
            console.error('Error While fetching Data from Local Storage:',error);
        }
    },

    getUserLoginDetails(){
        const isLoggedIn=localStorage.getItem("isLoggedIn");
        const getData=localStorage.getItem("data");
        if(isLoggedIn === "true" && isLoggedIn !== null && getData !== null){
            const decodedString = atob(getData);
            const data=JSON.parse(decodedString)
            return data;
        }
        else{
            return false;
        }
        
    },

    removeFlightSearchData(){
        sessionStorage.removeItem("flightSearchData");
    },
     // Function to sort data based on sorting type 
    sortData(data,key,order){
        const sortOrder = order === 1 ? 1 : -1;
        return data.sort((a, b) => sortOrder * (a[key] - b[key]));        
    },
    getTimeDifference(fromDateTime, toDateTime){
        // Parse the input dates
        const fromDate = new Date(fromDateTime);
        const toDate = new Date(toDateTime);
        // Validate the input
        if (isNaN(fromDate) || isNaN(toDate)) {
            throw new Error("Invalid date format. Please provide valid date strings.");
        }

        // Calculate the difference in milliseconds
        const diffInMs = toDate - fromDate;

        if (diffInMs < 0) {
            throw new Error("The 'to' date must be after the 'from' date.");
        }

        // Convert milliseconds to hours and minutes
        const totalMinutes = Math.floor(diffInMs / (1000 * 60));
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours}h ${minutes}m`;
    },
    //Get Current Date
    getCurrentDate(){
        const currentDate = new Date();
        const yearCurrent = currentDate.getFullYear();
        const monthCurrent = String(currentDate.getMonth() + 1).padStart(2, '0');
        const dayCurrent = String(currentDate.getDate()).padStart(2, '0');
        const formattedCurrentDate = `${yearCurrent}-${monthCurrent}-${dayCurrent}`;
        return formattedCurrentDate;
    },
    
    
}
export default Common;