import React, { useEffect, useState } from "react";
import Common from "../../Common/Common";
import Header from "../Header";

const Profile=()=>{
    const [loginUserDetails,setloginUserDetails]=useState({});

    const getLoginUserDetails=()=>{
        setloginUserDetails(Common.getUserLoginDetails());
        console.log('Common.getUserLoginDetails()',Common.getUserLoginDetails())

    }

    useEffect(()=>{
        getLoginUserDetails();
    
    },[])
    return(
        <>
             <Header customizedHeader={1} />
                <section id="common_author_area" class="section_padding">
                    <div class="container mt-4">
                        <div class="row p-1">
                            <div class="card col-lg-12 col-md-12 m-0 p-0">
                            <div className="card-header">
                                <h5>Profile</h5>
                            </div>
                                <div class="card-body">
                                    <form>
                                        <div class="form-group row">
                                            <label for="staticName" class="col-lg-2 col-md-4 col-sm-2 col-form-label fw-bold">Name</label>
                                            <div class="col-lg-10 col-md-8 col-sm-10">
                                            <input type="text" readonly class="form-control-plaintext" id="staticName" value={`${loginUserDetails.UserName ?? ''} ${loginUserDetails.lastName}`} />
                                            </div>
                                        </div>
                                        {loginUserDetails.userTypeID === 4 ? 
                                        <div class="form-group row">
                                            <label for="staticPartnerId" class="col-lg-2 col-md-4 col-sm-2 col-form-label fw-bold">Partner Id</label>
                                            <div class="col-lg-10 col-md-8 col-sm-10">
                                            <input type="text" readonly class="form-control-plaintext" id="staticPartnerId" value={`${loginUserDetails.partnerID}`} />
                                            </div>
                                        </div>
                                        :''}
                                        
                                        {loginUserDetails.userTypeID=== 4 ? 
                                        <>
                                            <div class="form-group row">
                                                <label for="staticAgencyName" class="col-lg-2 col-md-4 col-sm-2 col-form-label fw-bold">Agency Name</label>
                                                <div class="col-lg-10 col-md-8 col-sm-10">
                                                <input type="text" readonly class="form-control-plaintext" id="staticAgencyName" value={loginUserDetails.agencyName ?? ''} />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="staticAgencyAddress" class="col-lg-2 col-md-4 col-sm-2 col-form-label fw-bold">Address</label>
                                                <div class="col-lg-10 col-md-8 col-sm-10">
                                                <input type="text" readonly class="form-control-plaintext" id="staticAgencyAddress" value={loginUserDetails.agencyAddress ?? ''} />
                                                </div>
                                            </div>
                                        </>
                                        :''}
                                        {/* <div class="form-group row">
                                            <label for="staticEmail" class="col-lg-2 col-md-4 col-sm-2 col-form-label fw-bold">Gender</label>
                                            <div class="col-lg-10 col-md-8 col-sm-10">
                                            <input type="text" readonly class="form-control-plaintext" id="staticEmail" value={loginUserDetails.agencyName ?? ''}/>
                                            </div>
                                        </div> */}
                                        <div class="form-group row">
                                            <label for="staticContactNumber" class="col-lg-2 col-md-4 col-sm-2 col-form-label fw-bold">Contact Number</label>
                                            <div class="col-lg-10 col-md-8 col-sm-10">
                                            <input type="text" readonly class="form-control-plaintext" id="staticContactNumber" value={loginUserDetails.MobileNo ?? ''} />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="staticEmail" class="col-lg-2 col-md-4 col-sm-2 col-form-label fw-bold">Email</label>
                                            <div class="col-lg-10 col-md-8 col-sm-10">
                                            <input type="text" readonly class="form-control-plaintext" id="staticEmail" value={loginUserDetails.Email ?? ''} />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="staticAccntStatus" class="col-lg-2 col-md-4 col-sm-2 col-form-label fw-bold">Account Status</label>
                                            <div class="col-lg-10 col-md-8 col-sm-10">
                                            {loginUserDetails.isHold ? 
                                                <span class="badge bg-warning text-dark">Hold</span>
                                                :<span class="badge bg-success">Active</span>
                                            }
                                            </div>
                                        </div>
                    
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            
        </>
    )
}
export default Profile