import { Link, useNavigate } from 'react-router-dom';
import CommunicationHub from '../../../Common/CommunicationHub';
import './style.css';
import url from '../../../Common/url';
import { IoIosArrowBack } from 'react-icons/io';
import { useEffect, useState } from 'react';
const FailedPayment=()=>{
    const navigate = useNavigate();   
    const[paymentDetails,setPaymentDetails]=useState({});

    //Functions
    const handleCheckRequestUrl= async()=>{
        const urlString = window.location;
        const url = new URL(urlString);
        const param= new URLSearchParams(url.search);
        if(param.size){
            const key = param.get("data");
            if(key){
                try{
                    const decodedStringData=atob(key);
                    try{
                        const getPaymentDetails=JSON.parse(decodedStringData);
                        setPaymentDetails(getPaymentDetails);                        
                    }
                    catch(error){
                        console.error('Error While Parsing URL Req Err->',error);                        
                    }                    
                }
                catch(error){
                    console.error('Error While Decoding Url Req Err->',error);                    
                }
            }
            
        }
        
        
    };
    const handleBackBtn=()=>{
        navigate(url.searchFlight);
    };
    useEffect(()=>{
            handleCheckRequestUrl();
            document.title="Payment - Failed";
    },[]);
    
    return(
        <>
        <div className="container">
            <div className="d-flex flight-booking-header">
                <div onClick={handleBackBtn}><IoIosArrowBack className="flight-booking-header-back-icon"/></div>
                <div><span className="flight-booking-header-title">Payments</span></div>
                <div></div>
            </div>
            <div className="payment-failed-wrapper">
                    <div className="message-box_failed">
                        <i className="fa fa-times-circle mb-2 animated-icon" aria-hidden="true"></i>
                        <h2> Your payment failed </h2>
                        <p>  Try again later </p> 
                        <div className='mt-3'>
                            <p className="reftitle mb-1">Transaction Id : <span className="f12">{paymentDetails.transactionID ?? ''}</span></p>
                            <p className="reftitle">Transaction Date : <span className="f12">{paymentDetails.transactionDate ?? ''}</span></p>
                        </div>  
                        <p className='mt-2 mb-0'>If payment is deducted without confirmation, please reach out to us.
                        <br /><Link to={`tel:${CommunicationHub.landLineNo}`}>{CommunicationHub.landLineNo}</Link></p>
                    </div> 
            </div> 
</div> 
        </>
    )
};
export default FailedPayment; 