import React, { useState, useEffect } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import communicationHub from '../../Common/CommunicationHub';

const BookingConfirmationModal = ({ isShowModal, data, onClose }) => {
  const [showModal, setShowModal] = useState(isShowModal);
  useEffect(() => {
    setShowModal(isShowModal);
  }, [isShowModal]);

  // Close modal handler
  const handleClose = () => {
    isShowModal=0;
    data='';
    setShowModal(false);
    if (onClose) onClose(); // Call the parent component's close handler if provided
  };

  return (
    <>
      {showModal && (
        <>
          <div
            className="modal fade show"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ display: 'block' }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content m-content">
                <div className="modal-header c-modal-header">
                  <div className="icon-box">
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </div>
                  <h4 className="modal-title w-100 heading-text">Booking in Progress!</h4>
                </div>
                <div className="modal-body">
                  <p className="text-center txt-black">
                    Booking No <span>{data.bookingNo ?? ''}</span>
                  </p>
                  <p className="text-center">
                    Our agent will reach out to you once your booking is confirmed. Sit tight, and we’ll be in touch soon!
                  </p>
                  <div className="text-center mb-2">
                    <button className="btn btn-success btn-block modal-confirm-btn">View Booking Status</button>
                  </div>
                  <p className="text-center">
                    Need help now? Call us directly at <Link to={`tel:${communicationHub.confirmBooking}`}>{communicationHub.confirmBooking}</Link>
                  </p>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-success btn-block col-12 modal-confirm-btn" onClick={handleClose}>
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            style={{ display: 'block' }}
          ></div>
        </>
      )}
    </>
  );
};

export default BookingConfirmationModal;
