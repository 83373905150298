import React, { useEffect, useState } from "react";
const HomeBanner =()=>{
    const texts = [
        "Travel far, travel often, travel smart",
        "Fly, stay, explore wherever you go, we’re with you!",
        "Wander more, worry less, let us handle the rest!",
        "Your dream getaway is just a click away!",
        "Adventure awaits book your journey today!"
      ];
      
      const [text, setText] = useState("");
      const [textIndex, setTextIndex] = useState(0);
      const [charIndex, setCharIndex] = useState(0);
      const [isDeleting, setIsDeleting] = useState(false);
      const typingSpeed = isDeleting ? 40 : 80; // Smooth typing speed
    
      useEffect(() => {
        const handleTyping = () => {
          if (!isDeleting && charIndex < texts[textIndex].length) {
            setText((prev) => prev + texts[textIndex][charIndex]);
            setCharIndex((prev) => prev + 1);
          } else if (isDeleting && charIndex > 0) {
            setText((prev) => prev.slice(0, -1));
            setCharIndex((prev) => prev - 1);
          } else if (!isDeleting && charIndex === texts[textIndex].length) {
            setTimeout(() => setIsDeleting(true), 1200); // Pause before deleting
          } else if (isDeleting && charIndex === 0) {
            setIsDeleting(false);
            setTextIndex((prev) => (prev + 1) % texts.length);
          }
        };
    
        const timer = setTimeout(handleTyping, typingSpeed);
        return () => clearTimeout(timer);
      }, [charIndex, isDeleting, textIndex]);
return(
    <>
        <section id="home_one_banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="banner_one_text">
                            <h1>{text}<span className="animate-blink">|</span></h1>
                            <h3>Find awesome flights, hotel, tour, car and packages</h3>                        
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
)
}
export default HomeBanner;