import React from 'react';
import Header from '../../Header';
import callOperatorIcon from '../../../assets/img/tr-image/icons/call-operator.png';
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from 'react-router-dom';


const CustomerService = () => {
    const dataArr=[{title:'Flight Ticket',name:'Ramakant Yadav',contactNumber:'+917081400053',whatsappNumber:'+917081400053',email:'travelright.lko@gmail.com'}
        ,{title:'Tour package',name:'Rehan Gaffar',contactNumber:'+919935775799',whatsappNumber:'+919935775799',email:'holidays@travelright.in'}
        ,{title:'Umrah',name:'Mohtashim Ansari',contactNumber:'+919651935009 ',whatsappNumber:'+919651935009 ',email:'holidays@travelright.in'}
        ,{title:'Accounts',name:'Farhan Khan',contactNumber:'+917081400054',whatsappNumber:'+917081400054',email:'travelright.lko@gmail.com'}
        ,{title:'Visa',name:'Mohtashim Ansari',contactNumber:'+919651935009 ',whatsappNumber:'+919651935009 ',email:'travelright.lko@gmail.com'}
    ]
    return (
        <div>
            <Header customizedHeader={1}/>
            <section id="about_us_top" className="section_padding">
            <div className="container">
                    <div className="row">
                    {dataArr && dataArr.map((data, index) => {
                        return (
                            <div className="col-lg-4 col-md-3 col-sm-12 col-12 mgBtm20" key={index}>
                                <div className="customer_service_boxed">
                                    <div className="content-wrapper">
                                        <img src={callOperatorIcon} alt="img" className="icon-image" />
                                        <div className="text-content">
                                            <h5>{data.title}</h5>
                                            <h6 className='mt-1'>{data.name}</h6>
                                            <div className="icon-group">
                                                {/* Call */}
                                                <Link to={`tel:${data.contactNumber}`}>
                                                    <BiSolidPhoneCall className="icon" />
                                                </Link>
                                                
                                                {/* WhatsApp */}
                                                <Link to={`https://wa.me/${data.whatsappNumber}`} target="_blank" rel="noopener noreferrer">
                                                    <IoLogoWhatsapp className="icon" />
                                                </Link>
                                                
                                                {/* Email */}
                                                <Link to={`mailto:${data.email}`} target="_blank" rel="noopener noreferrer">
                                                    <MdEmail className="icon" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                    </div>
                </div>
                </section>
        </div>
    )
}
export default CustomerService