import React from "react";
import Spinner from '../assets/img/loader/spineer-loader.gif'
import Check from '../assets/img/loader/icons-check.gif';
const Loader =({showLoader=0,success=0})=>{
    return(
        <>
            <div className={`modal fade ${showLoader === 1 ? 'show' : ''}`} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showLoader === 1 ? 'block' : 'none' }}>
              <div className="modal-dialog modal-dialog-centered" style={{display:'flex',justifyContent:'center'}}>
                <div className="modal-content" style={{width:"200px"}}>
                  <div className="modal-body" style={{display:'flex',justifyContent:'center'}}>
                  {success === 0 ?
                    <div>
                      <img src={Spinner} alt="Loader-spinner"/><br />
                        <span>Loading..</span>
                  </div>
                  
                  : 
                  
                      <div>
                        <img src={Check} alt="Check-icon"/> <br />
                        <span>Success..</span>
                  </div>
                  }
                  
                  
                  
                  </div>
                </div>
              </div>
            </div>

<div className={`modal-backdrop fade ${showLoader === 1 ? 'show' : ''}`} style={{ display: showLoader === 1 ? 'block' : 'none',display:'flex',justifyContent:'center' }}></div>
        </>
    )
}
export default Loader;