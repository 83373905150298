import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import '../CustomCSS/TrendingTour.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import apiService from '../Common/ApiFactory/apiService';
import { useNavigate } from 'react-router-dom';
import SpinnerLoader from './Loader/SpinnerLoader';
const TrendingTourSlider = ({categoryID=0}) => {
    const navigate = useNavigate();
    const [showLoader,setShowLoader]=useState(false);
    const [packageList,setPackageList]=useState([]);
    const options = {
        loop: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        autoplayTimeout: 3000,  // Slide duration in milliseconds (3 seconds)
        smartSpeed: 1000,
        margin: 10,
        nav: true,
        navText: ["<i class='fas fa-arrow-left'></i>", "<i class=' fas fa-arrow-right' ></i>"],
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4
            }
        }
    };
    const getProductList= async()=>{
        setShowLoader(true);
        try{
            const {responseValue,status}= await apiService.get(`User/GetProductByCategory?CategoryId=${categoryID}`);
            if(status===1)setPackageList(responseValue);
            setShowLoader(false);
        }
        catch(err){
            setShowLoader(false);
            console.error('Trending-tour-slider',err)
        }
    };
    const handleViewPackage=(param)=>{
        const encodedString=btoa(param.product_id);
        const urlParam=`product-key=${encodedString}`;
        const searchUrl=`/tour-details?${urlParam}`;
        navigate(searchUrl);
    }
    useEffect(()=>{
        getProductList();
    },[]);
    return (
        <section id="promotional_tours" className="section_padding_top">
        <div className="container">
            {/* <!-- Section Heading --> */}
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="section_heading_center">
                        <h2>Trending tour</h2>
                    </div>
                </div>
            </div>
        <div className="row">
            <div className="col-lg-12">
                    {/* <div className="theme_common_box_two img_hover">
                        <div className="theme_two_box_img">
                            <a href="hotel-details.html"><img src="assets/img/tab-img/hotel1.png" alt="img" /></a>
                            <p><i className="fas fa-map-marker-alt"></i>New beach, Thailand</p>
                        </div>
                        <div className="theme_two_box_content">
                            <h4><a href="hotel-details.html">Kantua hotel, Thailand</a></h4>
                            <p><span className="review_rating">4.8/5 Excellent</span> <span className="review_count">(1214
                                    reviews)</span></p>
                            <h3>$99.00 <span>Price starts from</span></h3>
                        </div>
                    </div>
                    <div className="theme_common_box_two img_hover">
                        <div className="theme_two_box_img">
                            <a href="hotel-details.html"><img src="assets/img/tab-img/hotel2.png" alt="img" /></a>
                            <p><i className="fas fa-map-marker-alt"></i>Indonesia</p>
                            <div className="discount_tab">
                                <span>50%</span>
                            </div>
                        </div>
                        <div className="theme_two_box_content">
                            <h4><a href="hotel-details.html">Hotel paradise international</a></h4>
                            <p><span className="review_rating">4.8/5 Excellent</span> <span className="review_count">(1214
                                    reviews)</span></p>
                            <h3>$99.00 <span>Price starts from</span></h3>
                        </div>
                    </div>
                    <div className="theme_common_box_two img_hover">
                        <div className="theme_two_box_img">
                            <a href="hotel-details.html"><img src="assets/img/tab-img/hotel3.png" alt="img" /></a>
                            <p><i className="fas fa-map-marker-alt"></i>Kualalampur</p>
                        </div>
                        <div className="theme_two_box_content">
                            <h4><a href="hotel-details.html">Hotel kualalampur</a></h4>
                            <p><span className="review_rating">4.8/5 Excellent</span> <span className="review_count">(1214
                                    reviews)</span></p>
                            <h3>$99.00 <span>Price starts from</span></h3>
                        </div>
                    </div>
                    <div className="theme_common_box_two img_hover">
                        <div className="theme_two_box_img">
                            <a href="hotel-details.html"><img src="assets/img/tab-img/hotel4.png" alt="img" /></a>
                            <p><i className="fas fa-map-marker-alt"></i>Mariana island</p>
                            <div className="discount_tab">
                                <span>50%</span>
                            </div>
                        </div>
                        <div className="theme_two_box_content">
                            <h4><a href="hotel-details.html">Hotel deluxe</a></h4>
                            <p><span className="review_rating">4.8/5 Excellent</span> <span className="review_count">(1214
                                    reviews)</span></p>
                            <h3>$99.00 <span>Price starts from</span></h3>
                        </div>
                    </div>
                    <div className="theme_common_box_two img_hover">
                        <div className="theme_two_box_img">
                            <a href="hotel-details.html"><img src="assets/img/tab-img/hotel6.png" alt="img" /></a>
                            <p><i className="fas fa-map-marker-alt"></i>Beach view</p>
                        </div>
                        <div className="theme_two_box_content">
                            <h4><a href="hotel-details.html">Thailand grand suit</a></h4>
                            <p><span className="review_rating">4.8/5 Excellent</span> <span className="review_count">(1214
                                    reviews)</span></p>
                            <h3>$99.00 <span>Price starts from</span></h3>
                        </div>
                    </div>
                    <div className="theme_common_box_two img_hover">
                        <div className="theme_two_box_img">
                            <a href="hotel-details.html"><img src="assets/img/tab-img/hotel7.png" alt="img" /></a>
                            <p><i className="fas fa-map-marker-alt"></i>Long island</p>
                        </div>
                        <div className="theme_two_box_content">
                            <h4><a href="hotel-details.html">Zefi resort and spa</a></h4>
                            <p><span className="review_rating">4.8/5 Excellent</span> <span className="review_count">(1214
                                    reviews)</span></p>
                            <h3>$99.00 <span>Price starts from</span></h3>
                        </div>
                    </div> */}
                {/* </OwlCarousel> */}
                <OwlCarousel className="Trending_tour_slider owl-theme" {...options}>
                    
                    {packageList && packageList.map((product,ind) => (
                        <div className="theme_common_box_two img_hover card c-pointer" key={ind+1}>
                                <div className="theme_two_box_img card-img">
                                    <img src={product.media_url} alt="img" />
                                        <p><i className="fas fa-map-marker-alt"></i>{product.location}</p>
                                    </div>
                                <div className="theme_two_box_content card-content" >
                                    <h4>{product.product_name}</h4>
                                    <p><span className="review_rating">4.8/5 Excellent</span> <span className="review_count">(1214 reviews)</span></p>
                                    {product.base_price && product.discounted_price ? (
                                        <div className="price-section">
                                            <div className="discounted-price me-2">₹{product.discounted_price}</div>
                                            <div className="base-price">₹{product.base_price}</div>
                                            <span className="discount-percentage">{product.discount_percentage} off</span>
                                        </div>
                                    ) : product.base_price ? (
                                        <h3 className="base-price"><i className="bi bi-currency-rupee"></i>{product.base_price}<span className='ms-1'>Price starts from</span></h3>
                                    ) : ''}
                                </div>
                                <div className="cruise_content_bottom_right jutify-content-center c-card-button">
                                        <button className="btn btn_theme btn_md col-12" onClick={()=>{handleViewPackage(product)}}>View</button>
                                </div>
                            </div>
                    ))}
                </OwlCarousel>
            </div>
        </div>
        {showLoader ? <SpinnerLoader /> :''}
      </div>
    </section>
    );
}

export default TrendingTourSlider;
