import { Link } from "react-router-dom"
import Header from "../Header"
import CommunicationHub from "../../Common/CommunicationHub"

const TermAndConditions = ()=>{
    return(
        <>
            <Header customizedHeader={1} isDashboard={0}/>
        <section id="tour_details_main" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="terms_service_content">
                        <h1>Terms and Conditions - Travel Right</h1>
                        <div class="terms_item">
                            <h4>Acceptance of Terms</h4>
                            <p>
                            By accessing and using the Travel Right website and services, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using our services.
                            </p>
                            
                        </div>
                        <div class="terms_item">
                            <h4>Booking and Reservations</h4>
                            <p>
                            All bookings and reservations made through Travel Right are subject to availability. We reserve the right to change prices, itineraries, or other details without notice. Travelers are responsible for providing accurate information for bookings.
                            </p>
                           
                        </div>
                        <div class="terms_item">
                            <h4>Payment</h4>
                            <p>
                            Payment for travel services must be made according to the terms specified during the booking process. Travel Right accepts various payment methods, and all transactions are processed securely.
                            </p>
                            
                        </div>
                        <div class="terms_item">
                            <h4>Cancellation and Refund</h4>
                            <p>
                            You can cancel your service or product at any time. However, Travel Right does not offer refunds.
                            </p>
                        </div>
                        <div class="terms_item">
                            <h4>Responsibilities</h4>
                            <p>Travel Right acts as an intermediary between travelers and service providers. We are not responsible for any acts, omissions, or negligence of service providers.</p>
                            <p>Travelers are responsible for obtaining necessary travel documents, visas, and complying with entry requirements for their destinations.</p>
                        </div>
                        <div class="terms_item">
                            <h4>Liability</h4>
                            <p>Travel Right is not liable for any personal injury, property damage, or other losses that may occur during travel. Travel Right is not liable for any disruptions, delays, or changes in travel plans caused by factors beyond our control.</p>
                        </div>
                        <div class="terms_item">
                            <h4>Privacy</h4>
                            <p>Your use of our services is also governed by our Privacy Policy, which can be found <Link to="/privacy-policy">here</Link>. By using our services, you consent to the collection and use of your personal information as described in our Privacy Policy.</p>
                        </div>
                        <div class="terms_item">
                            <h4>Changes to Terms and Conditions</h4>
                           <p> Travel Right reserves the right to update or modify these Terms and Conditions at any time without prior notice. It is your responsibility to review these terms periodically for changes.</p>
                        </div>
                        <div class="terms_item">
                            <h4>Contact Us</h4>
                            <p>If you have any questions or concerns about these Terms and Conditions, please contact us at <Link to="tel:+05224575101">{CommunicationHub.landLineNo}</Link>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </>
    )
}
export default TermAndConditions