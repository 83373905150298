import { Link } from "react-router-dom"
import Header from "../Header"
import CommunicationHub from "../../Common/CommunicationHub"

const RefundAndCancellation = ()=>{
    return(
        <>
        <Header customizedHeader={1} isDashboard={0}/>
        <section id="tour_details_main" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="terms_service_content">
                    <h1>Terms and Conditions - Travel Right</h1>
                        <div class="terms_item">
                            <h6>Thank you for using Travel Right We are committed to ensuring your experience on our platform is exceptional. Please read our return and refund and Cancellation policy carefully.</h6>
                            
                            
                        </div>
                        <div class="terms_item">
                            <h4>No Refund</h4>
                            <p>We do not offer refunds for services or products once payment is made. All payments are final. </p>
                           
                        </div>
                        <div class="terms_item">
                            <h4>Cancellation</h4>
                            <p>You can cancel your service or product at any time. However, Travel Right does not offer refunds.</p>
                            
                        </div>
                        <div class="terms_item">
                            <h4>Payment Failure</h4>
                            <p>If a payment fails but the amount is deducted from your account, you can contact us and provide proof of the debited payment. We will process a refund in such cases.</p>
                        </div>
                        <div class="terms_item">
                            <h4>Contact Us</h4>
                            <p> If you have any questions or concerns about our return and refund policy, please contact us at <Link to="tel:+05224575101">{CommunicationHub.landLineNo}</Link>.</p>

                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        </section>
        </>
    )
}
export default RefundAndCancellation