import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const SliderComponent = ({ images = [] }) => {
  const sliderForRef = useRef(null);
  const sliderNavRef = useRef(null);
  const [navReady, setNavReady] = useState(false);

  useEffect(() => {
    if (sliderForRef.current && sliderNavRef.current) {
      setNavReady(true);
    }
  }, []);

  const settingsFor = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: sliderNavRef.current,
  };

  const settingsNav = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: sliderForRef.current,
    dots: false,
    focusOnSelect: true,
    centerMode: true,
    centerPadding: '50px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="tour_details_img_wrapper">
      <div className="slider slider-for big-slider">
        <Slider {...settingsFor} ref={sliderForRef}>
          {/* <div>
            <img src="assets/img/banner/banner-4.png" alt="img" />
          </div>
          <div>
            <img src="assets/img/tour/big-img2.png" alt="img" />
          </div>
          <div>
            <img src="assets/img/banner/banner-two-bg-3.png" alt="img" />
          </div>
          <div>
            <img src="assets/img/banner/usa.png" alt="img" />
          </div>
          <div>
            <img src="assets/img/banner/banner-three.png" alt="img" />
          </div>
          <div>
            <img src="assets/img/tour/big-img6.png" alt="img" />
          </div>
          <div>
            <img src="assets/img/tour/big-img7.png" alt="img" />
          </div> */}
          <div>
              <img src={images.media_url_one} alt="img" style={{aspectRatio:'1.5'}}/>
            </div>
            <div>
            <img src={images.media_url_two} alt="img" style={{aspectRatio:'1.5'}}/>
            </div>
            <div>
            <img src={images.media_url_three} alt="img" style={{aspectRatio:'1.5'}}/>
            </div>
            <div>
            <img src={images.media_url_four} alt="img" style={{aspectRatio:'1.5'}}/>
            </div>
            <div>
            <img src={images.media_url_five} alt="img" style={{aspectRatio:'1.5'}}/>
            </div>
        </Slider>
      </div>

      <div className="slider slider-nav img-slide-responsive">
        <Slider {...settingsNav} ref={sliderNavRef}>
        <div>
              <img src={images.media_url_one} alt="img" style={{aspectRatio:'1.5',margin:'0px 5px 0px 5px;'}}/>
            </div>
            <div>
            <img src={images.media_url_two} alt="img" style={{aspectRatio:'1.5',margin:'0px 5px 0px 5px;'}}/>
            </div>
            <div>
            <img src={images.media_url_three} alt="img" style={{aspectRatio:'1.5',margin:'0px 5px 0px 5px;'}}/>
            </div>
            <div>
            <img src={images.media_url_four} alt="img" style={{aspectRatio:'1.5',margin:'0px 5px 0px 5px;'}}/>
            </div>
            <div>
            <img src={images.media_url_five} alt="img" style={{aspectRatio:'1.5',margin:'0px 5px 0px 5px;'}}/>
            </div>
        </Slider>
      </div>
    </div>
  );
}

export default SliderComponent;



