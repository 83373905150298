import React, { useEffect, useState } from "react";
import FadeLineLoader from '../../../assets/img/loader/Fading-line.gif';
import Common from "../../../Common/Common";
import '../../Snackbar/style.css';
import UseResetScroll from "../../../Common/AutoScroll/UseResetScroll";
import apiService from "../../../Common/ApiFactory/apiService";
import url from "../../../Common/url";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { IoInformationCircleSharp, IoShareSocialOutline } from "react-icons/io5";
import TicketDetails from "./TicketDetails";
import { FaLongArrowAltRight } from "react-icons/fa";
import { LiaRupeeSignSolid } from "react-icons/lia";
import FareSummary from "./FareSummary";
import { FaIndianRupeeSign } from "react-icons/fa6";

const FlightBooking=()=>{
    const navigate = useNavigate();
    const[showBtnLoder,setShowBtnLoder] = useState(false);
    const [isPaymentMode, setIsPaymentMode] = useState(false);
    const[ticketDetails,setTicketDetails] = useState({});
    const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [pageTitle, setPageTitle] = useState('Review Flight Details');
    const [paymentMode, setPaymentMode] = useState(0); //payment mode 1 for credit 2 for online
    const [userDetails,setUserDetails]=useState({});
    const [transactionId,setTransactionId]=useState(0);
    const [isMediumDevice,setIsMediumDevice]=useState(null);
    const [passengerDetailsInfo,setPassengerDetailsInfo]=useState('Please add correct details of the passenger as mentioned in ID Proof with contact number so that Airline can inform them in case of any change in the flight timing.');
    const [partnerCredit,setPartnerCredit]=useState(0);
    const[sendForm,setSendForm] = useState({
        title:"",
        firstAndMiddleName:"",
        lastName:"",
        dob:"",
        passportNo:"",
        passportIssueDate:"",
        passportExpiryDate:"",
        nationality:"",
        countryCode:"",
        mobileNumber:"",
        email:""
    });

    const handleCheckRequestUrl=()=>{
        const urlString = window.location;
        const url = new URL(urlString);
        const param= new URLSearchParams(url.search);
        if(param.size){
            const key = param.get("data");
            if(key){
                try{
                    const decodedString=Common.decodedData(key);
                    try{
                        const parserString=JSON.parse(decodedString);
                        if(parserString.isConnectedFlight === 1){
                            const temp=JSON.parse(parserString.connectionList);
                            parserString.connectionList=temp;
                            setTicketDetails(parserString)
                        }
                        else{
                            
                            setTicketDetails(parserString)
                        }
                    }
                    catch(error){
                        console.error('Error While Parsing URL Req Err->',error);
                    }                    
                }
                catch(error){
                    console.error('Error While Decoding Url Req Err->',error);
                }
            }
            else{
            } 
        }
        else{
        }
        
    };
    const handleChange=(e)=>{
        const {value,name}=e.target;
        const targetElement=document.getElementById(`${name}`);
            targetElement.classList.remove('is-invalid');
        if (name === "mobileNumber") {
            if(value.length < 11){
                const numericValue = value.replace(/[^0-9]/g, '');
                setSendForm((prev) => ({
                    ...prev,
                    [name]: numericValue 
                }));
            }
            
        } else {
            setSendForm((prev) => ({
                ...prev,
                [name]: value
            }));
        }
    };
    const handleBook= async ()=>{
        const validation=Common.validateForm(sendForm);
        if(validation.isValid){
            setPageTitle('Payments');
            setIsPaymentMode(true);
            setTimeout(()=>{
                window.scrollTo(0, 0);
            },200)
        }
        else{
            const targetElement=document.getElementById(`${validation.key}`);
            targetElement.classList.add('is-invalid');
            targetElement.focus();
            setSnackbarMessage("Kindly fill all details before proceeding");
            setIsSnackbarVisible(true);  
            setTimeout(()=>{
                setIsSnackbarVisible(false)
            },3000)
            

        }
    };
    const handleBackToFlightResult=()=>{
         // const url = new URL(window.location.href);
          navigate(`/search-flight`);
    };
    const generateOrderID=async()=>{
          const obj={
            "sessionToken": userDetails.sessionToken,
            "userID": userDetails.userID,
            "ticketID": ticketDetails.id,
            "amount": ticketDetails.normalTicketFare
          }
          try{
            const{status,response}= await apiService.post('Payment/GenerateOrderID',obj);
            if(status === 1){
                return response;
            }
            else{
                return null;
            }
          }
          catch(error){
            console.error('Error While Generating Order ID :',error)
          }
          

    };
    const paymentFailed=async(orderID,paymentID,apiResponse,transactionID)=>{
        const obj={
            "sessionToken": userDetails.sessionToken,
            "userID": userDetails.userID,
            "key": transactionID,
            "ticketID": ticketDetails.id,
            "orderID": orderID,
            "paymentID": paymentID,
            "apiResponse": apiResponse,
            "signature": "",
            "amount": ticketDetails.normalTicketFare 
        }
        try{
            const{status,response}= await apiService.post('Payment/PaymentFailed',obj);
            const redirectUrl=url.paymentFailed;
            navigate(redirectUrl);
           
          }
          catch(error){
            const redirectUrl=url.paymentFailed;
            navigate(redirectUrl);
            console.error('Error While Api Call PaymentFailed:',error)
          }
    };
    const paymentSuccess=async(paymentID,orderID,signature,apiResponse,transactionID)=>{
        const obj={
            "sessionToken": userDetails.sessionToken,
            "userID": userDetails.userID,
            "key": transactionID,
            "ticketID": ticketDetails.id,
            "orderID": orderID,
            "paymentID": paymentID,
            "apiResponse": apiResponse,
            "signature": signature,
            "amount": ticketDetails.normalTicketFare,
            "title": sendForm.title,
            "firstAndMiddleName": sendForm.firstAndMiddleName,
            "lastName": sendForm.lastName,
            "dob": sendForm.dob,
            "passportNo": sendForm.passportNo,
            "passportIssueDate": sendForm.passportIssueDate,
            "passportExpiryDate": sendForm.passportExpiryDate,
            "nationality": sendForm.nationality,
            "countryCode": sendForm.countryCode,
            "mobileNumber": sendForm.mobileNumber,
            "email": sendForm.email 
        }
        try{
            const{status,response}= await apiService.post('Payment/PaymentSuccess',obj);
            if(status === 1){
                const getEncodedData=Common.encodedData(JSON.stringify(response));
                const getEncodedTicketDetails=Common.encodedData(JSON.stringify(ticketDetails));
                navigate(`/payment-success?response=${getEncodedData}&data=${getEncodedTicketDetails}`);
                
            }
            else{
                return null;
            }
          }
          catch(error){
            console.error('Error While Api Call Payment Success:',error)
          }
    };
    const handlePayment = async()=>{
        setShowBtnLoder(true);
        if(paymentMode === 1){
            try{
                const obj={
                    "sessionToken": userDetails.sessionToken,
                    "userID": userDetails.userID,
                    "key": userDetails.userTypeID,
                    "ticketID": ticketDetails.id,
                    "orderID": "",
                    "paymentID": 0,
                    "apiResponse": "",
                    "signature": "",
                    "amount": ticketDetails.normalTicketFare,
                    "title": sendForm.title,
                    "firstAndMiddleName": sendForm.firstAndMiddleName,
                    "lastName": sendForm.lastName,
                    "dob": sendForm.dob,
                    "passportNo": sendForm.passportNo,
                    "passportIssueDate": sendForm.passportIssueDate,
                    "passportExpiryDate": sendForm.passportExpiryDate,
                    "nationality": sendForm.nationality,
                    "countryCode": sendForm.countryCode,
                    "mobileNumber": sendForm.mobileNumber,
                    "email": sendForm.email 
                }
                try{
                    const{status,response,message}= await apiService.post('Payment/bookTicketWithCredits',obj);
                    if(status === 1){
                        setShowBtnLoder(false);
                        const getEncodedData=Common.encodedData(JSON.stringify(response));
                        const getEncodedTicketDetails=Common.encodedData(JSON.stringify(ticketDetails));
                        navigate(`/payment-success?response=${getEncodedData}&data=${getEncodedTicketDetails}`);
                    }
                    else{
                        setSnackbarMessage(message);
                        setShowBtnLoder(false);
                        setIsSnackbarVisible(true);  
                        setTimeout(()=>{
                            setIsSnackbarVisible(false)
                        },3000)
                    }
                }
                catch(error){
                    console.error('Error While Api Call Payment Success:',error);
                    setShowBtnLoder(false);
                }
            }
            catch(error){
                console.error('Error While Booking Ticket with Credit Err:',error);
                setSnackbarMessage("Something went wrong try again...!!");
                setShowBtnLoder(false);
                setIsSnackbarVisible(true);  
                setTimeout(()=>{
                    setIsSnackbarVisible(false)
                },3000)
            }
        }
        if(paymentMode === 2){
            const result = await generateOrderID();
            const responseData=result[0];
            setTransactionId(responseData.transactionID);          
            const options = {
            key: url.rzpykey, 
            amount: parseFloat(responseData.paymentAmount)*100, 
            currency: "INR",
            name: "Travel Right",
            description: "Test Transaction For Flight Tickets",
            image: "https://example.com/your_logo.jpg",
            order_id: responseData.orderID, 
            handler: function (response) {
                setShowBtnLoder(false);
                paymentSuccess(response.razorpay_payment_id,response.razorpay_order_id,response.razorpay_signature,JSON.stringify(response),responseData.transactionID);
            },
            prefill: {
                name: `${userDetails.firstName} ${userDetails.lastName}`,
                email: userDetails.Email,
                contact: userDetails.MobileNo,
            },
            notes: {
                address: "",
            },
            theme: {
                color: "#3399cc",
            },
            };
            const razorpay = new window.Razorpay(options);
            razorpay.on('payment.failed', function (response){
                const apiResponse = response.error;
                setShowBtnLoder(false);
                paymentFailed(apiResponse.metadata.order_id,apiResponse.metadata.payment_id,JSON.stringify(apiResponse),responseData.transactionID);
                
            });
            razorpay.open();
            setShowBtnLoder(false);
        }
        
    };
    const getPartnerCredit=async(tempArr)=>{
        const obj={
            userID:tempArr.userID,
            session:tempArr.sessionToken
        }
        try{
            const {status,responseValue} = await apiService.get(`User/RetrieveDashboardData?userId=${obj.userID}&SessionToken=${obj.session}`);
            if(status === 1){
                const data=responseValue[0];
                setPartnerCredit(data.availableCredit);
            }
            else{
                setPartnerCredit(0);
            }
        }
        catch(error){
            console.error('Error While fetching Dashboard Data:',error)
        }
    }
    UseResetScroll();   
    useEffect(()=>{
        handleCheckRequestUrl();
        const tempArr=Common.getUserLoginDetails();
        setUserDetails(tempArr);
        getPartnerCredit(tempArr);
        const handleResize = () => {
            const isMedium = window.innerWidth < 992;
            setIsMediumDevice(isMedium);
        };
       
        // Run on mount
        handleResize();
        // Add listener
        window.addEventListener('resize', handleResize);
        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
      
    },[]);
    const handleBackBtn=()=>{
        if(isPaymentMode){
             setIsPaymentMode(false);
             setPaymentMode(0);
        }
        else{
            navigate(url.searchFlight)
        }
    };
    const handleShare=()=>{
        if (navigator.share) {
            navigator.share({
              title: 'My Page Title',
              text: 'Check out this amazing page!',
              url: 'test' // Share current page URL
            })
            .then(() => console.log('Successfully shared!'))
            .catch((error) => console.log('Error sharing:', error));
          } else {
            alert('Sharing is not supported on this device.');
        }
    };
    useEffect(()=>{
        document.title=pageTitle;
    },[pageTitle])
    return(
        <>
             <section id="tour_details_main" className="flight-booking">
                <div className="d-flex flight-booking-header">
                    <div onClick={handleBackBtn}><IoIosArrowBack className="flight-booking-header-back-icon"/></div>
                    <div><span className="flight-booking-header-title">{isPaymentMode ? 'Payments' : 'Review Flight'}</span></div>
                    <div onClick={handleShare}><IoShareSocialOutline className="flight-booking-header-share-btn"/></div>
                </div>
                {isPaymentMode ?
                    <div className="container-fluid flight-booking-container-padd">
                        <div className="row">
                            <div className="col-lg-4 col-md-12 flight-booking-container">
                                    <div className="tour_details_right_sidebar_wrapper">
                                        {isMediumDevice === false ?
                                            <div className="tour_detail_right_sidebar fb-left-sidebar">
                                                <FareSummary dataList={ticketDetails}/>
                                            </div>
                                        :''}
                                        <div className="tour_detail_right_sidebar">
                                            <div className="tour_details_right_boxed">
                                                <div className="tour_details_right_box_heading">
                                                    <h3 className="heading_theme sec-timing"><span className="sec">Your Flight</span></h3>
                                                    <div className="flight_show_down_wrapper border-none">
                                                        <div className="flight-shoe_dow_item">
                                                            <div className="airline-details">
                                                                <div className="img"><img src={ticketDetails.airlineLogo} alt="img" /></div>
                                                                <span className="airlineName fw-500">{ticketDetails.airline_name}</span>
                                                                <span className="flightNumber"> {ticketDetails.airline_no}</span>
                                                            </div>
                                                            <div className="airline-details">
                                                                <span className="custom-badge text-center">{ticketDetails.formattedDepartureDate}</span>
                                                            </div>
                                                            <div className="flight_inner_show_component">
                                                                <div className="flight_det_wrapper fd-left">
                                                                    <div className="flight_det">
                                                                        <div className="code_time">
                                                                            <span className="fw-700"> {ticketDetails.departureTime}</span>
                                                                        </div>
                                                                        <p className="faresummary-content text-center">{ticketDetails.departureSectorCode}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flight_duration">
                                                                    <span> {ticketDetails.timeDifference}</span>
                                                                    {/* <div className="arrow_right"></div> */}
                                                                    <div className="p-arrowRight"><FaLongArrowAltRight /></div>
                                                                    <span className="stoppageDet"> {ticketDetails.flightStoppage}</span>
                                                                </div>
                                                                <div className="flight_det_wrapper flight_det_wrapper_right">
                                                                    <div className="flight_det">
                                                                        <div className="code_time">
                                                                            <span className="fw-700"> {ticketDetails.arrivalTime}</span>
                                                                        </div>
                                                                        <p className="faresummary-content text-center">{ticketDetails.arrivalSectorCode}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                        {isMediumDevice ?
                                            <div className="tour_detail_right_sidebar fb-left-sidebar">
                                                <FareSummary dataList={ticketDetails}/>
                                            </div>
                                        :''}                              
                                    </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="tour_details_leftside_wrapper">
                                    <div className="tour_details_boxed payment-method-container">
                                        <div className="paymentType">
                                            <div className={`payment-type-container ${paymentMode === 1 ? "selected-payment-type":""}`} onClick={()=>{setPaymentMode(1)}}>
                                                    <h6 className="fw-600">Pay With Credit</h6>
                                                    <span className="fw-400">Available Balance : <span className={`${paymentMode === 1 ? 'text-light' :'text-success'} fw-bold`}>
                                                         <i className="bi bi-currency-rupee mt-1"></i>{partnerCredit ?? 0}</span>
                                                    </span>
                                               
                                            </div>
                                            <div className={`payment-type-container ${paymentMode === 2 ? "selected-payment-type":""}`} onClick={()=>{setPaymentMode(2)}}>
                                                <h6 className="fw-600">Pay With Online</h6>
                                                <span className="fw-400">UPI,Debit   & Credit Card,Net Banking</span>
                                            </div>                                            
                                        </div>
                                        {paymentMode === 1 || paymentMode === 2 ?
                                            <div className="row text-center mt-2">
                                                <div className="tour_select_offer_bar_bottom">
                                                    <button className="btn cus-btn-theme btn_md w-100" onClick={handlePayment} disabled={showBtnLoder}>
                                                    {showBtnLoder ?  <img src={FadeLineLoader} className='btn-loader' alt='loader' /> :<span className="payBtn">Pay Now (<FaIndianRupeeSign />{`${ticketDetails.normalTicketFare ?? ''}`})</span>   }
                                                    </button>
                                            
                                                </div>
                                            </div>
                                        :""}
                                        
                                        <div className="row text-center mt-2">
                                             <p><span className="fw-500">100%</span> Safe Payment Process</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    <div className="container-fluid flight-booking-container-padd">
                        <div className="row">  
                             <div className="col-lg-8"> 
                                <TicketDetails dataList={ticketDetails} />
                                {isMediumDevice ? 
                                    <div className="col-lg-4 rf-left-sidebar">
                                        <div className="tour_details_right_sidebar_wrapper">
                                            <div className="tour_detail_right_sidebar">
                                                <FareSummary dataList={ticketDetails} isReviewFlight={true}/>
                                            </div>
                                        </div>
                                        
                                    </div>   
                                
                                :''}   
                                 <div className="tour_details_right_sidebar_wrapper mt-3">
                                    <div className="tour_detail_right_sidebar">
                                        <div className="tour_details_right_boxed">
                                            <div className="tour_details_right_box_heading">
                                                <h3 className="heading_theme sec-timing"><span className="sec">Enter Passenger Details</span><IoInformationCircleSharp className="cursor-pointer" title={passengerDetailsInfo}/></h3>                                                    
                                            </div>
                                            <div className="tour_package_details_bar_list">
                                                <form id="contact_form_content">
                                                    <div className="row">
                                                        <div className="col-lg-2 col-md-2 col-sm-2">
                                                            <div className="form-floating">
                                                                <select className="form-select" id="ddlTitle" name='title' value={sendForm.title} aria-label="Floating label select example" onChange={handleChange}>
                                                                    <option value="Mr">Mr</option>
                                                                    <option value="Ms">Ms</option>
                                                                    <option value="Mrs">Mrs</option>
                                                                </select>
                                                                <label htmlFor="ddlTitle" className="custom-date-font-style">Title</label>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-lg-5 col-md-5 col-sm-10">
                                                            <div className="form-floating">
                                                                <input type="text" name="firstAndMiddleName" value={sendForm.firstAndMiddleName} className="form-control" id="firstAndMiddleName" placeholder="First & Middle Name" onChange={handleChange} />
                                                                <label htmlFor="firstAndMiddleName" className="custom-input-font-style">First & Middle Name</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                            <div className="form-floating">
                                                                <input type="text" name="lastName" value={sendForm.lastName} className="form-control" id="lastName" placeholder="Last Name" onChange={handleChange} />
                                                                <label htmlFor="lastName" className="custom-input-font-style">Last Name</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                                            <div className="form-floating">
                                                                <input type="date" name="dob" value={sendForm.dob} className="form-control" id="dob" placeholder="Date of Birth" onChange={handleChange} />
                                                                <label htmlFor="dob" className="custom-date-font-style">Date of Birth</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                                            <div className="form-floating">
                                                                <input type="text" name="passportNo" value={sendForm.passportNo} className="form-control" id="passportNo" placeholder="Passport No" onChange={handleChange} />
                                                                <label htmlFor="passportNo" className="custom-input-font-style">Passport No.</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                                            <div className="form-floating">
                                                                <input type="date" name="passportIssueDate" value={sendForm.passportIssueDate} className="form-control" id="passportIssueDate" placeholder="Passport Issue Date" onChange={handleChange} />
                                                                <label htmlFor="passportIssueDate" className="custom-date-font-style">Passport Issue Date</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                                            <div className="form-floating">
                                                                <input type="date" name="passportExpiryDate" value={sendForm.passportExpiryDate} className="form-control" id="passportExpiryDate" placeholder="Passport Expiry Date" onChange={handleChange} />
                                                                <label htmlFor="passportExpiryDate" className="custom-date-font-style">Passport Expiry Date</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                                            <div className="form-floating">
                                                                <input type="text" name="nationality" value={sendForm.nationality} className="form-control" id="nationality" placeholder="Nationality" onChange={handleChange} />
                                                                <label htmlFor="nationality" className="custom-input-font-style">Nationality</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>                                        
                                    </div> 
                                    {isMediumDevice ?
                                        <div className="tour_detail_right_sidebar">
                                            <div className="tour_details_right_boxed">
                                                <div className="tour_details_right_box_heading">
                                                    <h3 className="heading_theme sec-timing">
                                                        <span className="sec">Contact Details</span>
                                                    </h3>
                                                    
                                                </div>
                                                <div className="tour_package_details_bar_list">
                                                    <form id="contact_form_content">
                                                        <div className="row">
                                                            
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="form-floating">
                                                                    <input type="text" name="mobileNumber" value={sendForm.mobileNumber} className="form-control" id="mobileNumber" placeholder="Mobile Number" onChange={handleChange} />
                                                                    <label htmlFor="mobileNumber" className="custom-input-font-style">Mobile Number</label>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="form-floating">
                                                                    <input type="text" name="email" value={sendForm.email} className="form-control" id="email" placeholder="Email" onChange={handleChange} />
                                                                    <label htmlFor="email" className="custom-input-font-style">Email</label>
                                                                </div>
                                                            </div>
                                                            

                                                            <small className='text-danger d-none' id='validationMsg'>Oops! You missed some required fields. Please fill them in.</small>
                                                            <small className='text-danger d-none' id='errorMsg'></small>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                            <div className="tour_select_offer_bar_bottom">
                                            <button className="btn cus-btn-theme btn_md w-100" onClick={handleBook} disabled={showBtnLoder}>
                                            {showBtnLoder ?  <img src={FadeLineLoader} className='btn-loader' alt='loader' /> :<span>Book Now</span>   }
                                            </button>
                                            
                                            </div>
                                        </div>  
                                    :''}                                 
                                 </div>
                             </div>  
                            {!isMediumDevice ? 
                                <div className="col-lg-4 rf-left-sidebar">
                                    <div className="tour_details_right_sidebar_wrapper stickyFareSummryOnLargeDevice">
                                        <div className="tour_detail_right_sidebar">
                                            <FareSummary dataList={ticketDetails} isReviewFlight={true}/>
                                        </div>                                   
                                        <div className="tour_detail_right_sidebar">
                                            <div className="tour_details_right_boxed">
                                                <div className="tour_details_right_box_heading">
                                                    <h3 className="heading_theme sec-timing">
                                                        <span className="sec">Contact Details</span>
                                                    </h3>
                                                    
                                                </div>
                                                <div className="tour_package_details_bar_list">
                                                    <form id="contact_form_content">
                                                        <div className="row">
                                                            
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="form-floating">
                                                                    <input type="text" name="mobileNumber" value={sendForm.mobileNumber} className="form-control" id="mobileNumber" placeholder="Mobile Number" onChange={handleChange} />
                                                                    <label htmlFor="mobileNumber" className="custom-input-font-style">Mobile Number</label>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="form-floating">
                                                                    <input type="text" name="email" value={sendForm.email} className="form-control" id="email" placeholder="Email" onChange={handleChange} />
                                                                    <label htmlFor="email" className="custom-input-font-style">Email</label>
                                                                </div>
                                                            </div>
                                                            

                                                            <small className='text-danger d-none' id='validationMsg'>Oops! You missed some required fields. Please fill them in.</small>
                                                            <small className='text-danger d-none' id='errorMsg'></small>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                            <div className="tour_select_offer_bar_bottom">
                                            <button className="btn cus-btn-theme btn_md w-100" onClick={handleBook} disabled={showBtnLoder}>
                                            {showBtnLoder ?  <img src={FadeLineLoader} className='btn-loader' alt='loader' /> :<span>Book Now</span>   }
                                            </button>
                                            </div>
                                        </div>
                                     </div>
                                </div>   
                            
                            :''}  
                        </div>
                    </div>
                }
                <div id="snackbar" className={isSnackbarVisible ? "show" : ""}> {snackbarMessage} </div>
            </section>
            
        </>
    )
}
export default FlightBooking;