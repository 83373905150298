import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Common from "../Common";
import url from "../url";

const AuthenticationWrapper = (props) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const loginStatus = () => {
        if (Common.isUserAuthenticated()) {
            return {
                isLoggedIn: true
            };
        }
        else {
            return {
                isLoggedIn: false
            };
        }
    };
    useEffect(() => {
        const response = loginStatus();
        console.log('response auth',response)
        if (response.isLoggedIn) {
            setIsLoggedIn(true);
        }
        else {
            setIsLoggedIn(false);
            navigate(url.login)
        }

    }, []);

    return (
        <>
            {
                isLoggedIn ? props.component : ""
            }
        </>
    )
}
export default AuthenticationWrapper;