import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import './style.css';
const NotFound = () => {
    const navigate=useNavigate();
    const handleBackButton=()=>{
        document.title="Travel Right";
        navigate('/');
    };
    useState(()=>{
        document.title="😔 Page Not Found";
    },[])
  return (
    
    <div class="custombody">
            <div class="container">
        <div class="container-content">
            <p class="mr-4">4</p>
                <span>😔</span>
                <div class="search">
                    🔎
                </div>
            <p class="ml-4">4</p>
        </div>
      <p class="text-2xl">Page not found.</p>
      <div className="backBtnContainer">
         <span className="backBtn" onClick={handleBackButton}>Go Back to Home</span>
      </div>
   </div>
    </div>
  );
};

export default NotFound;
