import { useEffect, useRef, useState } from "react";
import ScrollToTop from "../../scrollToTop";
import Header from "../../Header";
import apiService from "../../../Common/ApiFactory/apiService";
import url from "../../../Common/url";
import { useNavigate } from "react-router-dom";
import Common from "../../../Common/Common";
import UseResetScroll from "../../../Common/AutoScroll/UseResetScroll";
import { Button, Modal } from "react-bootstrap";
import travel from '../../../assets/img/tr-image/Gif/travel.gif'
import '../../../CustomCSS/modalFilter.css';
import { IoTime } from "react-icons/io5";
import { PiHandbagFill } from "react-icons/pi";
import { MdLuggage } from "react-icons/md";
const FlightTicket=()=>{
    const navigate = useNavigate();
    const locationDropdownRef = useRef(null);
    const categoryDropdownRef = useRef(null);
    const fromSectorDropdownRef = useRef(null);
    const toSectorDropdownRef = useRef(null);
    const[showLocationDrpdown,setShowLocationDrpdown]=useState(false);
    const[showFromSectorDrpdown,setShowFromSectorDrpdown]=useState(false);
    const[showToSectorDrpdown,setShowToSectorDrpdown]=useState(false);
    const[searchQuery,setSearchQuery]=useState('');
    const[locationList,setLocationList]=useState([]);
    const[categoryList,setCategoryList]=useState([]);
    const[fromSectorList,setFromSectorList]=useState([]);
    const[toSectorList,setToSectorList]=useState([]);
    const[selectedCategory,setSelectedCategory]=useState(0);
    const[selectedCategoryName,setSelectedCategoryName]=useState('');
    const[showCategoryDrpdown,setShowCategoryDrpdown]=useState(false);
    const[isApiCall,setIsApiCall]=useState(0);
    const[isFromSectorApiCall,setIsFromSectorApiCall]=useState(0);
    const[isToSectorApiCall,setIsToSectorApiCall]=useState(0);
    const[selectedFromSectorName,setSelectedFromSectorName]=useState("LKO - Lucknow");
    const[selectedFromSectorID,setSelectedFromSectorID]=useState(1);
    const[selectedToSectorName,setSelectedToSectorName]=useState("DXB - Dubai");
    const[selectedToSectoID,setSelectedToSectorID]=useState(2);
    const[searchKeyFromSector,setSearchKeyFromSector]=useState("");
    const[searchKeyToSector,setSearchKeyToSector]=useState("");
    const[fromDate,setFromDate]=useState("");
    const[toDate,setToDate]=useState("");
    const[isSearching,setIsSearching]=useState(false);
    const[flightTicketList,setFlightTicketList]=useState([]);
    const[ticketListForFilter,setTicketListForFilter]=useState([]);
    const[airlineList,setAirlineList]=useState([]);
    const[selectedAirline,setSelectedAirline]=useState([]);
    const[showModalFilter,setShowModalFilter]=useState(false);
    const filterDetails={'filter':{title:'Filters',filterTypeID:1},'airline':{title:'Popular Airlines',filterTypeID:2},'sorting':{title:'Sort By',filterTypeID:3}}
    const [modalFilter,setmodalFilter]=useState({});
    const [modalFilterSelectedAirline,setModalFilterSelectedAirline]=useState([]);
    const [modalFilterSorting,setModalFilterSorting]=useState({isSorting:false,type:null,isPriceSorting:null,priceSortingType:null});
    const [show, setShow] = useState(false);
    const[currentDate,setCurrentDate]=useState("");
    const[isActiveTimeSlotFilter,setisActiveTimeSlotFilter]=useState(false);
    const[storeTicket,setStoreTicket]=useState([]);
   const [timeSlots,setTimeSlots] =useState([
        {
            id:1,
            isSelected:false,
            title: "EARLY MORNING",
            subtitle: "Before 6AM",
            icon: (
            <svg width="1em" height="1em" fontSize="1.5rem" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-testid="MorningIcon" style={{display:'inline-block'}}>
                <path d="M12.7588 5.7565c0-.4178-.3575-.7565-.7986-.7565s-.7985.3387-.7985.7565v2.226c0 .4178.3575.7565.7985.7565s.7986-.3387.7986-.7564z"></path>
                <path fillRule="evenodd" d="M4.9365 17.4274v.0597H2.726c-.401 0-.726.3386-.726.7564S2.325 19 2.726 19h18.548c.401 0 .726-.3387.726-.7565s-.325-.7564-.726-.7564h-2.2901v-.0597c0-3.6746-3.1446-6.6534-7.0237-6.6534s-7.0237 2.9788-7.0237 6.6534m7.0237-5.1405c-2.997 0-5.4266 2.3015-5.4266 5.1405v.0597h10.8532v-.0597c0-2.839-2.4296-5.1405-5.4266-5.1405" clipRule="evenodd"></path>
                <path d="M20.7554 8.8166a.7614.7614 0 0 1 0 1.0896l-1.6006 1.5741c-.306.3009-.8021.3009-1.1081 0a.7616.7616 0 0 1 0-1.0896l1.6007-1.5741c.306-.3009.8021-.3009 1.108 0M3.2446 9.8616a.7615.7615 0 0 1 0-1.0896c.306-.301.802-.301 1.108 0l1.6006 1.5741a.7615.7615 0 0 1 0 1.0896c-.306.3009-.802.3009-1.108 0z"></path>
            </svg>
            ),
        },
        {
            id:2,
            isSelected:false,
            title: "MORNING",
            subtitle: "6AM - 12PM",
            icon: (
            <svg width="1em" height="1em" fontSize="1.5rem" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-testid="AfternoonIcon" style={{display:'inline-block'}}>
                <path d="M12.7699 3.2533v1.9903c0 .9715-1.5066.9715-1.5066 0V3.2533c0-.9715 1.5066-.9715 1.5066 0m-.0332 15.5269v1.9665c0 .9715-1.5066.9715-1.5066 0v-1.9665c0-.9715 1.5066-.9715 1.5066 0m8.01-6.0104H18.78c-.9715 0-.9715-1.5066 0-1.5066h1.9667c.9715 0 .9715 1.5066 0 1.5066m-15.5053-.0331c.9715 0 .9715-1.5066 0-1.5066h-1.988c-.9716 0-.9716 1.5066 0 1.5066zm13.4877-6.3771-1.4192 1.4192c-.6869.687-1.7523-.3784-1.0653-1.0654l1.4192-1.4192c.687-.687 1.7523.3784 1.0653 1.0654M7.7349 17.307 6.336 18.7059c-.687.6869-1.7523-.3784-1.0653-1.0654l1.3989-1.3988c.687-.687 1.7523.3783 1.0653 1.0653M6.3596 5.2707c-.687-.687-1.7523.3784-1.0653 1.0653l1.4301 1.4302c.687.687 1.7523-.3784 1.0653-1.0654zm10.9377 10.9846c-.687-.687-1.7523.3783-1.0653 1.0653l1.4085 1.4086c.687.6869 1.7523-.3784 1.0654-1.0654z"></path>
                <path fillRule="evenodd" d="M12 15.5154c1.9415 0 3.5154-1.5739 3.5154-3.5154S13.9415 8.4846 12 8.4846 8.4846 10.0585 8.4846 12 10.0585 15.5154 12 15.5154m0 1.5066c2.7736 0 5.022-2.2484 5.022-5.022S14.7736 6.978 12 6.978 6.978 9.2264 6.978 12 9.2264 17.022 12 17.022" clipRule="evenodd"></path>
            </svg>
            ),
        },
        {
            id:3,
            isSelected:false,
            title: "Mid Day",
            subtitle: "12PM - 6PM",
            icon: (
            <svg width="1em" height="1em" fontSize="1.5rem" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-testid="EveningIcon" style={{display:'inline-block'}}>
                <path d="M13.7415 5.7383v2.0364c0 .9844-1.5214.9844-1.5214 0V5.7383c0-.9844 1.5214-.9844 1.5214 0"></path>
                <path fillRule="evenodd" d="M7.7099 13.1601c-.4152.8335-1.5656.9479-1.915 2.0756-1.5984.0951-2.7036 1.3079-2.7937 2.9049-.0243.4304.3274.8068.7595.8068h17.3951c1.0734 0 1.0734-1.5 0-1.5h-1.7999c0-3.8189-2.4141-7.3183-6.5605-7.3183-2.0872 0-4.1404 1.1335-5.0855 3.031m7.2822 4.2873h2.8423c0-2.9826-1.7294-5.791-5.039-5.791-1.3048 0-2.655.6054-3.4162 1.6729 1.7275.1627 2.7765 1.1599 3.2599 1.97.8356.1203 1.4397.5478 1.8368 1.0798.2561.3433.4195.7195.5162 1.0683m-7.7883-1.4566c-.1048-2.1053 3.7154-1.0874 4.236.3062.2994.8015 1.443.3545 1.902 1.1231H4.7185c.5153-.9687 2.5479-.1729 2.4853-1.4293" clipRule="evenodd"></path>
                <path d="M20.9041 9.5759c.6926-.6952-.3808-1.7776-1.0758-1.08l-1.4344 1.44c-.6926.6952.3808 1.7775 1.0758 1.0799zM5.1288 9.5351l1.4344 1.44c.695.6976 1.7684-.3848 1.0759-1.08l-1.4345-1.44c-.695-.6975-1.7684.3849-1.0758 1.08"></path>
            </svg>
            ),
        },
        {
            id:4,
            isSelected:false,
            title: "Night",
            subtitle: "After 6PM",
            icon: (
            <svg width="1em" height="1em" fontSize="1.5rem" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-testid="NightIcon" style={{display:'inline-block'}}>
                <path fill-rule="evenodd" d="M16.2278 14.9882c2.418-4.2931-.1936-9.9137-5.0336-10.935-2.5969-.548-.9751 3.289-.8841 4.5507.0802 1.112-.403 2.3652-1.2848 3.3747-.8303.9505-2.014 1.6851-3.4137 1.8828-3.6707.5182-.1742 3.8266 1.6566 4.2108C7.1028 18.7508 6.9878 20 7.9986 20H19.17c.6415 0 .83-.5264.83-1.0622 0-1.6151-1.0203-2.8711-3.0428-2.999a3.64 3.64 0 0 0-.7294-.9506m-1.1684-.7308c-.4501-.1752-.9471-.2694-1.4751-.2694-1.4893 0-2.4636.7791-3.2002 1.9508-1.1442 0-1.9521.3518-2.472.9078-.4153-.0673-.7706-.1596-1.0793-.2769-.5228-.1985-.9492-.6438-1.2351-.9951-.0736-.0903-.018-.2154.0953-.2435 2.311-.5732 3.4528-1.4055 4.4397-2.5822 1.0041-1.1971 1.6262-2.7412 1.518-4.2411-.0558-.7727-.341-1.989-.5979-2.7753-.0436-.1332.0725-.2668.2087-.2324 3.9763 1.0047 5.4313 5.2928 3.7979 8.7573m-3.9108 3.0584c.5899-.9385 1.1816-1.9508 2.4357-1.9508 1.3271 0 1.9591.8564 2.516 1.8993 1.011.064 2.3183.1159 2.5002 1.3801H8.5721c.1702-1.3468 1.47-1.3286 2.5765-1.3286" clip-rule="evenodd"></path>
            </svg>
            ),
        }
    ]);


    const getLocationList=async()=>{
        try{
            const {status,responseValue}= await apiService.get(`SearchEngine/SearchLocation?KeyWord=${searchQuery}`);
            if (status === 1){
                setLocationList(responseValue);
                if(responseValue.length > 0){
                    setShowLocationDrpdown(true);
                }
                else{
                    setShowLocationDrpdown(false);
                }

            }
            else{
                setShowLocationDrpdown(false);
            }
        }
        catch(error){
            console.error('Error Get Location List->',error);
        }
    };
    const getCategoryList = async () => {
        try{
            const {status,responseValue}= await apiService.get(`Admin/GetCategoryList`);
            if (status === 1)setCategoryList(responseValue);
        }
        catch(error){
            console.error('Error Get Category List->',error);
        }
    };
    const getAirlineList = async () => {
        try{
            const {status,responseValue}= await apiService.get(`Admin/AirlineDirectory`);
            if (status === 1)setAirlineList(responseValue);
        }
        catch(error){
            console.error('Error Get Category List->',error);
        }
    };
    const handleSearch=(e)=>{
        const value = e.target.value;
        setSearchQuery(value);
    };
    const handleSelectLocation =(param)=>{
        document.getElementById('searchLocation').value=param;
        setShowLocationDrpdown(false);
    };
    const handleSearchTour =()=>{
        const searchQuery=document.getElementById('searchLocation').value;
        const encodedString=btoa(searchQuery);
        const urlParam=`search-tour=${encodedString}`;
        const urlParam2=`category=${selectedCategory}`;
        const urlParam3=`categoryName=${selectedCategoryName}`;
        const searchUrl=`${url.searchTour}?${urlParam}&${urlParam2}&${urlParam3}`;
        navigate(searchUrl);
    };
    const handleSelectCategory=(value)=>{
        setSelectedCategory(value.id);
        setSelectedCategoryName(value.category_name);
        setShowCategoryDrpdown(false);
    };
    const handleSearchSector=async(value,type)=>{
        if(type === 1){
            setSelectedFromSectorID(0);
            setSelectedFromSectorName(value);
            setSearchKeyFromSector(value);
        }
        else{
            setSelectedToSectorID(0);
            setSelectedToSectorName(value);
            setSearchKeyToSector(value);
        }
    };
    const getSectorList=async(value,type)=>{
            const{status,responseValue}= await apiService.get(`SearchEngine/SearchSector?KeyWord=${value}`);
            if(status === 1){
                if(type === 1){
                    setFromSectorList(responseValue);
                    setShowFromSectorDrpdown(true);
                }
                else{
                    setToSectorList(responseValue);
                    setShowToSectorDrpdown(true);
                }
            }
            else{
                if(type === 1){
                    if(value.length === 0){
                        setShowFromSectorDrpdown(false);
                    }
                    setFromSectorList([]);
                }
                else{
                    if(value.length === 0){
                        setShowToSectorDrpdown(false);
                    }
                    setToSectorList([]);
                }

            }
    }
    const handleSelectSector=(type,data)=>{
        if(type === 1){
            setSelectedFromSectorName(`${data.sectorCode} - ${data.sectorName}`);
            setSelectedFromSectorID(data.id);
            setShowFromSectorDrpdown(false);
        }
        else if(type === 2){
            setSelectedToSectorName(`${data.sectorCode} - ${data.sectorName}`);
            setSelectedToSectorID(data.id);
            setShowToSectorDrpdown(false);
        }
    };
    const handlSetDate=()=>{
         const getCurrentDate=Common.getCurrentDate();
        setFromDate(getCurrentDate);
    };
    //-----------------------------------------------------//
    const handleCheckRequestUrl= async()=>{
        const urlString = window.location;
        const url = new URL(urlString);
        const param= new URLSearchParams(url.search);
        if(param.size){
            const key = param.get("data");
            Common.removeFlightSearchData();
            if(key){
                try{
                    const decodedString=atob(key);
                    try{
                        const searchRequest=JSON.parse(decodedString);
                        setSelectedFromSectorID(searchRequest.fromSectorID);
                        setSelectedFromSectorName(searchRequest.fromSectorName);
                        setSelectedToSectorID(searchRequest.toSectorID);
                        setSelectedToSectorName(searchRequest.toSectorName);
                        setFromDate(searchRequest.fromDate);
                        setToDate(searchRequest.toDate);
                        // Used Search Flight on page load
                        setIsSearching(true);

                        const obj={
                            departureSectorID:searchRequest.fromSectorID,
                            departureSectorName:(searchRequest.fromSectorName.split('-')[0]).trim(),
                            arrivalSectorID:searchRequest.toSectorID,
                            arrivalSectorName:(searchRequest.toSectorName.split('-')[0]).trim(),
                            fromDate:searchRequest.fromDate,
                            toDate:searchRequest.toDate,
                            UserID:Common.getUserLoginDetails().userID ?? 0
                        };
                        try{
                        const {responseValue} = await apiService.get(`SearchEngine/RetrieveFlightsByRouteAndDate?DepartureSectorID=${obj.departureSectorID}&ArrivalSectorID=${obj.arrivalSectorID}&DepartureSectorName=${obj.departureSectorName}&ArrivalSectorName=${obj.arrivalSectorName}&FromDate=${obj.fromDate}&ToDate=${obj.toDate}&UserID=${obj.UserID}`);
                        setFlightTicketList(responseValue);
                        setTicketListForFilter(responseValue);
                        setStoreTicket(responseValue);
                        setIsSearching(false);
                        }
                        catch(error){
                            console.error('Error While Search Flight Err->',error);
                            setIsSearching(false);
                        }

                    }
                    catch(error){
                        console.error('Error While Parsing URL Req Err->',error);
                        handlSetDate();
                        setIsSearching(false);
                    }
                }
                catch(error){
                    console.error('Error While Decoding Url Req Err->',error);
                    handlSetDate();
                    setIsSearching(false);
                }
            }
            else{
                handlSetDate();
                setIsSearching(false);
            }
        }
        else{
            const checkFlightData=sessionStorage.getItem("flightSearchData");
            if(checkFlightData){
                const getFlightData= JSON.parse(decodeURIComponent(checkFlightData));
                setSelectedFromSectorName(getFlightData.fromSectorName);
                setSelectedFromSectorID(getFlightData.fromSectorID);
                setSelectedToSectorName(getFlightData.toSectorName);
                setSelectedToSectorID(getFlightData.toSectorID);
                setSearchKeyFromSector(getFlightData.searchKeyFromSector);
                setSearchKeyToSector(getFlightData.searchKeyToSector);
                setFromDate(getFlightData.fromDate);
                setToDate(getFlightData.toDate);
                setFlightTicketList(getFlightData.ticketList);
                setTicketListForFilter(getFlightData.ticketList);
                setStoreTicket(getFlightData.ticketList);
                setIsFromSectorApiCall(0);
                setIsToSectorApiCall(0);
            }
            else{
                handlSetDate();
                setIsSearching(false);
                showTicktList();
            }

        }

    };
    const clearTimeSlotFilter=()=>{
         // const tempArr=[...timeSlots];
          const tempArr=timeSlots.map((item)=>({...item,isSelected:false}));
          setTimeSlots(tempArr);
    }
    const handleSearchFlight=async(e)=>{
        e.preventDefault();
        setIsSearching(true);
        clearAllModalFilter();
        Common.removeFlightSearchData();
        clearTimeSlotFilter();
        const obj={
            departureSectorID:selectedFromSectorID,
            departureSectorName:(selectedFromSectorName.split('-')[0]).trim(),
            arrivalSectorID:selectedToSectoID,
            arrivalSectorName:(selectedToSectorName.split('-')[0]).trim(),
            fromDate:fromDate,
            toDate:toDate,
            UserID:Common.getUserLoginDetails().userID ?? 0
        };
        try{
           const {responseValue} = await apiService.get(`SearchEngine/RetrieveFlightsByRouteAndDate?DepartureSectorID=${obj.departureSectorID}&ArrivalSectorID=${obj.arrivalSectorID}&DepartureSectorName=${obj.departureSectorName}&ArrivalSectorName=${obj.arrivalSectorName}&FromDate=${obj.fromDate}&ToDate=${obj.toDate}&UserID=${obj.UserID}`);
           console.log('responseValue ',responseValue)
           setFlightTicketList(responseValue);
           setTicketListForFilter(responseValue);
           setStoreTicket(responseValue);
           setIsSearching(false);
        }
        catch(error){
            console.error('Error While Search Flight Err->',error);
            setIsSearching(false);
        }

    };
    const handleBookFlight=(data)=>{
        const isLogin = Common.isUserAuthenticated();
        if(isLogin){
            const flightData={
                fromSectorName:selectedFromSectorName,
                fromSectorID:selectedFromSectorID,
                toSectorName:selectedToSectorName,
                toSectorID:selectedToSectoID,
                searchKeyFromSector:searchKeyFromSector,
                searchKeyToSector:searchKeyToSector,
                fromDate:fromDate,
                toDate:toDate,
                ticketList:flightTicketList
            };
            sessionStorage.setItem('flightSearchData',encodeURIComponent(JSON.stringify(flightData)));
            const getEncodedData=Common.encodedData(JSON.stringify(data));
            navigate(`${url.flightbooking}?data=${getEncodedData}`);
        }
        else{
            const flightData={
                fromSectorName:selectedFromSectorName,
                fromSectorID:selectedFromSectorID,
                toSectorName:selectedToSectorName,
                toSectorID:selectedToSectoID,
                searchKeyFromSector:searchKeyFromSector,
                searchKeyToSector:searchKeyToSector,
                fromDate:fromDate,
                toDate:toDate,
                ticketList:flightTicketList
            };
            sessionStorage.setItem('flightSearchData',encodeURIComponent(JSON.stringify(flightData)));
            setShow(true);
        }

    };
    const redirectToLogin=()=>{
        sessionStorage.setItem("loginRequestFromPage",JSON.stringify({loginRequestSenderPath:url.searchFlight}));
        navigate(url.login)
    };
    const showTicktList=async()=>{
        Common.removeFlightSearchData();
        setIsSearching(true);
        const obj={
            departureSectorID:selectedFromSectorID,
            departureSectorName:(selectedFromSectorName.split('-')[0]).trim(),
            arrivalSectorID:selectedToSectoID,
            arrivalSectorName:(selectedToSectorName.split('-')[0]).trim(),
            fromDate:fromDate,
            toDate:toDate,
            UserID:Common.getUserLoginDetails().userID ?? 0
        };
        try{
        const {responseValue} = await apiService.get(`SearchEngine/RetrieveFlightsByRouteAndDate?DepartureSectorID=${obj.departureSectorID}&ArrivalSectorID=${obj.arrivalSectorID}&DepartureSectorName=${obj.departureSectorName}&ArrivalSectorName=${obj.arrivalSectorName}&FromDate=${obj.fromDate}&ToDate=${obj.toDate}&UserID=${obj.UserID}`);
        setFlightTicketList(responseValue);
        setTicketListForFilter(responseValue);
        setStoreTicket(responseValue);
        setIsSearching(false);
        }
        catch(error){
            console.error('Error While Search Flight Err->',error);
            setIsSearching(false);
        }
    };
    const handleCheckUncheckAirline=(param,index)=>{
        try{
            let tempArr=[...selectedAirline];
            const isChecked = document.getElementById(`airlineCheck${param}`).checked;
            if(isChecked){
                if(!tempArr.includes(param)){
                    tempArr.push(param);
                }
            }
            else{
                tempArr = tempArr.filter(airline=>airline !== param);
            }
            setSelectedAirline(tempArr);
        }
        catch(error){
            console.error('Error While Select Airline for filter Err:',error);
        }

    };
    const handleApplyFilter=()=>{
        //For price sorting {1 for low to high} {2 for high to low}
        try{
            if(selectedAirline.length > 0){
                const arr =[...ticketListForFilter];
                let filteredData = arr.filter(ticket=> selectedAirline.includes(ticket.airlineID));
                const sortingValue = document.getElementById("ddlSorting").value;
                if(parseInt(sortingValue) === 1 || parseInt(sortingValue) === 2){
                    if(parseInt(sortingValue) === 1){
                        filteredData=filteredData.sort((a,b)=>a.normalTicketFare - b.normalTicketFare);
                    }
                    else if(parseInt(sortingValue) === 2){
                        filteredData=filteredData.sort((a,b)=>b.normalTicketFare - a.normalTicketFare);
                    }
                }
                setFlightTicketList(filteredData);
                setStoreTicket(filteredData);
            }
            else{
                setFlightTicketList(ticketListForFilter);
                setStoreTicket(ticketListForFilter);
            }
        }
        catch(error){
            console.error('Errow While Applying Filter Err:',error);
        }


    };
    const handleExchangeSector=()=>{
        setSelectedFromSectorName(selectedToSectorName);
        setSelectedFromSectorID(selectedToSectoID);
        setSelectedToSectorName(selectedFromSectorName);
        setSelectedToSectorID(selectedFromSectorID);
    };
    const handleOpenFilterModal=(filterType)=>{
        const getFilterDetails=filterDetails[filterType];
        setmodalFilter(getFilterDetails);
        setShowModalFilter(true)


    };
    const handleCloseFilterModal=()=>{
        setShowModalFilter(false)
    };
    const handleSelectAirline=(key,index)=>{
        let tempArr=[...modalFilterSelectedAirline];
        const isExist=tempArr.filter((item)=>item.selectedAirline === key && item.isSelected === true);
        if(isExist.length > 0){
            tempArr = tempArr.filter((item)=>item.selectedAirline !== key && item.isSelected === true)
        }
        else{
            tempArr.push({
                isSelected:true,
                selectedAirlineIndex:index,
                selectedAirline:key
            })
        }
        setModalFilterSelectedAirline(tempArr);

    };
    const handleModalFilterSorting=(sortingType,priceSortingType)=>{ //For price sorting {1 for low to high} {2 for high to low}
        setModalFilterSorting({
            isSorting:true,
            type:sortingType,
            isPriceSorting:sortingType === 'priceSorting' ? true : false,
            priceSortingType:sortingType === 'priceSorting' ? Number(priceSortingType) : null

        })

    };
    const handleApplyModalFilter=(filterTypeID)=>{
        try{
            const arr =[...ticketListForFilter];
            let filteredData = arr;
            if (filterTypeID === 1) {
                if(modalFilterSelectedAirline.length > 0){
                    // Filter by selected airlines
                    const selectedAirlines = modalFilterSelectedAirline.map(airline => airline.selectedAirline);
                    filteredData = arr.filter(item => selectedAirlines.includes(item.airlineID));
                }
                // Sort filtered data
                if(modalFilterSorting.isSorting === true && modalFilterSorting.type === 'priceSorting'){
                    filteredData = Common.sortData(filteredData,'normalTicketFare',modalFilterSorting.priceSortingType);
                }
                 // Update the flight ticket list
                setFlightTicketList(filteredData);
                setStoreTicket(filteredData);

            }
            else if (filterTypeID === 2 && modalFilterSelectedAirline.length > 0) {
                // Filter by selected airlines
                const selectedAirlines = modalFilterSelectedAirline.map(airline => airline.selectedAirline);
                filteredData = arr.filter(item => selectedAirlines.includes(item.airlineID));
                // Sort filtered data
                if(modalFilterSorting.isSorting === true && modalFilterSorting.type === 'priceSorting'){
                    filteredData = Common.sortData(filteredData,'normalTicketFare',modalFilterSorting.priceSortingType);
                }
                 // Update the flight ticket list
                 setFlightTicketList(filteredData);
                 setStoreTicket(filteredData);
            } else if (filterTypeID === 3) {
                if(modalFilterSorting.type === 'priceSorting'){
                    // Sort data first
                    filteredData = Common.sortData(arr,'normalTicketFare',modalFilterSorting.priceSortingType);
                    // If airlines are selected, filter the sorted data
                    if (modalFilterSelectedAirline.length > 0) {
                        const selectedAirlines = modalFilterSelectedAirline.map(airline => airline.selectedAirline);
                        filteredData = filteredData.filter(item => selectedAirlines.includes(item.airlineID));
                    }
                    // Update the flight ticket list
                    setFlightTicketList(filteredData);
                    setStoreTicket(filteredData);

                }

            }
            else{
                setFlightTicketList(ticketListForFilter); // Update the flight ticket list
                setStoreTicket(ticketListForFilter);
            }

            handleCloseFilterModal();
        }
        catch(error){
            console.error('Errow While Applying Filter Err:',error);
        }
    };
    const handleClearSortingModalFilter=()=>{
        const ticketList=[...flightTicketList];
        const sortedList=ticketList.sort((a,b)=>a.normalTicketFare - b.normalTicketFare);
        setFlightTicketList(sortedList);
        setStoreTicket(sortedList);
        setModalFilterSorting({isSorting:false,type:null,isPriceSorting:null,priceSortingType:null});
        handleCloseFilterModal();
    };
    const handleClearAirlineModalFilter=()=>{
        if(modalFilterSelectedAirline.length === 0){
            return;
        }
        let tempArr=[...ticketListForFilter];
        // Check if sorting is enabled and perform sorting accordingly
        if(modalFilterSorting.isSorting === true && modalFilterSorting.type === 'priceSorting'){
            // tempArr= tempArr.sort((a, b) => sortOrder * (a.normalTicketFare - b.normalTicketFare));
            tempArr=Common.sortData(tempArr,'normalTicketFare',modalFilterSorting.priceSortingType);
        }
        setModalFilterSelectedAirline([]); // Reset filters
        setFlightTicketList(tempArr); //Update the ticket list
        setStoreTicket(tempArr);
        handleCloseFilterModal();// Close the filter modal

    };
    const clearAllModalFilter=()=>{
        setModalFilterSelectedAirline([]); // Reset Airline filters
        setFlightTicketList(ticketListForFilter); //Update the ticket list
        setStoreTicket(ticketListForFilter);
        setModalFilterSorting({isSorting:false,type:null,isPriceSorting:null,priceSortingType:null});
        handleCloseFilterModal();
    }
    const handleClearModalFilter=(filterTypeID)=>{
        switch(filterTypeID){
            case 2 :
                handleClearAirlineModalFilter();
                break;
            case 3 :
                handleClearSortingModalFilter();
                break;
            default :
               console.log('default')
        }
    };
    const handleSelectTimeSlot=(data,index)=>{
          const tempArr=[...timeSlots];
          const isSelected = tempArr.filter((item)=>(item.isSelected === true && item.id === data.id));
          isSelected.length > 0 ?   tempArr[index].isSelected=false : tempArr[index].isSelected=true;
          setTimeSlots(tempArr);
          
    };
    useEffect(()=>{
         const tempArr=[...timeSlots];
          const isTimeSlotSelected = tempArr.filter((item)=>(item.isSelected === true));
          isTimeSlotSelected.length > 0 ? setisActiveTimeSlotFilter(false) : setisActiveTimeSlotFilter(true); 
          handleTimeSlotFilter(isTimeSlotSelected);
        //   if(flightTicketList.length > 0){

        //   } 
            
            

    },[timeSlots]);

    const handleTimeSlotFilter=(selectedSlot)=>{
        const selectedTimeSlot = [...selectedSlot];     
        if (selectedTimeSlot.length === 0) {
            setisActiveTimeSlotFilter(false); // Disable the filter when no slot is selected
            setFlightTicketList(storeTicket); // Reset to all flights
        } else {
            const selectedTimeSlotIDs = selectedTimeSlot.map((item) => item.id); // Extract IDs from selected slots    
            const filteredFlights = storeTicket.filter((flight) => 
                selectedTimeSlotIDs.includes(flight.TimeSlotID)
            );
            setisActiveTimeSlotFilter(true); // Enable the filter
            setFlightTicketList(filteredFlights); // Update the filtered flights
        }
    }

    UseResetScroll();
    //Use Effect
    useEffect(()=>{
        getCategoryList();
        getAirlineList();
        clearAllModalFilter();
        handleCheckRequestUrl();
        sessionStorage.removeItem("loginRequestFromPage");
        document.title="Flight Results";
        setCurrentDate(Common.getCurrentDate())
    },[]);
    useEffect(()=>{
        if(isApiCall){
            let timeout= setTimeout(()=>{
                getLocationList();
            },500);
            return ()=>clearTimeout(timeout);
        }
        setIsApiCall(1);

    },[searchQuery]);
    useEffect(()=>{
        if(isFromSectorApiCall){
            let timeout= setTimeout(()=>{
                getSectorList(searchKeyFromSector,1);
            },500);
            return ()=>clearTimeout(timeout);
        }
        setIsFromSectorApiCall(1);
    },[searchKeyFromSector]);
    useEffect(()=>{
        if(isToSectorApiCall){
            let timeout= setTimeout(()=>{
                getSectorList(searchKeyToSector,2);
            },500);
            return ()=>clearTimeout(timeout);
        }
        setIsToSectorApiCall(1);
    },[searchKeyToSector]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (locationDropdownRef.current && !locationDropdownRef.current.contains(event.target)) {
                setShowLocationDrpdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [locationDropdownRef]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (categoryDropdownRef.current && !categoryDropdownRef.current.contains(event.target)) {
                setShowCategoryDrpdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [categoryDropdownRef]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (fromSectorDropdownRef.current && !fromSectorDropdownRef.current.contains(event.target)) {
                setShowFromSectorDrpdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [fromSectorDropdownRef]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (toSectorDropdownRef.current && !toSectorDropdownRef.current.contains(event.target)) {
                setShowToSectorDrpdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [toSectorDropdownRef]);
return(
    <>
    <Header customizedHeader={1} isDashboard={0} isFromSearchFlight={1} isHideTopBar={1}/>
    {/* Finder New */}
        <div className="container mt-2">
            <div className="row">
                <div className="col-lg-12">
                    <div className="theme_search_form_area" style={{padding:'30px 30px 10px 30px'}}>
                        <div className="theme_search_form_tabbtn" style={{marginBottom:'15px'}}>
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="flights-tab" data-bs-toggle="tab"
                                        data-bs-target="#flights" type="button" role="tab" aria-controls="flights"
                                        aria-selected="true"><i className="fas fa-plane-departure"></i>Flights</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="tours-tab" data-bs-toggle="tab" data-bs-target="#tours"
                                        type="button" role="tab" aria-controls="tours" aria-selected="false"><i
                                            className="fas fa-globe"></i>Tours</button>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="flights" role="tabpanel"
                                aria-labelledby="flights-tab">
                                <div className="tab-content" id="myTabContent1">
                                    <div className="tab-pane fade show active" id="oneway_flight" role="tabpanel"
                                        aria-labelledby="oneway-tab">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="oneway_search_form">
                                                    <form action="#!">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                                                <div className="flight_Search_boxed"  ref={fromSectorDropdownRef}>
                                                                    <p>From</p>
                                                                    <input type="text" value={selectedFromSectorName} onChange={(e)=>{handleSearchSector(e.target.value,1)}}/>
                                                                    {showFromSectorDrpdown ?
                                                                        <div className="dropdown">
                                                                            <div className="dropdown-contentNew">
                                                                                {fromSectorList && fromSectorList.map((list)=>{
                                                                                    return(
                                                                                        <>
                                                                                            <div className="row" onClick={()=>{handleSelectSector(1,list)}}>
                                                                                            <div className="cus-w-20 sector-code-btn-p"><button type="button" className="btn sector-code-btn">{list.sectorCode}</button></div>
                                                                                            <div className="cus-w-80">
                                                                                            <span className="sector-Name">{list.sectorName}</span>
                                                                                            <span className="sector-code-c">{list.sectorLocation}</span>
                                                                                            </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    :''}
                                                                    <div className="plan_icon_posation">
                                                                        <i className="fas fa-plane-departure"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                                                <div className="flight_Search_boxed" ref={toSectorDropdownRef}>
                                                                    <p>To</p>
                                                                    <input type="text" value={selectedToSectorName} onChange={(e)=>{handleSearchSector(e.target.value,2)}}/>
                                                                    {showToSectorDrpdown ?
                                                                        <div className="dropdown">
                                                                            <div className="dropdown-contentNew">
                                                                            {toSectorList && toSectorList.map((list)=>{
                                                                                    return(
                                                                                        <>
                                                                                            <div className="row" onClick={()=>{handleSelectSector(2,list)}}>
                                                                                            <div className="cus-w-20 sector-code-btn-p"><button type="button" className="btn sector-code-btn">{list.sectorCode}</button></div>
                                                                                            <div className="cus-w-80">
                                                                                            <span className="sector-Name">{list.sectorName}</span>
                                                                                            <span className="sector-code-c">{list.sectorLocation}</span>
                                                                                            </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    :''}
                                                                    <div className="plan_icon_posation">
                                                                        <i className="fas fa-plane-arrival"></i>
                                                                    </div>
                                                                    <div className="range_plan cursor-pointer" onClick={handleExchangeSector}>
                                                                        <i className="fas fa-exchange-alt"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                                                <div className="form_search_date">
                                                                    <div className="flight_Search_boxed date_flex_area">
                                                                        <div className="Journey_date w-100">
                                                                            <p>Travel Date</p>
                                                                            <input type="date" value={fromDate} min={currentDate} onChange={(e)=>{setFromDate(e.target.value)}}/>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                <div className="form_search_date">
                                                                    <div className="flight_Search_boxed date_flex_area">

                                                                        <div className="Journey_date w-100">
                                                                            <p>To date</p>
                                                                            <input type="date" value={toDate} onChange={(e)=>{setToDate(e.target.value)}}/>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            <div className="top_form_search_button">
                                                                <button className="btn btn_theme btn_md cus-search-button" disabled={isSearching} onClick={handleSearchFlight}>
                                                                 {isSearching ?
                                                                 <>
                                                                 <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                                 Searching...
                                                                 </>
                                                                 :<>Search</>
                                                                 }
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tours" role="tabpanel" aria-labelledby="tours-tab">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="tour_search_form">
                                            <form>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                        <div className="flight_Search_boxed" ref={locationDropdownRef}>
                                                            <p>Destination</p>
                                                            <input type="text" id="searchLocation" placeholder="Where are you going?" onChange={handleSearch}/>
                                                            {showLocationDrpdown ?
                                                                <div className="dropdown">
                                                                    <div className="dropdown-content">
                                                                        {locationList && locationList.map((list)=>{
                                                                            return(
                                                                                <>
                                                                                <span onClick={()=>{handleSelectLocation(list.location)}}>{list.location}</span>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            :''}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                        <div className="form_search_date">
                                                            <div className="flight_Search_boxed date_flex_area finder-cate-dropdown">
                                                                <div className="Journey_date c-width-100 c-mx-auto"  ref={categoryDropdownRef}>
                                                                    <button className="form-control t-left finder-cate-btn finder-cate-heading" onClick={(e)=>{e.preventDefault();setShowCategoryDrpdown(!showCategoryDrpdown)}}>{selectedCategoryName || 'Choose Category'}</button>
                                                                    {showCategoryDrpdown ?
                                                                    <div className="dropdown">
                                                                        <div className="dropdown-content">
                                                                            {categoryList && categoryList.map((list)=>{
                                                                                return(
                                                                                    <>
                                                                                    <span onClick={()=>{handleSelectCategory(list)}}>{list.category_name}</span>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                    :''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="top_form_search_button">
                                                        <button className="btn btn_theme btn_md cus-search-button" onClick={handleSearchTour}>Search</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    {/* </section> */}
    {/* End Here */}
    <section id="explore_area" className="flight_list_padding">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 fiight-search-filter">
                    <div className="left_side_search_area">
                        <div className="row">
                            <div className="left_side_search_boxed mb-2">
                                <div className="left_side_search_heading">
                                    <h5>Sort</h5>
                                </div>
                                <div className="filter-price">
                                <select className="form-control" id="ddlSorting">
                                        <option value="0">Choose</option>
                                        <option value="1">Low to High</option>
                                        <option value="2">High to Low</option>
                                </select>
                                </div>
                                <button className="apply" type="button" onClick={handleApplyFilter}>Apply</button>
                            </div>
                            <div className="left_side_search_boxed mb-2">
                                <div className="left_side_search_heading">
                                    <h5>Fare Type</h5>
                                </div>
                                <div className="tour_search_type">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultt1" />
                                        <label className="form-check-label" htmlFor="flexCheckDefaultt1">
                                            <span className="area_flex_one">
                                                <span>Regular Fare</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultt2" />
                                        <label className="form-check-label" htmlFor="flexCheckDefaultt2">
                                            <span className="area_flex_one">
                                                <span>Group Fare</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="left_side_search_boxed mb-2">
                                <div className="left_side_search_heading">
                                    <h5>Departure from {flightTicketList.length > 0 ? flightTicketList[0].departureSectorName : ''}</h5>
                                </div>
                                <div className="tour_search_type">
                                    <div class="departureTimeContainer">
                                        {timeSlots.map((slot, index) => (
                                            <div className={`item ${slot.isSelected ? 'selecteditem' : ''} ${index === 3 ? 'timeSlotNight':''}`} key={index} onClick={()=>{handleSelectTimeSlot(slot,index)}}>
                                                {slot.icon}
                                                <p className="dtitle">{slot.title}</p>
                                                <p className="dsubtitle">{slot.subtitle}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="left_side_search_boxed mb-2">
                                <div className="left_side_search_heading">
                                    <h5>Airline</h5>
                                </div>
                                <div className="tour_search_type">
                                    {airlineList && airlineList.map((item,index)=>{
                                        return(
                                            <div className="form-check" key={index+1}>
                                                <input className="form-check-input" type="checkbox" id={`airlineCheck${item.id}`} onClick={()=>{handleCheckUncheckAirline(item.id,index)}}/>
                                                <label className="form-check-label" htmlFor={`airlineCheck${item.id}`}>
                                                    <span className="area_flex_one">
                                                        <span>{item.airline_name}</span>
                                                    </span>
                                                </label>
                                            </div>
                                        )
                                    })}
                                </div>
                                <button className="apply" type="button" onClick={handleApplyFilter}>Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* When no ticket found */}
                {flightTicketList.length === 0 ?
                    <div className="col-lg-6 text-center no-flights-message">
                        <img src={travel} style={{height:'150px'}} alt=""/>
                        <h5 className="mb-3">Sorry, no flights available for the selected route.</h5>
                    </div>
                :

                <div className="col-lg-9 col-md-12">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="flight_search_result_wrapper flight-details-block">
                                    {flightTicketList && flightTicketList.map((value,index)=>{
                                        const connectionDetails=JSON.parse(value.connectionList);
                                        console.log('value',value,value.isConnectedFlight,value.handBaggageWeight)
                                        return(
                                            <div className="flight_search_item_wrappper">
                                                <div className="flight_search_items">
                                                    <div className="multi_city_flight_lists">
                                                        <div className="flight_multis_area_wrapper">
                                                        <div className="chepest-in">
                                                        <div className="chepest">

                                                            <div className="cus-flight_logo">
                                                                    <img src={value.airlineLogo} alt="img" />
                                                                    {/* <div className="custom-badge-icon chepest_web"><span><IoTime /><p>Cheapest</p></span></div> */}
                                                                </div>
                                                                <div className="flight_search_destination">
                                                                    <h4 className="ftWeight600">{value.departureTime}</h4>
                                                                    <h5 className="ftWeight600 mgTop4px">{value.departureSectorCode}</h5>
                                                                    <h6>{value.departureSectorLocation}</h6>
                                                                </div>

                                                                 <div className="flight_right_arrow">
                                                                    <i className="bi bi-arrow-right-circle-fill arrow-icon"></i>
                                                                    <h6>{value.flightStoppage}</h6>
                                                                    <p>{value.timeDifference}</p>
                                                                </div>
                                                                <div className="flight_search_destination">

                                                                    <h4 className="ftWeight600">{value.arrivalTime}</h4>
                                                                    <h5 className="ftWeight600 mgTop4px">{value.arrivalSectorCode}</h5>
                                                                    <h6>{value.arrivalSectorLocation}</h6>
                                                                </div>
                                                            </div>
                                                              {/* <div className="custom-badge-icon chepest_mbl"><span><IoTime /><p>Cheapest</p></span></div> */}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="flight_search_right">
                                                        <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="25"
                                                                height="25"
                                                                fill="currentColor"
                                                                className="bi bi-currency-rupee"
                                                                viewBox="0 0 16 16"
                                                                style={{ verticalAlign: 'middle' }} // Align the icon vertically
                                                            >
                                                                <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                                                            </svg>
                                                            <span className="ticket-price-lable">{value.normalTicketFare}</span>
                                                        </div>
                                                        {value.isSold ? <span className="isSoldBtn" disabled>Sold Out</span>

                                                         :<span className="btn_theme_custom" onClick={()=>{handleBookFlight(value)}}>Book now</span>
                                                        }



                                                        <h6 className="Flightdetails_none" data-bs-toggle="collapse" data-bs-target={`#collapseFlightDetails${index}`}
                                                            ariaExpanded="false" aria-controls="collapseExample">Flight Details <i
                                                                className="fas fa-chevron-down color-common"></i></h6>
                                                    </div>
                                                </div>
                                                <div className="flight_policy_refund collapse mt-2" id={`collapseFlightDetails${index}`}>

                                                    {value.isConnectedFlight === 1 ?
                                                    <>
                                                        {connectionDetails && connectionDetails.length > 0 && connectionDetails.map((conn,ind,allConn)=>{
                                                            return(
                                                                    <>
                                                                        {ind === 0 ?
                                                                            <div className="flight_show_down_wrapper">
                                                                                <div className="flight-shoe_dow_item">
                                                                                    <div className="airline-details">
                                                                                        <div className="img"><img src={value.airlineLogo} alt="img" /></div>
                                                                                        <span className="airlineName fw-500">{value.airline_name}</span>
                                                                                        <span className="flightNumber"> {value.airline_no}</span>
                                                                                    </div>
                                                                                    <div className="flight_inner_show_component">
                                                                                        <div className="flight_det_wrapper">
                                                                                            <div className="flight_det">
                                                                                                <div className="code_time">
                                                                                                    <span className="code"> {value.formattedDepartureDate}</span><br />
                                                                                                    <span className="time font-1rem"> {value.departureTime}</span> <br />
                                                                                                    <span className="time font-sec"> {value.departureSectorCode} - {value.departureSectorName}</span>
                                                                                                </div>
                                                                                                <p className="airport mb-1">
                                                                                                    {value.departureSectorLocation}
                                                                                                    <br />
                                                                                                    <span className="font-sec">{value.departureTerminal} </span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="flight_duration">
                                                                                            <div className="arrow_right"></div>
                                                                                            <span> {conn.timeDifference ?? "7h 0m"}</span>
                                                                                        </div>
                                                                                        <div className="flight_det_wrapper">
                                                                                            <div className="flight_det">

                                                                                                <div className="code_time">
                                                                                                    <span className="code"> {conn.formattedArrivalDate}</span><br />
                                                                                                    <span className="time font-1rem"> {conn.arrivalTime}</span> <br />
                                                                                                    <span className="time font-sec"> {conn.arrivalSectorCode} - {conn.arrivalSectorName}</span>
                                                                                                </div>
                                                                                                <p className="airport mb-1">
                                                                                                    {conn.arrivalSectorLocation}
                                                                                                    <br />
                                                                                                    <span className="font-sec">{conn.arrivalTerminal} </span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flight_refund_policy">
                                                                                    <div className="TabPanelInner flex_widht_less">
                                                                                        <h4>Refund Policy</h4>
                                                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                                                            following policies.</p>
                                                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                                                            policy + DocoTrip Convenience Fee). </p>
                                                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                                                    </div>
                                                                                    <div className="TabPanelInner">
                                                                                        <h4>Baggage</h4>
                                                                                        <div className="flight_info_taable mt-1">
                                                                                            {/* <p><span>{(conn.handbaggageWeight+conn.checkInBaggageWeight)}KG /</span> person</p> */}
                                                                                            <p className='cursor-pointe' title={`Total Check-in ${conn.handbaggageWeight} KG`}><PiHandbagFill className="cabin-bag-icon"/><span>Check-in : </span>{conn.handbaggageWeight}KG </p>
                                                                                            <p className='cursor-pointe' title={`Total Check-in ${conn.checkInBaggageWeight} KG`}><MdLuggage className="cabin-bag-icon"/><span>Cabin : </span>{conn.checkInBaggageWeight}KG</p>
                                                                                        
                                                                                        </div>                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        :
                                                                            <div className="flight_show_down_wrapper">
                                                                                <div className="flight-shoe_dow_item">
                                                                                    <div className="airline-details">
                                                                                        <div className="img"><img src={value.airlineLogo} alt="img" /></div>
                                                                                        <span className="airlineName fw-500">{value.airline_name}</span>
                                                                                        <span className="flightNumber"> {value.airline_no}</span>
                                                                                    </div>
                                                                                    <div className="flight_inner_show_component">
                                                                                        <div className="flight_det_wrapper">
                                                                                            <div className="flight_det">
                                                                                                <div className="code_time">
                                                                                                    <span className="code"> {allConn[(ind-1)].formattedArrivalDate}</span><br />
                                                                                                    <span className="time font-1rem"> {allConn[(ind-1)].arrivalTime}</span> <br />
                                                                                                    <span className="time font-sec"> {allConn[(ind-1)].arrivalSectorCode} - {allConn[(ind-1)].arrivalSectorName}</span>
                                                                                                </div>
                                                                                                <p className="airport mb-1">
                                                                                                    {allConn[(ind-1)].arrivalSectorLocation}
                                                                                                    <br />
                                                                                                    <span className="font-sec">{allConn[(ind-1)].arrivalTerminal} </span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="flight_duration">
                                                                                            <div className="arrow_right"></div>
                                                                                            <span> {allConn[(ind-1)].timeDifference}</span>
                                                                                        </div>
                                                                                        <div className="flight_det_wrapper">
                                                                                            <div className="flight_det">
                                                                                                <div className="code_time">
                                                                                                    <span className="code"> {conn.formattedArrivalDate}</span><br />
                                                                                                    <span className="time font-1rem"> {conn.arrivalTime}</span> <br />
                                                                                                    <span className="time font-sec"> {conn.arrivalSectorCode} - {conn.arrivalSectorName}</span>
                                                                                                </div>
                                                                                                <p className="airport mb-1">
                                                                                                    {value.arrivalSectorLocation}
                                                                                                    <br />
                                                                                                    <span className="font-sec">{conn.arrivalTerminal} </span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flight_refund_policy">
                                                                                    <div className="TabPanelInner flex_widht_less">
                                                                                        <h4>Refund Policy</h4>
                                                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                                                            following policies.</p>
                                                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                                                            policy + DocoTrip Convenience Fee). </p>
                                                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                                                    </div>
                                                                                    <div className="TabPanelInner">
                                                                                        <h4>Baggage</h4>
                                                                                        <div className="flight_info_taable mt-1">
                                                                                            <p className='cursor-pointe' title={`Total Check-in ${conn.handbaggageWeight} KG`}><PiHandbagFill className="cabin-bag-icon"/><span>Check-in : </span>{conn.handbaggageWeight}KG </p>
                                                                                            <p className='cursor-pointe' title={`Total Check-in ${conn.checkInBaggageWeight} KG`}><MdLuggage className="cabin-bag-icon"/><span>Cabin : </span>{conn.checkInBaggageWeight}KG</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {ind === (connectionDetails.length-1) ?
                                                                            <div className="flight_show_down_wrapper">
                                                                                <div className="flight-shoe_dow_item">
                                                                                    <div className="airline-details">
                                                                                        <div className="img"><img src={value.airlineLogo} alt="img" /></div>
                                                                                        <span className="airlineName fw-500">{value.airline_name}</span>
                                                                                        <span className="flightNumber"> {value.airline_no}</span>
                                                                                    </div>
                                                                                    <div className="flight_inner_show_component">
                                                                                        <div className="flight_det_wrapper">
                                                                                            <div className="flight_det">

                                                                                                <div className="code_time">
                                                                                                    <span className="code"> {conn.formattedArrivalDate}</span><br />
                                                                                                    <span className="time font-1rem"> {conn.arrivalTime}</span> <br />
                                                                                                    <span className="time font-sec"> {conn.arrivalSectorCode} - {conn.arrivalSectorName}</span>
                                                                                                </div>
                                                                                                <p className="airport mb-1">
                                                                                                    {conn.arrivalSectorLocation}
                                                                                                    <br />
                                                                                                    <span className="font-sec">{conn.arrivalTerminal} </span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="flight_duration">
                                                                                            <div className="arrow_right"></div>
                                                                                            <span> {conn.timeDifference ?? "3h 9m"}</span>
                                                                                        </div>
                                                                                        <div className="flight_det_wrapper">
                                                                                            <div className="flight_det">

                                                                                                <div className="code_time">
                                                                                                    <span className="code"> {value.formattedArrivalDate}</span><br />
                                                                                                    <span className="time font-1rem"> {value.arrivalTime}</span> <br />
                                                                                                    <span className="time font-sec"> {value.arrivalSectorCode} - {value.arrivalSectorName}</span>
                                                                                                </div>
                                                                                                <p className="airport mb-1">
                                                                                                    {value.arrivalSectorLocation}
                                                                                                    <br />
                                                                                                    <span className="font-sec">{value.arrivalTerminal} </span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flight_refund_policy">
                                                                                    <div className="TabPanelInner flex_widht_less">
                                                                                        <h4>Refund Policy</h4>
                                                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                                                            following policies.</p>
                                                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                                                            policy + DocoTrip Convenience Fee). </p>
                                                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                                                    </div>
                                                                                    <div className="TabPanelInner">
                                                                                        <h4>Baggage</h4>
                                                                                        <div className="flight_info_taable mt-1">
                                                                                            <p className='cursor-pointe' title={`Total Check-in ${conn.handbaggageWeight} KG`}><PiHandbagFill className="cabin-bag-icon"/><span>Check-in : </span>{conn.handbaggageWeight}KG </p>
                                                                                            <p className='cursor-pointe' title={`Total Check-in ${conn.checkInBaggageWeight} KG`}><MdLuggage className="cabin-bag-icon"/><span>Cabin : </span>{conn.checkInBaggageWeight}KG</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        :""}
                                                                    </>
                                                            );
                                                        })}
                                                    </>

                                                    :
                                                        
                                                            <div className="flight_show_down_wrapper">
                                                                <div className="flight-shoe_dow_item">
                                                                    <div className="airline-details">
                                                                        <div className="img"><img src={value.airlineLogo} alt="img" /></div>
                                                                        <span className="airlineName fw-500">{value.airline_name}</span>
                                                                        <span className="flightNumber"> {value.airline_no}</span>
                                                                    </div>
                                                                    <div className="flight_inner_show_component">
                                                                        <div className="flight_det_wrapper">
                                                                            <div className="flight_det">
                                                                                <div className="code_time">
                                                                                    <span className="code"> {value.departureSectorCode}</span>
                                                                                    <span className="time"> {value.departureTime}</span>
                                                                                </div>
                                                                                <p className="airport">{value.departureTerminal} {value.departureSectorLocation}</p>
                                                                                <p className="date"> {value.formattedDepartureDate}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flight_duration">
                                                                            <div className="arrow_right"></div>
                                                                            <span> {value.timeDifference}</span>
                                                                        </div>
                                                                        <div className="flight_det_wrapper">
                                                                            <div className="flight_det">
                                                                                <div className="code_time">
                                                                                    <span className="code"> {value.arrivalSectorCode}</span>
                                                                                    <span className="time"> {value.arrivalTime}</span>
                                                                                </div>
                                                                                <p className="airport">{value.arrivalTerminal} {value.arrivalSectorLocation}</p>
                                                                                <p className="date"> {value.formattedArrivalDate}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flight_refund_policy">
                                                                    <div className="TabPanelInner flex_widht_less">
                                                                        <h4>Refund Policy</h4>
                                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                                            following policies.</p>
                                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                                            policy + DocoTrip Convenience Fee). </p>
                                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                                    </div>
                                                                    <div className="TabPanelInner">
                                                                        <h4>Baggage</h4>
                                                                        <div className="flight_info_taable mt-1">
                                                                            <p className='cursor-pointe' title={`Total Check-in ${value.handBaggageWeight} KG`}><PiHandbagFill className="cabin-bag-icon"/><span>Check-in : </span>{value.handBaggageWeight}KG </p>
                                                                            <p className='cursor-pointe' title={`Total Check-in ${value.checkInBaggageWeight} KG`}><MdLuggage className="cabin-bag-icon"/><span>Cabin : </span>{value.checkInBaggageWeight}KG</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}


                                </div>

                            </div>
                        </div>
                    </div>
                }

            </div>

        </div>
        <div className="flight-filter-bottom fixed-bottom bg-white">
                <div className="row pb-3 d-flex justify-content-center">
                    <div className="col-3 mt-2 text-center" onClick={()=>{handleOpenFilterModal('filter')}}>
                        <i className="bi bi-funnel-fill filter-icon f-30"></i>
                        <span className="flight-fliter-bottom-title">FILTER</span>
                    </div>
                    <div className="col-3 mt-2 text-center" onClick={()=>{handleOpenFilterModal('airline')}}>
                        <svg width="1em" height="1em" font-size="2rem" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-testid="AirlinesFilledIcon" ><path fill-rule="evenodd" d="m3.007 5.0762 2.7498 14.066c.0608.4886.5144.8578 1.054.8578h12.209c.5523 0 .9966-.4554.8258-.9806-.1382-.4248-.3351-.8245-.502-1.1574-.2383-.4753-.606-.9557-1.066-1.2224-2.7776-1.6102-4.5703-2.5788-6.4255-5.0896L7.1924 4.8575c-.1542-.2088-.3918-.3545-.6621-.4062L4.2786 4.021c-.7077-.1352-1.3531.4004-1.2717 1.0552Z" clip-rule="evenodd"></path></svg>
                        <span className="filter-icon flight-fliter-bottom-title">AIRLINE</span>
                    </div>
                    <div className="col-3 mt-2 text-center" onClick={()=>{handleOpenFilterModal('sorting')}}>
                        <i className="bi bi-filter-left filter-icon f-30 "></i>
                        <span className="flight-fliter-bottom-title">SORT</span>
                    </div>
                </div>
        </div>
        {/* Filter Modal */}
        <div class="custom-modal">
            <div id="modalFilter" className={`modal ${showModalFilter ? 'show open' :'close'}`}>
                <div className="modal-content">
                <div className="cusmodal-header">
                        <span className="close" onClick={handleCloseFilterModal}>&times;</span>
                        <h2 className="cusmodal-title">{modalFilter.title ?? ''}</h2>
                    </div>
                    <div className="modal-body">
                        {(showModalFilter && modalFilter.filterTypeID === 1) ?
                            <>
                            <h5>Sort By</h5>
                            <div className="radio-container mb-5">
                                    <label className="radio-label">
                                        <input
                                        type="radio"
                                        className="form-check-input"
                                        name="sortOptions"
                                        id="priceLowToHighF"
                                        checked={modalFilterSorting.isPriceSorting === true && modalFilterSorting.priceSortingType === 1 ? true : false}
                                        onChange={()=>{handleModalFilterSorting('priceSorting',1)}}/>
                                        <div className="text-group">
                                        <span className="form-check-label">Price</span>
                                        <span className="radio-description">Low to High</span>
                                        </div>
                                    </label>
                                    <label className="radio-label radio-label-right">
                                        <input
                                        type="radio"
                                        className="form-check-input"
                                        name="sortOptions"
                                        id="priceHighToLowF"
                                        checked={modalFilterSorting.isPriceSorting === true && modalFilterSorting.priceSortingType === 2 ? true : false}
                                        onChange={()=>{handleModalFilterSorting('priceSorting',2)}} />
                                        <div className="text-group">
                                        <span className="form-check-label">Price</span>
                                        <span className="radio-description">High to Low</span>
                                        </div>
                                    </label>
                            </div>
                            <h5>Popular Airlines</h5>
                            <div className="tour_search_type">
                                {ticketListForFilter.length > 0 && airlineList && airlineList.map((item,index)=>{
                                    return(

                                        <div className="flight-item" key={index+1}>
                                            <img src={item.airline_logo} alt="Air India" className="flight-logo" />
                                            <div className="flight-info">
                                                <p className="flight-name">{item.airline_name}</p>
                                                <p className="flight-number">{item.airline_no}</p>
                                            </div>
                                            <input className="form-check-input flight-checkbox" type="checkbox" id={`airlineCheckModalFilter${item.id}`} checked={modalFilterSelectedAirline.some(val=>val.selectedAirline === item.id && val.isSelected === true)}  onChange={()=>{handleSelectAirline(item.id,index)}} />
                                        </div>
                                    )
                                })}
                            </div>
                            </>
                        :(showModalFilter && modalFilter.filterTypeID === 2) ?
                        <div className="tour_search_type">
                            {ticketListForFilter.length > 0 && airlineList && airlineList.map((item,index)=>{
                                return(

                                    <div className="flight-item" key={index+1}>
                                        <img src={item.airline_logo} alt="Air India" className="flight-logo" />
                                        <div className="flight-info">
                                            <p className="flight-name">{item.airline_name}</p>
                                            <p className="flight-number">{item.airline_no}</p>
                                        </div>
                                        <input className="form-check-input flight-checkbox" type="checkbox" id={`airlineCheckModalFilter${item.id}`} checked={modalFilterSelectedAirline.some(val=>val.selectedAirline === item.id && val.isSelected === true)}  onChange={()=>{handleSelectAirline(item.id,index)}} />
                                    </div>
                                )
                            })}
                        </div>
                        : (showModalFilter && modalFilter.filterTypeID === 3) ?
                                <>
                                    <div className="radio-container">
                                        <label className="radio-label">
                                            <input
                                            type="radio"
                                            className="form-check-input"
                                            name="sortOptions"
                                            id="priceLowToHigh"
                                            checked={modalFilterSorting.isPriceSorting === true && modalFilterSorting.priceSortingType === 1 ? true : false}
                                            onChange={()=>{handleModalFilterSorting('priceSorting',1)}}/>
                                            <div className="text-group">
                                            <span className="form-check-label">Price</span>
                                            <span className="radio-description">Low to High</span>
                                            </div>
                                        </label>
                                        <label className="radio-label radio-label-right">
                                            <input
                                            type="radio"
                                            className="form-check-input"
                                            name="sortOptions"
                                            id="priceHighToLow"
                                            checked={modalFilterSorting.isPriceSorting === true && modalFilterSorting.priceSortingType === 2 ? true : false}
                                            onChange={()=>{handleModalFilterSorting('priceSorting',2)}} />
                                            <div className="text-group">
                                            <span className="form-check-label">Price</span>
                                            <span className="radio-description">High to Low</span>
                                            </div>
                                        </label>
                                    </div>
                                </>
                        :''
                        }

                    </div>
                    <div className="modal-footer justify-content-between align-items-center">

                        {(showModalFilter && modalFilter.filterTypeID === 1) ?
                            <span className="btn-clear" onClick={clearAllModalFilter}>Clear</span>
                        :(showModalFilter && modalFilter.filterTypeID === 2) ?
                            <span className="btn-clear" onClick={()=>{handleClearModalFilter(2)}}>Clear</span>
                        :(showModalFilter && modalFilter.filterTypeID === 3) ?
                            <span className="btn-clear" onClick={()=>{handleClearModalFilter(3)}}>Clear</span>
                        :''
                        }

                        {(showModalFilter && modalFilter.filterTypeID === 1) ?
                            <span className="btn_theme_custom btn-showFlight" onClick={()=>{handleApplyModalFilter(1)}}>Show Flights</span>
                        :(showModalFilter && modalFilter.filterTypeID === 2) ?
                            <span className="btn_theme_custom btn-showFlight" onClick={()=>{handleApplyModalFilter(2)}}>Show Flights</span>
                        :(showModalFilter && modalFilter.filterTypeID === 3) ?
                        <span className="btn_theme_custom btn-showFlight" onClick={()=>{handleApplyModalFilter(3)}}>Show Flights</span>
                        :''
                        }

                    </div>
                </div>
            </div>
        </div>
        <Modal show={show} onHide={()=>{setShow(false)}} centered>
            <Modal.Header closeButton className="login-confirmation-header">
                <Modal.Title>Login Required</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                You must be logged in to access this feature. Please log in to continue.
            </Modal.Body>
            <Modal.Footer className="login-confirmation-footer">
                <Button className="btn-login" onClick={redirectToLogin}>
                    Go to Login
                </Button>
            </Modal.Footer>
        </Modal>
    </section>

    <ScrollToTop />
    </>
)
}
export default FlightTicket;