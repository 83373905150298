import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Common from "../../../Common/Common";
import apiService from "../../../Common/ApiFactory/apiService";
import Header from "../Component/Header";
import wallet from '../../../assets/Icons/wallet.png';
import ticketBooking from '../../../assets/Icons/ticket-booking-icon.png';
import packageBooking from '../../../assets/Icons/online-booking-icon.png';
import invoice from '../../../assets/Icons/report-icon.png';
import url from "../../../Common/url";
const Account = () => {
    const navigate=useNavigate();
    const[loginUserDetails, setLoginUserDetails]=useState(Common.getUserLoginDetails());
    const[dashboardData, setDashboardData]=useState({});
    const [cards, setCards] = useState([
        { heading: 'Wallet', subHeading: '', icon: wallet, value: '', url: url.wallet },
        { heading: 'Ticket Booking', subHeading: '', icon: ticketBooking, value: '', url: url.airticketbookings },
        { heading: 'Package Booking', subHeading: '', icon: packageBooking, value: '', url: url.packagebookings },
        { heading: 'Invoice', subHeading: '', icon: invoice, value: '', url: '' },
        { heading: 'Transaction', subHeading:'', icon: invoice, value: '', url: '' },
    ]);
    const RetrieveDashboardData=async()=>{
        const obj={
            userID:loginUserDetails.userID,
            session:loginUserDetails.sessionToken
        }
        try{
            const {status,responseValue} = await apiService.get(`User/RetrieveDashboardData?userId=${obj.userID}&SessionToken=${obj.session}`);
            if(status === 1){
                const data=responseValue[0];
                setDashboardData(data);
                const tempArr=[
                    { heading: 'Wallet', subHeading: `Rs ${data.availableCredit || ''}`, icon: wallet, value: '', url: url.wallet },
                    { heading: 'Ticket Booking', subHeading:data.totalTicketBokingCount, icon: ticketBooking, value: '', url: url.airticketbookings },
                    { heading: 'Package Booking', subHeading:data.totalPackageBokingCount, icon: packageBooking, value: '', url: url.packagebookings },
                    { heading: 'Invoice', subHeading:'', icon: invoice, value: '', url: '' },
                    { heading: 'Transaction', subHeading:'', icon: invoice, value: '', url: '' },
                ];
                setCards([...tempArr]);
                    
            }
            else{
                setDashboardData([]);
            }
        }
        catch(error){
            console.error('Error While fetching Dashboard Data:',error)
        }
    };
   
    useEffect(() => {
        RetrieveDashboardData();
    },[]);
    return(
        <>
            <Header />
           <div className="container-fluid padTop60">
           <div className="col-lg-12">
                <div className="row">
                    {cards.map((item, index) => {
                        return(
                            <div className="col-xxl-3 col-md-4 col-sm-12 cursor-pointer" key={index}>
                                <div className="card padding5Px customCardHeight110px" onClick={()=>{navigate(item.url)}}>
                                    <div className="card-body">
                                        <div className="cardContainer">
                                            <div className="d-flex justify-content-center">
                                                <img src={item.icon} alt="icon" style={{ height: '40px' }} />
                                            </div>
                                            <div>
                                                <p className="cardTitle">  {item.heading}</p>
                                                {item.heading === 'Wallet' ? <>
                                                <span className={`cardSubTitle ${dashboardData.isCreditExpire ? 'badge bg-danger text-light' : 'badge bg-success text-light'}`}>{item.subHeading}  </span>
                                                <span className={`mt-1 ${(dashboardData.creditStatus === 'Active' && !dashboardData.isCreditExpire ) ? 'badge bg-success':'badge bg-danger'} d-block`}  title="Credit Status">
                                                    {dashboardData.isCreditExpire ? 'Expire' : dashboardData.creditStatus === 'Active' ? 'Active' :dashboardData.creditStatus }
                                                </span>
                                                </>
                                                :<span className="cardSubTitle">{item.subHeading}  </span>} 
                                                
                                            </div>                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
           </div>
        </>
    )
}
export default Account