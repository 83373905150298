import { Link } from "react-router-dom"
import Header from "../Header"
import CommunicationHub from "../../Common/CommunicationHub"

const PrivacyAndPolicy = ()=>{
    return(
       <>
         <Header customizedHeader={1} isDashboard={0}/>
        <section id="tour_details_main" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="terms_service_content">
                        <h1>Privacy Policy - Doco Trip</h1>
                        <div class="terms_item">
                            <h4>Privacy Policy</h4>
                            <p> Greetings from Travel Right. We are devoted to maintaining the security of your personal information and safeguarding your right to privacy. When you use our website or services, we may collect, use, disclose, and protect personal information about you as described in this Privacy Policy. You provide your permission to the procedures outlined in this privacy statement by visiting or using our website.</p>
                            
                        </div>
                        <div class="terms_item">
                            <h4>Information We Collect</h4>
                            <p>We may gather contact details such your postal address, phone number, and email address Payment Information: If you use our website to make any payments, we may collect payment details such credit card numbers or other monetary details.</p>
                            
                        </div>
                        <div class="terms_item">
                            <h4>How We Use Your Information</h4>
                            <p>Your personal information may be used by us for a variety of reasons, such as:<br />
                            Service Provision : To offer and maintain our services, which include matching users, promoting communication, and offering support to customers.
                            <br />Personalization : To make your visit to our website more tailored to you while also offering pertinent information and suggestions.
                            <br />Communication : To contact with you, including via providing newsletters, updates, and advertising materials.
                            <br />Security : To safeguard our website's integrity and security and stop fraud and abuse.
                            </p>
                            
                        </div>
                        <div class="terms_item">
                            <h4>Data Security</h4>
                            <p>We take appropriate precautions to guard your personal data against loss, theft, misuse, and unauthorized access, disclosure, modification, or destruction. Please be aware, nevertheless, that no technique of internet transmission or computer storage is 100 percent secure.</p>
                            
                        </div>
                        <div class="terms_item">
                            <h4>Cookies And Tracking</h4>
                            <p>To gather data regarding your browsing habits, preferences, and usage patterns, we may make use of cookies, web beacons, and other tracking technologies.</p>
                            
                        </div>
                        <div class="terms_item">
                            <h4>Policy Updates </h4>
                            <p>We may occasionally amend this privacy statement to reflect changes in our procedures or for other administrative, statutory, or regulatory purposes. If there are any significant changes, we'll let you know via the website or another channel.</p>
                            
                        </div>
                        <div class="terms_item">
                            <h4>Contact Us</h4>
                            <p>If you have any questions or concerns about this Privacy Policy or our practices, please contact us at <Link to="tel:+05224575101">{CommunicationHub.landLineNo}</Link></p>
                            
                        </div>
                    </div>
                        
                   
                </div>
            </div>
        </div>
        </section>
       </>
    )
}
export default PrivacyAndPolicy