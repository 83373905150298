import React, { useEffect, useRef, useState } from "react";
import html2pdf from "html2pdf.js";
import './style.css';
import imgFlight from '../../../../assets/ETicket/flight.png';
import Common from "../../../../Common/Common";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import url from "../../../../Common/url";
const ETicket=({isCustom=false})=>{
    const contentRef = useRef();
      const navigate = useNavigate();   
    //Manage States
     const [ticketDetails,setTicketDetails]=useState({});
     const [isEditPaymentMode,setisEditPaymentMode]=useState(false);
     const [totalPrice,setTotalPrice]=useState('');
     const [fare,setFare]=useState(0);
     const [feeAndSurcharge,setFeeAndSurcharge]=useState(0);
     const [isAddDiscountEnabled,setisAddDiscountEnabled]=useState(false);
     const [isShowDiscountValue,setisShowDiscountValue]=useState(false);
     const [discount,setDiscount]=useState(0);
     const [isPrint,setisPrint]=useState(false);
     const [isPrintWithoutFare,setisPrintWithoutPrint]=useState(false);

    //End Here
    const handleChange=(e)=>{
        const {name,value}=e.target;
         // First, remove "Rs." prefix if it exists
        let currentValue = value.replace('Rs.', '');
        // Then, allow only numbers and one decimal point using regex
        currentValue = currentValue.replace(/[^0-9.]/g, '');
        if(name === 'fare'){
           setFare(currentValue);
        }
       else if(name === 'feeAndSurcharge'){
            setFeeAndSurcharge(currentValue);
        }
       else if(name === 'discount'){
            setDiscount(currentValue);
        }
    }
    const getETicket=()=>{
       
        try{
            const data=Common.decodedData(sessionStorage.getItem("eTicketDetails"));
            const decryptData=JSON.parse(decodeURIComponent(data));  
            const sumTotalPrice = (
                (parseFloat(decryptData.bookedFare) || 0) + 
                (parseFloat(decryptData.gst) || 0) + 
                (parseFloat(decryptData.feeAndSurcharge) || 0) + 
                (parseFloat(decryptData.baggageCharge) || 0)
            );
            setFare(decryptData.bookedFare);
            setFeeAndSurcharge(decryptData.feeAndSurcharge);
            setTotalPrice(sumTotalPrice);
            if(decryptData && decryptData.isConnectedFlight === 1){
              decryptData.connectionDetails =JSON.parse(decryptData.connectionDetails);
              
              setTicketDetails(decryptData);
            }
            else{
                setTicketDetails(decryptData);
            }


        }
        catch{
            console.error('Something went wrong')
        }
    };
    const handleEditPayment=()=>{
        setisEditPaymentMode(true);
        isAddDiscountEnabled ? setisShowDiscountValue(false) :setisShowDiscountValue(true);
    };
    const handleCancelEditPayment=()=>{
        const sumTotalPrice = (
            (parseFloat(ticketDetails.bookedFare) || 0) + 
            (parseFloat(ticketDetails.gst) || 0) + 
            (parseFloat(ticketDetails.feeAndSurcharge) || 0) + 
            (parseFloat(ticketDetails.baggageCharge) || 0)
        );
        setFare(ticketDetails.bookedFare);
        setFeeAndSurcharge(ticketDetails.feeAndSurcharge);
        setTotalPrice(sumTotalPrice);
        setisEditPaymentMode(false);
    };
    const handleUpdatePayment=()=>{
        if(!fare || fare <= 0 ){
            alert('Please correct enter fare');
        }
        else{
            const sumTotalPrice = (
                (parseFloat(fare) || 0) + 
                (parseFloat(ticketDetails.gst) || 0) + 
                (parseFloat(feeAndSurcharge) || 0) + 
                (parseFloat(ticketDetails.baggageCharge) || 0)
            );
            if(isAddDiscountEnabled && discount > sumTotalPrice){
                alert('Discount should be less than total price');
                return;
            }
            const discountValue = isAddDiscountEnabled ? parseFloat(discount) : 0;
            setTotalPrice((sumTotalPrice - discountValue));
            isAddDiscountEnabled ? setisShowDiscountValue(true) : setisShowDiscountValue(false);
            setisEditPaymentMode(false);
        }
        
    };
    const handleAddDiscount=()=>{  
        setisEditPaymentMode(true);
        setisAddDiscountEnabled(true);
        setisShowDiscountValue(false);

    };
    const handleRemoveDiscount=()=>{  
        const sumTotalPrice = (
            (parseFloat(fare) || 0) + 
            (parseFloat(ticketDetails.gst) || 0) + 
            (parseFloat(feeAndSurcharge) || 0) + 
            (parseFloat(ticketDetails.baggageCharge) || 0)
        );
        setTotalPrice((sumTotalPrice));
        setisShowDiscountValue(true);
        setisAddDiscountEnabled(false);
        setDiscount(0);
    };
    const handlePrint = () => {
        setisPrint(true);
        setTimeout(() => { 
            window.print();
            setisPrint(false);
        },500);
    };
    const handlePrintWithoutFare = () => {
        setisPrintWithoutPrint(true);
        setTimeout(() => { 
            window.print();
            setisPrintWithoutPrint(false);
        },500);
    };
    const handleDownloadPDF = () => {
        const element = contentRef.current; // Target the content to print
        const options = {
          margin: 10,
          filename: `${ticketDetails.firstAndMiddleName}_eTicket.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };
    
        html2pdf().set(options).from(element).save();
    };
    const handleBackBtn=()=>{
        if(isCustom){
            navigate(url.searchFlight);
        }
        else{
            navigate(url.airticketbookings);
        }
    };
    useEffect(()=>{
        getETicket()
    },[])
    return(
        <>
            <div className="customBody">
                {(isPrint === false && isPrintWithoutFare === false) ?
                    <div className="d-flex e-ticket-header">
                        <div onClick={handleBackBtn}><IoIosArrowBack className="flight-booking-header-back-icon"/></div>
                        <div><span className="flight-booking-header-title">E-Ticket</span></div>
                        <div><span className="flight-booking-header-title"></span></div>
                    </div>
                :''}
                <div className={`${(isPrint === false && isPrintWithoutFare === false) ? 'customETicketWrapper':''}`}>
                    <div className="wrapper-cnt" ref={contentRef}>
                        <div className="watermark">
                            <div className="header">
                                <h1>E-Ticket</h1>
                                <div className="topleft">
                                    <table>
                                        <tr colspan="2">
                                            <td align="left"><strong>{ticketDetails.bookedByAgencyName ?? ''}</strong></td>
                                        </tr>
                                        <tr colspan="2">
                                            <td align="left">{ticketDetails.bookedByAddress ?? ''}</td>
                                        </tr>
                                        <tr>
                                            <td align="left">Contact No: {ticketDetails.bookedByMobileNo ?? ''}</td>
                                            <td align="right">Issued Date: {ticketDetails.bookingDate}</td>
                                        </tr>
                                    </table>
                                </div>

                            </div>

                            <div className="info-section">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>PNR No.</th>
                                            <th>Departure</th>
                                            <th>Arrival</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td valign="center">
                                                <strong>{ticketDetails.ticketPnr ?? ''}</strong>
                                            </td>
                                            <td>{ticketDetails.departureDate} {ticketDetails.departureTime}</td>
                                            <td>{ticketDetails.arrivalDate} {ticketDetails.arrivalTime}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="info-section">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Passenger Name</th>
                                            <th>Passenger Type</th>
                                            <th>GST No</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><strong> {ticketDetails.firstAndMiddleName} {ticketDetails.lastName}</strong></td>
                                            <td>Adult</td>
                                            <td>SDFR101010</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="info-section">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Flight Details</th>
                                            <th>Departure</th>
                                            <th></th>
                                            <th>Arrival</th>
                                            <th>Status</th>
                                        </tr>
                                        <tr>
                                            <th className="subheading">Departure Flight</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {ticketDetails.isConnectedFlight === 1 ? 
                                        <>
                                            {ticketDetails.connectionDetails.length > 0 && ticketDetails.connectionDetails.map((item,index,allItems)=>{ 
                                                return(
                                                    <>
                                                    {index === 0 ?
                                                        <tr key={(index+1)}>
                                                            <td className="lineh"><span><img src={ticketDetails.airline_logo}  style={{height: '17px'}} alt="airline-logo"/></span>
                                                                <strong className="blacktext">{ticketDetails.airline_name} {ticketDetails.airline_no}</strong><br />
                                                                Class: H1<br />
                                                                Aircraft: 73N<br />
                                                                Operating Carrier: {ticketDetails.airline_name}<br />
                                                                Cabin:Economy<br />
                                                            </td>
                                                            <td className="lineh"><strong className="blacktext">{ticketDetails.departureSectorCode} </strong><br />
                                                                {ticketDetails.departureSector}<br />Terminal: {ticketDetails.departureTerminal}<br /><strong>{ticketDetails.departureDate} {ticketDetails.departureTime}</strong>
                                                                <br />
                                                            </td>
                                                            <td><img className="arrowImage" src={imgFlight}  alt="line"/></td>
                                                            <td className="lineh"><strong className="blacktext">{item.conArrivalSectorCode} </strong><br />
                                                                {item.conArrivalSector}<br />Terminal: {item.arrivalTerminal}<br /><strong>{item.arrivalDate} {item.arrivalTime}, Tue
                                                                </strong><br />
                                                            </td>
                                                            <td>Confirmed</td>
                                                        </tr>
                                                    :
                                                    <>
                                                        <tr index={index+2}>
                                                            <td className="lineh"><span><img src={ticketDetails.airline_logo}  style={{height: '17px'}} alt="airline-logo"/></span>
                                                                <strong className="blacktext">{ticketDetails.airline_name} {ticketDetails.airline_no}</strong><br />
                                                                Class: H1<br />
                                                                Aircraft: 73N<br />
                                                                Operating Carrier: {ticketDetails.airline_name}<br />
                                                                Cabin:Economy<br />
                                                            </td>
                                                            <td className="lineh"><strong className="blacktext">{allItems[(index-1)].conArrivalSectorCode} </strong><br />
                                                                {allItems[(index-1)].conArrivalSector}<br />Terminal: {allItems[(index-1)].departureTerminal}<br /><strong>{allItems[(index-1)].departureDate} {allItems[(index-1)].departureTime}</strong>
                                                                <br />
                                                            </td>
                                                            <td><img className="arrowImage" src={imgFlight}  alt="line"/></td>
                                                            <td className="lineh"><strong className="blacktext">{item.conArrivalSectorCode} </strong><br />
                                                                {item.conArrivalSector}<br />Terminal: {item.arrivalTerminal}<br /><strong>{item.arrivalDate} {item.arrivalTime}, Tue
                                                                </strong><br />
                                                            </td>
                                                            <td>Confirmed</td>
                                                        </tr>
                                                    </>
                                                    }
                                                    {index === ticketDetails.connectionDetails.length - 1 ?
                                                        <tr key={index+1}>
                                                            <td className="lineh"><span><img src={ticketDetails.airline_logo}  style={{height: '17px'}} alt="airline-logo"/></span>
                                                                <strong className="blacktext">{ticketDetails.airline_name} {ticketDetails.airline_no}</strong><br />
                                                                Class: H1<br />
                                                                Aircraft: 73N<br />
                                                                Operating Carrier: {ticketDetails.airline_name}<br />
                                                                Cabin:Economy<br />
                                                            </td>
                                                            <td className="lineh"><strong className="blacktext">{item.conArrivalSectorCode} </strong><br />
                                                                {item.conArrivalSector}<br />Terminal: {item.departureTerminal}<br /><strong>{item.departureDate} {item.departureTime}</strong>
                                                                <br />
                                                            </td>
                                                            <td><img className="arrowImage" src={imgFlight}  alt="line"/></td>
                                                            <td className="lineh"><strong className="blacktext">{ticketDetails.arrivalSectorCode} </strong><br />
                                                                {ticketDetails.arrivalSector}<br />Terminal: {ticketDetails.arrivalTerminal}<br /><strong>{ticketDetails.arrivalDate} {ticketDetails.arrivalTime}, Tue
                                                                </strong><br />
                                                            </td>
                                                            <td>Confirmed</td>
                                                        </tr>
                                                    :''}
                                                    </>
                                                
                                                )
                                            })}
                                        </>
                                    
                                    :
                                    <tr key={1}>
                                        <td className="lineh"><span><img src={ticketDetails.airline_logo}  style={{height: '17px'}} alt="airline-logo"/></span>
                                            <strong className="blacktext">{ticketDetails.airline_name} {ticketDetails.airline_no}</strong><br />
                                            Class: H1<br />
                                            Aircraft: 73N<br />
                                            Operating Carrier: {ticketDetails.airline_name}<br />
                                            Cabin:Economy<br />
                                        </td>
                                        <td className="lineh"><strong className="blacktext">{ticketDetails.departureSectorCode} </strong><br />
                                            {ticketDetails.departureSector}<br />Terminal: {ticketDetails.departureTerminal}<br /><strong>{ticketDetails.departureDate} {ticketDetails.departureTime}</strong>
                                            <br />
                                        </td>
                                        <td><img className="arrowImage" src={imgFlight}  alt="line"/></td>
                                        <td className="lineh"><strong className="blacktext">{ticketDetails.arrivalSectorCode} </strong><br />
                                            {ticketDetails.arrivalSector}<br />Terminal: {ticketDetails.arrivalTerminal}<br /><strong>{ticketDetails.arrivalDate} {ticketDetails.arrivalTime}, Tue
                                            </strong><br />
                                        </td>
                                        <td>Confirmed</td>
                                    </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>

                            <div className={`remark-c_ ${isPrintWithoutFare ? 'remark-pydetails-wrapper-print' :'remark-pydetails-wrapper'} `}>
                                <div className="remarks_ remark-custome">
                                    <p>
                                        <strong style={{color: 'red'}}>Important:</strong>
                                        <strong>This is an Electronic Ticket. Passengers must carry a valid photo ID for check-in at the
                                            airport.</strong>
                                    </p>
                                    <p style={{color: 'rgb(66, 64, 64)'}}>The network you are using may require you to visit its login
                                        page.
                                        The
                                        network you
                                        are using may require you to visit its login page.The network you are using
                                        may require you to visit its login page.The network you are using may require
                                        you to visit its login page.The network you are using may require you to visit
                                        its login page.The network you are using may require you to visit its login page.
                                        The network you are using may require you to visit its login page.
                                    </p>
                                    <p>
                                        <strong  style={{color: 'red'}}>Note:</strong>
                                        <strong>This is an Electronic Ticket. Passengers must carry a valid photo ID for check-in at the
                                            airport.</strong>
                                    </p>
                                </div>
                                {isPrintWithoutFare === false ?
                                <div className="payy_">
                                    <div className="payment-section">
                                        <div className="paydetails"><strong>Payment Details</strong></div>
                                        <div className="paydetails">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Fare</td>
                                                        {(isEditPaymentMode && !isAddDiscountEnabled) ?  
                                                        <td className="editable-td">
                                                        Rs. 
                                                        <input type="text" className="customInput" name="fare" value={fare} onChange={handleChange} />
                                                        </td>
                                                        : <td id="fare">Rs.{fare}</td>
                                                        }
                                                        
                                                    
                                                    </tr>
                                                    <tr>
                                                        <td>K3/GST</td>
                                                        <td>Rs. {ticketDetails.gst}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Fee & Surcharge</td>
                                                        {(isEditPaymentMode && !isAddDiscountEnabled) ?  <td className="editable-td">
                                                        Rs.
                                                        <input type="text" className="customInput"  name="feeAndSurcharge" value={feeAndSurcharge} onChange={handleChange} />
                                                        </td>
                                                        : <td id="feeAndSurcharge">Rs.{feeAndSurcharge}</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Excess Baggage Charge</td>
                                                        <td>Rs. {ticketDetails.baggageCharge}</td>
                                                    </tr>
                                                    {isAddDiscountEnabled ?
                                                        <tr>
                                                            <td>Discount</td>
                                                            {isShowDiscountValue ?
                                                                <td>Rs. {discount}</td>
                                                            : 
                                                            <td>
                                                                <input type="text" className="customInput" value={discount} name="discount" onChange={handleChange} />
                                                            </td>
                                                            }
                                                            
                                                            
                                                        </tr>
                                                    :''}
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="paydetails-total">Total Amount <span>Rs. {totalPrice}</span></div>
                                        {isEditPaymentMode ?
                                            <div className="editpaymentcontainer">
                                                <button className="btn-cancel" onClick={handleCancelEditPayment}>cancel</button>
                                                <button className="btn-update" onClick={handleUpdatePayment}>Update</button>
                                            </div>
                                        :''}
                                        
                                    </div>
                                </div>
                                :''}

                            </div>
                            <div className="advertisement"> {/* <img src={imgAdd} /> */} </div>
                        </div>
                    </div>
                </div>
                
                {(isPrint === false && isPrintWithoutFare === false) ?
                    <div className="eTicketFooter">
                        <div className="content" onClick={handlePrint}><button>Print E-ticket</button></div>
                        <div className="content" onClick={handlePrintWithoutFare}><button>Print E-ticket Without Fare</button></div>
                        <div className="content"><button>Email E-ticket</button></div>
                        <div className="content"><button>Email E-ticket Without Fare</button></div>
                        <div className="content" onClick={handleDownloadPDF}><button>Generate PDF</button></div>
                        {isAddDiscountEnabled ? <div className="content" onClick={handleRemoveDiscount}><button>Remove Discount</button></div> : <div className="content" onClick={handleAddDiscount}><button>Add Discount</button></div> }
                        <div className="content" onClick={handleEditPayment}><button>Edit Payment</button></div>
                     </div>
                :''}
                
            </div>
        </>
    )
}
export default ETicket;
